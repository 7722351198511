import useHttp from "./useHttp";

export default function useSavedModule() {
  const { fetchData } = useHttp();

  const fetchSavedModule = (appProperties) => {
    return new Promise((resolve, reject) => {
      let integId = appProperties?.randomIntegId;
      console.log("response> in templates>>>>");

      if (appProperties !== undefined) {
        fetchData(`omessage/${integId}/savedModules`, "GET", null, appProperties).then((response) => {
          let savedAllCustomModules = "";
          if (response) {
            const responseData = JSON.parse(response);
            savedAllCustomModules = responseData?.data;
          }
          resolve(savedAllCustomModules);
        });
      }
    });
  };

  const fetchModules = (appProperties) => {
    return new Promise((resolve, reject) => {
      let integId = appProperties?.randomIntegId;

      console.log("response> in templates>>>>");

      if (appProperties !== undefined) {
        fetchData(`omessage/${integId}/fetchModules`, "GET", null, appProperties).then((response) => {
          let selectCustomModule = "";
          if (response) {
            const responseData = JSON.parse(response);
            selectCustomModule = responseData.data;
          }
          resolve(selectCustomModule);
        });
      }
    });
  };

  const fetchModuleApiName = (appProperties, moduleApiName) => {
    return new Promise((resolve, reject) => {
      let integId = appProperties?.randomIntegId;
      if (appProperties !== undefined) {
        fetchData(`omessage/${integId}/module/fields?moduleApiName=${moduleApiName}`, "GET", null, appProperties).then((response) => {
          let selectCustomModule = "";
          if (response) {
            const responseData = JSON.parse(response);
            selectCustomModule = responseData.data;
          }
          resolve(selectCustomModule);
        });
      }
    });
  };

  const addCustomModuleBtn = (appProperties, body) => {
    return new Promise((resolve, reject) => {
      const integId = appProperties.integId;
      fetchData(`omessage/${integId}/module`, "POST", body, appProperties).then((response) => {
        let addCustomModule = "";
        if (response) {
          addCustomModule = JSON.parse(response);
        }
        resolve(addCustomModule);
      });
    });
  };

  return {
    fetchSavedModule,
    fetchModules,
    fetchModuleApiName,
    addCustomModuleBtn,
  };
}
