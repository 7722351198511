import { Button } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import useCampaignApi from "../../../../hooks/useCampaign";
import { failureNotification } from "../../../utils/CommonNotifications";
import { ConfirmAlertModal } from "../../../utils/CommonVessels";
import { getClassNameForCampaignStatus, getClassNameForCampaignType } from "../../utils/campaignUtils";
import { capitalizeFirstLetter, getDefaultUnknownErrorMessageContent } from "./../../../utils/commonUtils";

const actionContent = {
  edit: "Editing will pause the campaign. Finish and relaunch to resume.",
};
const CampaignActionArea = React.memo((props) => {
  const { initialValues, setInitialValues, showNotVisibleFilterBanner, sendCampaign, appProperties } = props;
  const { updateCampaignStatus } = useCampaignApi(appProperties);
  const [confirmActionModalOpen, setConfirmActionModalOpen] = useState(false);
  const currentActionStatus = initialValues?.campaignStatusName?.toLowerCase() === "paused" ? "resume" : "edit";
  const [currentAction, setCurrentAction] = useState(currentActionStatus);
  const [resumeLoading, setResumeLoading] = useState(false);
  const disableForm = useCallback(
    (disable) => {
      setInitialValues((prev) => {
        return { ...prev, disabled: disable };
      });
    },
    [setInitialValues]
  );

  useEffect(() => {
    if (currentAction === "resume") {
      disableForm(false);
    }
  }, [currentAction, disableForm]);

  const doPauseAction = async () => {
    try {
      const updateCampaignStatusResponse = await updateCampaignStatus(initialValues?.campaignId, "pause");
      if (updateCampaignStatusResponse?.status === 200 && updateCampaignStatusResponse?.data) {
        setConfirmActionModalOpen(false);
        setCurrentAction("resume");
      } else {
        failureNotification(getDefaultUnknownErrorMessageContent("Error updating Campaign status."));
      }
    } catch (e) {
      failureNotification(getDefaultUnknownErrorMessageContent(`Error updating Campaign status.`));
    }
  };

  const doResumeAction = async () => {
    const sendCampaignResponse = await sendCampaign(true);
    if (sendCampaignResponse) {
      if (sendCampaignResponse?.status) {
        setCurrentAction("edit");
      } else {
        failureNotification(sendCampaignResponse?.error);
      }
    }
  };

  const doAction = async () => {
    if (currentAction === "resume") {
      setResumeLoading(true);
      await doResumeAction();
      setResumeLoading(false);
    } else if (currentAction === "edit") {
      setConfirmActionModalOpen(true);
    }
  };

  const doOnCancel = () => {
    setConfirmActionModalOpen(false);
  };

  const isStatusPaused = (status) => {
    if (status?.toLowerCase() === "resume") {
      return true;
    }
    return false;
  };

  const getActionHeaderName = (action, type) => {
    if (isStatusPaused(action)) {
      return "Paused";
    } else {
      return capitalizeFirstLetter(initialValues?.type);
    }
  };

  const getActionHeaderClassName = (action) => {
    if (isStatusPaused(action)) {
      return getClassNameForCampaignStatus("paused");
    } else {
      return getClassNameForCampaignType(initialValues?.type);
    }
  };

  const actionHeaderContent = getActionHeaderName(currentAction);

  const actionHeaderClass = getActionHeaderClassName(currentAction);

  return (
    <div className="row w-100 d-flex align-items-center justify-content-between campaignActionContainer mb-3">
      <ConfirmAlertModal
        onCancel={doOnCancel}
        open={confirmActionModalOpen}
        onConfirm={doPauseAction}
        centered={true}
        alertIcon={<div className="actionIconsSprite campaignEdit" />}
        titleContent={"Are you sure you want to pause?"}
        modalContent={actionContent[currentAction]}
        buttonConfirm={"Edit"}
      />
      <div className="col-md-5 text-center d-flex justify-content-center">
        <div className={`w-75 campaignActionTitleContainer hs-border-30 py-3 ${actionHeaderClass}`}>
          <div className="hs-fw-600 ">{actionHeaderContent}</div>
        </div>
      </div>
      <div className="col-md-5 campaignActionButtonsContainer d-flex gap-3 justify-content-end me-4">
        <Button
          className="hs-bg-violet hs-border-10 big-button hs-fs-16 px-3"
          type="primary"
          name="actionButton"
          disabled={false || (!initialValues?.disabled && showNotVisibleFilterBanner)}
          loading={resumeLoading}
          onClick={doAction}
        >
          <span className="px-4">{getActionAreaName(currentAction)}</span>
        </Button>
      </div>
    </div>
  );
});

const getActionAreaName = (currentAction) => {
  if (currentAction === "resume") {
    return "Save and Launch";
  } else {
    return capitalizeFirstLetter(currentAction);
  }
};
export default CampaignActionArea;
