import React, { useContext, useEffect, useState } from "react";

import { AppContext } from "../../context/AppContext";

import { Select, Spin } from "antd";
import useHttp from "../../hooks/useHttp";
import useTemplates from "../../hooks/useTemplates";
import { RefreshButton } from "../utils/CommonVessels";

export const SavedTemplatesSelect = React.memo((props) => {
  const {
    setTemplateId,
    templateId,
    setMessageAreaLoading,
    handleMessage,
    tabIndex,
    moduleName,
    openAddTemplateDrawer,
    showPreview,
    refetchOnModuleChange,
    fetchOnlyByModule,
    handleMedia,
  } = props;

  const [appProperties] = useContext(AppContext);
  const [savedTemplatesList, setSavedTemplatesList] = useState([]);
  const [templateLoading, setTemplateLoading] = useState(true);
  const [templateApi, setTemplateApi] = useState(false);
  const [templateLink, setTemplateLink] = useState("");

  const { fetchSavedTemplates, fetchSavedTemplatesByModule } = useTemplates();
  const { fetchData } = useHttp();

  function handleTemplateId(templateIdSelected) {
    if (templateIdSelected === "add::::Templates") {
      window.open(templateLink, "_blank");
    } else {
      props.setTemplateId(templateIdSelected);
    }
  }
  function handleTemplateLoading(templateToBeLoaded) {
    setTemplateLoading(templateToBeLoaded);
  }
  const handleOnChange = (v) => {
    handleTemplateLoading(true);
    handleTemplateId(v);
    previewTemplate(v);
  };

  const previewTemplate = (templateId) => {
    if (templateId !== "add::::Templates") {
      setMessageAreaLoading(true);
      appProperties.controller.getAssociatedObjectType().then(function (associatedModule) {
        appProperties.controller.getAssociatedObjectId().then(function (associatedId) {
          let integId = appProperties.randomIntegId;

          if (!showPreview) {
            associatedId = "";
            associatedModule = "";
          }
          fetchData(
            `omessage/${integId}/template/${templateId}/preview?associatedObjectId=${associatedId}&associatedObjectType=${associatedModule}`,
            "GET",
            null,
            appProperties
          )
            .then((response) => {
              const responseData = JSON.parse(response);
              setMessageAreaLoading(false);
              setTemplateLoading(false);
              if (responseData?.data?.data || responseData?.data?.mediaFileList) {
                setTemplateId(templateId);
                handleMessage(responseData?.data?.data || "");
                handleMedia(responseData?.data?.mediaFileList || []);
              }
            })
            .catch((error) => {});
        });
      });
    } else {
      setTemplateLoading(false);
    }
  };

  useEffect(() => {
    // if (moduleName) {
    //   handleFetchTemplates(true, true);
    // }
    if (refetchOnModuleChange) handleFetchTemplates(true, true);
  }, [moduleName]);

  const handleFetchTemplates = async (refresh, templatesSelectOpen) => {
    if ((!templateApi && templatesSelectOpen) || (refresh && templatesSelectOpen)) {
      setTemplateLoading(true);
      var templateLink = appProperties.templateLink + "&openDrawer=" + openAddTemplateDrawer;
      var url = new URL(templateLink);

      url.searchParams.set("topBar", "true");
      url.searchParams.set("sideBar", "true");

      setTemplateLink(url);
      if (moduleName) {
        fetchSavedTemplatesByModule(appProperties, moduleName).then((responseData) => {
          var templatesData = [];

          if (responseData?.data) {
            templatesData = responseData.data;

            templatesData = templatesData.map((item) => {
              return {
                ...item,
                value: item.templateId,
                label: item.friendlyName,
              };
            });
          }
          setTemplateLoading(false);
          setSavedTemplatesList(templatesData);
          setTemplateApi(true);
        });
      } else if (!fetchOnlyByModule) {
        fetchSavedTemplates(appProperties)
          .then((responseData) => {
            var templatesData = [];

            if (responseData?.data) {
              templatesData = responseData.data;

              templatesData = templatesData.map((item) => {
                return {
                  ...item,
                  value: item.templateId,
                  label: item.friendlyName,
                };
              });
            }
            setTemplateLoading(false);
            setSavedTemplatesList(templatesData);
            setTemplateApi(true);
          })
          .finally(() => setTemplateLoading(false));
      }
    }
  };
  let templatesOption = [...savedTemplatesList];

  const handleFetchTemplatesButton = (e) => handleFetchTemplates("refresh", e);

  return (
    <>
      <Select
        tabIndex={tabIndex}
        onMouseDown={(e) => {
          handleFetchTemplates(null, e);
        }}
        bordered={false}
        style={{
          width: 120,
          height: 29,
          backgroundColor: "#F4F4FF",
          borderRadius: 8,
          cursor: "pointer",
        }}
        dropdownStyle={{ width: 200 }}
        placeholder={
          <span style={{ color: "#333" }} className="ms-1 hs-fs-13">
            Template
          </span>
        }
        value={templateId}
        options={templatesOption}
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label ?? "").toString().includes(input)}
        suffixIcon={<div className="actionIconsSprite templatesDropdownIcon" />}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toString()
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toString().toLowerCase())
        }
        dropdownRender={(menu) => (
          <div className="d-flex flex-column">
            <Spin spinning={templateLoading}>
              <div className="overflow-auto" style={{ maxHeight: "20dvh", minHeight: "20dvh" }}>
                {menu}
              </div>
            </Spin>
            <div className="row mt-auto justify-content-end">
              <div className="col-12 d-flex align-items-center justify-content-end">
                <RefreshButton onClickFunction={handleFetchTemplatesButton} loading={templateLoading} />
                <div
                  onClick={() => handleTemplateId("add::::Templates")}
                  className="btn ms-2 d-flex align-items-center"
                  style={{ background: "#605bff", color: "#fff" }}
                >
                  <div className="actionIconsSprite addTemplateIcon" />
                  <span className="hs-fs-12 text-light">Add Template</span>
                </div>
              </div>
            </div>
          </div>
        )}
        onSelect={(v) => handleOnChange(v)}
      />
    </>
  );
});

export default SavedTemplatesSelect;
