import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { ConfigurePhone, NoChannelPresent, SetAsDefaultPhone, initPusher } from "../utils/CommonVessels";
import { Badge, Button, Card, Col, Divider, Form, Input, Row, Select, Spin, Switch, Tooltip, message, theme } from "antd";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import "../../assets/css/outbound.css";
import MediaUploadIconWrapper from "../../components/custom/MediaUploadIconWrapper";
import ReusableFilePreview from "../../components/custom/ReusableFilePreview";
import { ASSIST_MAIL } from "../../constants/AppConstants";
import { ProviderCommonObj, ProviderExtendedCommonObj } from "../../constants/ProviderConstants";
import useHttp from "../../hooks/useHttp";
import ConversationWrapper from "../ConversationContext";
import { SavedTemplatesSelect } from "../outbound/SavedTemplatesSelect";
import {
  failureNotification,
  failureNotificationWithBtn,
  failureNotificationWithLink,
  successNotification,
  successNotificationWithBtn,
} from "../utils/CommonNotifications";
import { FundsMonitor } from "../utils/FundsMonitor";
import { AppContext } from "./../../context/AppContext";
import { TopMenuItems } from "./../utils/MoreMenu";
import { getRandomNumber, mapToMediaList } from "../utils/commonUtils";
const { TextArea } = Input;

function SendSMS(props) {
  const [appProperties] = useContext(AppContext);
  const { fetchData } = useHttp();
  const { token } = theme.useToken();
  const sendSMSIcon = <div className="actionIconsSprite sendSMSIcon h-100 w-100"></div>;
  const [messageApi, contextHolder] = message.useMessage();
  const info = () => {
    messageApi.open({
      content: (
        <>
          <Badge status="error" /> Alert: Verify your 10DLC status within your{" "}
          <Button
            type="link"
            onClick={() => {
              window.open("/channels/textsms/helloSend?" + urlParams);
              messageApi.destroy();
            }}
            style={{ whiteSpace: "nowrap" }}
          >
            Phone Settings
          </Button>
          <a href={"/channels/textsms/helloSend?" + urlParams} rel="noreferrer" target="_blank"></a>
        </>
      ),
      className: "tendlcAlert",
      duration: 3,
    });
  };

  const [savedPhoneNumbers, setSavedPhoneNumbers] = useState([]);
  const [savedMessagingServices, setSavedMessagingServices] = useState([]);
  const [loadFromServiceList, setLoadFromServiceList] = useState([]);
  const [loadTemplates, setLoadTemplates] = useState(false);
  const [loadingButton, setLoadingButton] = useState(sendSMSIcon);
  const [fromNumber, setFromNumber] = useState("");
  const [defaultFromNumber, setDefaultFromNumber] = useState("");
  const [toNumber, setToNumber] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [showMMS, setShowMMS] = useState(true);
  const [savedNumbersList, setSavedNumbersList] = useState([]);
  const [alphaSender, setAlphaSender] = useState("false");
  const [messagingServiceId, setMessagingServiceId] = useState("");
  const [helloSend, setHelloSend] = useState(false);
  const [campaignStatus, setCampaignStatus] = useState(null);
  const [balanceCredits, setBalanceCredits] = useState(0);
  const [sendSMSForm] = Form.useForm();

  const isBulkSMS = props.bulk;
  const [showChatView, setShowChatView] = useState(isBulkSMS ? false : true);
  const [integId, setIntegId] = useState();
  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);

  urlParams.delete("sideBar");
  urlParams.delete("topBar");
  var resource = urlParams.get("resource");
  var userId = urlParams.get("userId");
  var companyId = urlParams.get("companyId");
  var selectedIds = urlParams.get("selectedIds");
  var filter = urlParams.get("filter");
  var excludedIds = urlParams.get("excludedIds");
  var view = urlParams.get("view");

  useEffect(() => {
    let licenseInfo = appProperties?.licenseObj?.licenseDetails;
    if (licenseInfo) {
      setBalanceCredits(licenseInfo?.creditsPurchased - licenseInfo?.creditsConsumed);
    }
  }, [appProperties?.licenseObj?.licenseDetails]);

  useEffect(() => {
    let integId = appProperties?.installedApps?.find((obj) => obj?.right?.service_name === "hello_send")?.integProps?.integId;
    if (integId) {
      fetchData("hellosend/account/" + integId + "/campaign/registration?type=PROFILE", "POST", null, appProperties).then(function (response) {
        if (response) {
          response = JSON.parse(response);
          let steps = response?.data?.steps;
          setCampaignStatus(steps?.[4]?.campaign?.campaignStatus);
        }
      });
    }
  }, [fetchData, appProperties?.installedApps]);

  useEffect(() => {
    if (!showChatView) {
      setHelloSend(savedNumbersList?.find((obj) => obj?.phoneNumber === fromNumber)?.isHelloSendPhoneNumber === "true" ? true : false);
    }
    let numberObj = savedNumbersList?.find((obj) => obj?.phoneNumber === fromNumber);
    let integ = numberObj?.integId;
    setIntegId(integ);
    let rightServiceName = appProperties?.installedApps?.find((obj) => obj?.integProps?.integId === numberObj?.integId)?.right?.service_name;
    let assignerObj1 = Object.assign(ProviderCommonObj, ProviderExtendedCommonObj[rightServiceName]);
    appProperties.providerCommonObj = assignerObj1;
    appProperties.rightServiceName = rightServiceName;

    if (appProperties?.providerCommonObj && !appProperties.providerCommonObj["isMMSSupported"]) {
      setShowMMS(false);
    } else {
      setShowMMS(true);
    }
  }, [fromNumber, appProperties?.providerCommonObj, savedNumbersList]);

  function handleAlphaSender(phoneNumberList, selectedNumber) {
    console.log("selected phone handleAlphaSender >>>>>>>", phoneNumberList);
    console.log("selected phone fromNumber >>>>>>>", selectedNumber);

    const filteredOption = phoneNumberList.filter((phoneObj) => phoneObj.value === selectedNumber);

    console.log("filteredOption >>>>>>>>>>>>>>>>> ", filteredOption);
    if (filteredOption.length > 0) {
      let isAlphaSender = filteredOption[0].alphaSender;
      let messageServiceId = filteredOption[0].messageServiceId;
      console.log("filteredOption >>>>>> isAlphaSender >>>>>", isAlphaSender);

      if (isAlphaSender !== undefined && isAlphaSender === "true") {
        setAlphaSender("true");
        setMessagingServiceId(messageServiceId);
      }
    }
  }

  function handleFromNumberChange(selectedPhoneNumber) {
    if (selectedPhoneNumber === "add::::phone") {
      window.open("/channels/textsms?" + urlParams, "_blank");
    } else {
      setFromNumber(selectedPhoneNumber);
      handleAlphaSender(savedPhoneNumbers, selectedPhoneNumber);
    }
  }
  function handleDefaultNumberChange(selectedPhoneNumber) {
    if (selectedPhoneNumber === "add::::phone") {
      // window.open("/channels/textsms?" + urlParams, "_blank");
    } else {
      setDefaultFromNumber(selectedPhoneNumber);
    }
  }
  function handleToNumberChange(toNumberList) {
    setToNumber(toNumberList);
  }

  useEffect(() => {
    var phoneObj = appProperties?.savedNumbers?.filter((obj) => obj.isMessagingService !== "true");
    var msgObj = appProperties?.savedNumbers?.filter((obj) => obj.isMessagingService === "true");
    if (msgObj?.length > 0 && phoneObj?.length > 0) {
      phoneObj = [
        ...(phoneObj || []),
        {
          label: (
            <Divider plain style={{ margin: 0, fontSize: "12px" }} orientation="left">
              Message Service ID
            </Divider>
          ),
          value: "divider",
          disabled: true,
        },
        ...msgObj,
      ];
    } else if (msgObj?.length > 0) {
      phoneObj = [...msgObj];
    }
    setSavedNumbersList(phoneObj);
    if (phoneObj !== undefined) {
      phoneObj = phoneObj.map((item) => {
        return {
          ...item,
          value: item.phoneNumber,
          label: item?.phoneNumber ? (
            <Tooltip title={item?.phoneNumber}>
              <div className="addPhone-sendsms">
                <div className="d-flex align-items-center addPhone-sendsms">
                  <div
                    className={
                      "numberlist-sprites numberlist-" +
                      appProperties.installedApps.find((obj) => obj?.integProps?.integId === item.integId)?.right?.service_name
                    }
                  ></div>
                  <span>
                    {(() => {
                      let labelName = (item.friendlyName || "") + `(${item.phoneNumber})`;
                      if (labelName?.length > 30) {
                        return labelName.slice(0, 30) + "...";
                      } else {
                        return labelName;
                      }
                    })()}
                  </span>
                  <SetAsDefaultPhone setSavedNumbers={setLoadFromServiceList} phoneObj={phoneObj} item={item} />
                </div>
              </div>
            </Tooltip>
          ) : (
            item?.label
          ),
        };
      });
    } else {
      phoneObj = [];
    }
    setLoadTemplates(true);
    if (phoneObj?.length > 0) {
      const defaultPhoneNumber = phoneObj.find((obj) => obj?.defaultNumber === 1)?.phoneNumber || phoneObj[0]?.value;
      setDefaultFromNumber(defaultPhoneNumber);
      setFromNumber(defaultPhoneNumber);
    } else if (appProperties?.serviceInstalled) {
      var link = "channels/textsms?" + urlParams;
      failureNotificationWithBtn("Error", "Phone number not added. Add a phone number to proceed.", "Add phone", link);
    }
    setSavedPhoneNumbers(phoneObj);
    setLoadFromServiceList(phoneObj);
    setSavedMessagingServices(msgObj);
    setLoadTemplates(true);
    if (appProperties?.phone) {
      setToNumber(appProperties.phone);
    }
  }, [appProperties.savedNumbers, appProperties, fetchData, urlParams]);

  const sendMessage = (fileList, setFileList, message, setMessage) => {
    const antIcon = <LoadingOutlined className="loading-button" spin />;
    setLoadingButton(() => <Spin indicator={antIcon} />);

    appProperties.controller.getAssociatedObjectId().then(function (data) {
      let associatedObjectId = data;
      appProperties.controller.getAssociatedObjectType().then(async function (typeData) {
        const associatedObjectType = typeData;
        if (appProperties.controller.service !== "Pipedrive" && associatedObjectId !== null) {
          associatedObjectId = associatedObjectId[0];
        }

        if (!isBulkSMS && !toNumber) {
          failureNotification(null, "The 'To' number is not a valid phone number.");
          setLoadingButton(sendSMSIcon);
        } else if (!message) {
          failureNotification(null, "Message body required");
          setLoadingButton(sendSMSIcon);
        } else if (helloSend && props?.balanceCredits < 0.5) {
          failureNotification("Message not sent. Insufficient balance in your wallet. Top up now to reactivate the service.");
          setLoadingButton(sendSMSIcon);
        } else if (message !== "") {
          const phoneNumberEntry = savedNumbersList?.find((entry) => entry.phoneNumber === fromNumber);
          let integId = phoneNumberEntry?.integId;
          const mediaFileList = fileList
            ? fileList.map((file) => {
                return {
                  name: file.name ?? "file",
                  url: file.url,
                  size: file.size ?? -1,
                  contentType: file.contentType ?? "dummy/dummy",
                  thumbnailUrl: file.thumbnailUrl,
                };
              })
            : [];
          var payload = {
            from: fromNumber,
            to: toNumber,
            messageBody: message,
            associatedObjectId: associatedObjectId,
            associatedObjectType: associatedObjectType,
            mediaFileList: mediaFileList,
            templateId: templateId,
            alphaSender: alphaSender,
            messageServiceId: messagingServiceId,
            userId: urlParams.get("userId"),
          };
          let formData = null;

          let header = null;
          var targetUrl = "";
          if (isBulkSMS) {
            const filterJson = JSON.parse(filter);
            payload = {
              ...payload,
              view: view,
              selectedIds: selectedIds,
              filterId: filterJson?.filter_id ?? "",
              excludedIds: excludedIds,
              userId: userId,
              moduleName: resource,
              campaignName: `${resource?.toUpperCase()}_Campaign_${getRandomNumber(3)}`,
              filterName: `Selected ${resource}s`,
              messageScheduled: false,
              // campaignName: sendSMSForm?.getFieldValue("campaignName"),
            };
            header = { "Content-Type": "multipart/form-data" };

            formData = new FormData();
            formData.append("data", JSON.stringify(payload));
            payload = formData;

            targetUrl = `${integId}/campaign/send`;
          } else {
            targetUrl = `omessage/${integId}/send`;
          }
          let rightPhoneObj = appProperties.installedApps.find((obj) => obj?.integProps?.integId === integId);
          if (rightPhoneObj?.right?.auth?.authorized) {
            fetchData(targetUrl, "POST", payload, appProperties, header)
              .then(function (response) {
                response = JSON.parse(response);
                if (response.data.status === true) {
                  if (helloSend && props.balanceCredits < 11) {
                    failureNotification("Message queued successfully. Low balance in your wallet, top up for uninterrupted service.");
                  }
                  if (isBulkSMS) {
                    let notificationMessage = "SMS campaign started successfully! Monitor analytics for insights.";
                    successNotificationWithBtn(notificationMessage);
                  } else {
                  }
                  setLoadingButton(sendSMSIcon);
                  setMessage("");
                } else {
                  if (isBulkSMS) {
                    failureNotification("Campaign failed to initialize", response.data.error);
                  } else if (response.data?.data !== undefined && response.data?.data !== null) {
                    failureNotification("", response.data.data);
                  } else if (response.data?.license !== undefined && response.data?.license !== null) {
                    failureNotificationWithLink(response.data?.license?.description, "contact support .", `mailto:${ASSIST_MAIL}`);
                  }
                  setLoadingButton(sendSMSIcon);
                }
              })
              .catch(function () {
                setMessage("");
                setLoadingButton(sendSMSIcon);
                failureNotification("", "Message Failed ! ");
              })
              .finally(() => {
                setFileList(() => []);
              });
          } else {
            failureNotificationWithBtn(
              <span className="text-nowrap">{`${rightPhoneObj?.right?.service_display_name} service is not authorized !`}</span>,
              "Authorize",
              `/channels/textsms/${rightPhoneObj?.right?.service_name}?` + urlParams
            );
            setLoadingButton(sendSMSIcon);
          }
        }
      });
    });
  };

  return (
    <>
      {contextHolder}

      <div className="w-100 h-100 bg-white">
        <div className="w-100 h-100 row d-flex justify-content-center p-0 m-0">
          <div className="w-100 h-100 col d-flex justify-content-center p-0 m-0">
            <div className="parent-element overflow-hidden">
              <div className="" style={{ height: "33px" }}>
                <Row>
                  <Col span={12} className="d-flex ps-2 justify-content-start align-items-center">
                    {appProperties?.serviceInstalled && helloSend && (
                      <div className="ms-2 mb-1">
                        <FundsMonitor popOver={false} />
                      </div>
                    )}
                  </Col>
                  <Col span={9} className="d-flex align-items-center justify-content-end">
                    {appProperties?.serviceInstalled && helloSend && campaignStatus !== "VERIFIED" && (
                      <Badge dot={true} className="d-flex align-items-center me-3" style={{ top: 0, right: 0 }}>
                        <div className="actionIconsSprite tenDlc-icon" onClick={info} style={{ cursor: "pointer" }}></div>
                      </Badge>
                    )}
                    {appProperties?.serviceInstalled && !isBulkSMS && (
                      <>
                        <span className="pe-1 " style={{ whiteSpace: "nowrap", fontSize: 12 }}>
                          Chat view{" "}
                        </span>
                        <Switch
                          className="addPhoneSwitch-chatView"
                          value={showChatView}
                          checked={showChatView}
                          onChange={(e) => {
                            localStorage.setItem("chatview", e);
                            setShowChatView(e);
                          }}
                        />
                      </>
                    )}
                  </Col>
                  <Col span={3} className="d-flex justify-content-end align-items-center pe-3 ">
                    <TopMenuItems appProperties={appProperties} isBulkSMS={isBulkSMS} />
                  </Col>
                </Row>
              </div>
              {/* Content««««««««««««««««««««««««««««««««««««« */}
              {appProperties?.serviceInstalled ? (
                <>
                  {!showChatView ? (
                    <div className="container d-flex justify-content-center w-100 h-100 bg-white">
                      <div className="row w-100 h-100">
                        <div className="col h-100">
                          <Card bordered={null} style={{ boxShadow: "none", background: "#fff" }} bodyStyle={{ padding: "0px" }}>
                            <Form form={sendSMSForm} layout="vertical">
                              <PhoneNumbersList
                                handleFromNumberChange={handleFromNumberChange}
                                handleDefaultNumberChange={handleDefaultNumberChange}
                                savedNumbers={loadFromServiceList}
                                savedMessagingServices={savedMessagingServices}
                                defaultFromNumber={defaultFromNumber}
                              />
                              {isBulkSMS ? (
                                <>
                                  <RecipientsForBulk resource={resource} />
                                  {/* <FormInput
                                  formItemName={"campaignName"}
                                  formItemLabel={"Campaign Name"}
                                  formItemRules={[
                                    {
                                      required: true,
                                      message: (
                                        <span className="position-absolute">
                                          Required!
                                        </span>
                                      ),
                                    },
                                  ]}
                                  formExtraContent="Only for analytics purposes, not exposed to your customers."
                                  instantfocus={true}
                                /> */}
                                </>
                              ) : (
                                <Recipients handleToNumberChange={handleToNumberChange} toNumber={toNumber} />
                              )}
                              <MessagingArea
                                sendMessage={sendMessage}
                                loadingButton={loadingButton}
                                templateId={templateId}
                                setTemplateId={setTemplateId}
                                loadTemplates={loadTemplates}
                                showMMS={showMMS}
                                setLoading={props.setLoading}
                                resource={resource}
                                integId={integId}
                                appProperties={appProperties}
                                fromNumber={fromNumber}
                                toNumber={toNumber}
                              />
                            </Form>
                          </Card>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <ConversationWrapper chatView={true} setHelloSend={setHelloSend} />
                  )}
                </>
              ) : (
                <NoChannelPresent newTab={true} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function SendSMSButton(props) {
  const { sendMessage, message, setMessage, loadingButton, fileList, setFileList } = props;
  return (
    <Col span={6} className="d-flex justify-content-end align-items-center">
      <div
        onClick={() => sendMessage(fileList, setFileList, message, setMessage)}
        className="me-3 d-flex align-items-center justify-content-center sendSMSIconWrapper"
      >
        {loadingButton}
      </div>
    </Col>
  );
}

function SendMessageButtonWithIconsArea(props) {
  const { sendMessage, message, setMessage, showMMS, loadingButton, sendSMSLoading, fileList, integId, setFileList } = props;
  return (
    <Row className="rounded-bottom hs-bg-offwhite pb-3">
      <div className="h-auto w-100 d-flex justify-content-between align-items-end">
        <MediaUploadIconWrapper fileList={fileList} setFileList={setFileList} showMMS={showMMS} integId={integId} />
        <SendSMSButton
          fileList={fileList}
          setFileList={setFileList}
          message={message}
          setMessage={setMessage}
          sendMessage={sendMessage}
          loadingButton={loadingButton}
          sendSMSLoading={sendSMSLoading}
        />
      </div>
    </Row>
  );
}

const PhoneNumbersList = React.memo((props) => {
  const handleFromNumberChange = props.handleFromNumberChange;
  const handleDefaultNumberChange = props.handleDefaultNumberChange;
  const defaultFromNumber = props.defaultFromNumber;
  const savedMessagingServices = props.savedMessagingServices;

  let savedNumbers = props.savedNumbers;
  if (!savedNumbers.some((item) => item.value === "add::::phone")) {
    savedNumbers.push({
      value: "add::::phone",
      label: (
        <Button icon={<PlusOutlined style={{ verticalAlign: "middle" }} />} type="" className="addPhone-sendsms">
          Add Phone
        </Button>
      ),
    });
  }
  return (
    <Form.Item label={<span className="hs-fs-13">From</span>} className="mb-0">
      <Row>
        <Select
          tabIndex={3}
          className="rounded hs-bg-offwhite hs-focus-border h-45"
          id="fromPhoneNumber"
          value={defaultFromNumber !== undefined ? defaultFromNumber : ""}
          options={savedNumbers}
          autoFocus
          bordered={null}
          suffixIcon={<div className="actionIconsSprite fromNumberDropDownIcon" />}
          onChange={(v) => {
            console.log("selected from number >>>>>>>>>>>", v);
            handleFromNumberChange(v);
            handleDefaultNumberChange(v);
          }}
        />
      </Row>
    </Form.Item>
  );
});

function Recipients(props) {
  const handleToNumberChange = props.handleToNumberChange;
  const toNumber = props.toNumber;
  return (
    <Form.Item className="mb-0 p-0" label={<span className="hs-fs-13">To</span>}>
      <RecipientsNumber handleToNumberChange={handleToNumberChange} toNumber={toNumber} />
    </Form.Item>
  );
}
function RecipientsForBulk(props) {
  return (
    <div className="my-3">
      <span className="hs-fs-13">
        To: <b>Selected {props.resource}s</b>
      </span>
    </div>
  );
}

function RecipientsNumber(props) {
  const handleToNumberChange = props.handleToNumberChange;
  const toNumber = props.toNumber;
  return (
    <Row>
      <Input
        tabIndex={4}
        className="rounded hs-bg-offwhite hs-focus-border h-45 hs-fs-14"
        value={toNumber}
        bordered={null}
        id="toPhoneNumber"
        onChange={(e) => handleToNumberChange(e.target.value)}
      />
      <div className="d-flex w-100 justify-content-between">
        <span className="hs-fs-11 mt-1 mb-2">{"Include country code in 'TO' number"}</span>
        {/* Empty div for configure phone button in future */}
        <div>
          <ConfigurePhone />
        </div>
      </div>
    </Row>
  );
}

function MessagingArea(props) {
  const { sendMessage, loadingButton, loadTemplates, showMMS, resource, integId, appProperties, fromNumber, templateId, toNumber } = props;
  const { fetchData } = useHttp();
  const [message, setMessage] = useState();
  const [messageAreaLoading, setMessageAreaLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [pusherChannel, setPusherChannel] = useState(null);
  const messageBodyRef = useRef();

  const handlePusherCall = (e) => {
    if (!pusherChannel) {
      initPusher(fetchData, appProperties).then((res) => {
        setPusherChannel(res);
      });
    }
  };

  useEffect(() => {
    if (pusherChannel) {
      pusherChannel?.channel?.bind("CONVERSATION_UPDATE", (data) => {
        // throw error if comes
        if (data) {
          try {
            let response = JSON.parse(data?.data);
            console.log("CONVERSATION_UPDATE >>>>>", response);
            if (
              [fromNumber, toNumber, toNumber?.replace(/[\s()-]/g, "")].includes(response.sender_id) &&
              [fromNumber, toNumber, toNumber?.replace(/[\s()-]/g, "")].includes(response.receiver_id) &&
              response?.direction === 2
            ) {
              if (["failed", "undelivered", "delivered"].includes(response.messageStatus)) {
                response.class = "input_error";
                if (response.errorCode || response?.errorMessage) {
                  failureNotification(response?.errorCode, response?.errorMessage);
                }
              }
            }
          } catch (e) {
            console.error("ERROR::::", e);
          }
        }
      });
      return () => {
        pusherChannel?.channel?.unbind("CONVERSATION_UPDATE");
        pusherChannel.dispose();
      };
    }
  }, [pusherChannel]);
  function handleMessage(messageText) {
    setMessage(messageText);
    handlePusherCall(messageText);
  }

  function handleMedia(mediaFile) {
    setFileList(mediaFile ? mapToMediaList(mediaFile, false) : []);
  }

  useEffect(() => {
    messageBodyRef?.current?.focus();
  }, [templateId]);
  return (
    <Form.Item className="mb-0">
      <MessagingHeaderRow
        setLoading={props.setLoading}
        setTemplateId={props.setTemplateId}
        handleMessage={handleMessage}
        loadTemplates={loadTemplates}
        resource={resource}
        setMessageAreaLoading={setMessageAreaLoading}
        handleMedia={handleMedia}
      />
      <div className="rounded hs-bg-offwhite hs-focus-border">
        <Spin spinning={messageAreaLoading}>
          <TextArea
            ref={messageBodyRef}
            tabIndex={1}
            autoFocus
            className="hs-fs-14"
            placeholder={"Your text goes here"}
            size="large"
            autoSize={{ maxRows: 8, minRows: 8 }}
            bordered={null}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              handlePusherCall(e);
            }}
          />
        </Spin>
        <div className="d-flex align-items-end" style={{ minHeight: "48px" }}>
          <ReusableFilePreview fileList={fileList} setFileList={setFileList} shape={"circle"} direction={"horizontal"} customClasses={"ps-3"} />
        </div>
        <SendMessageButtonWithIconsArea
          fileList={fileList}
          setFileList={setFileList}
          showMMS={showMMS}
          message={message}
          setMessage={setMessage}
          sendMessage={sendMessage}
          integId={integId}
          loadingButton={loadingButton}
        />
      </div>
    </Form.Item>
  );
}

function MessagingHeaderRow(props) {
  const { setMessageAreaLoading, setTemplateId, setLoading, handleMessage, resource, loadTemplates, handleMedia } = props;
  const openAddTemplateDrawer = true;

  return (
    <>
      <Row className="pb-1 d-flex justify-content-between">
        <MessagingHeader />
        <SavedTemplatesSelect
          tabIndex={2}
          moduleName={resource}
          setMessageAreaLoading={setMessageAreaLoading}
          setTemplateId={setTemplateId}
          setLoading={setLoading}
          handleMessage={handleMessage}
          loadTemplates={loadTemplates}
          openAddTemplateDrawer={openAddTemplateDrawer}
          showPreview={true}
          handleMedia={handleMedia}
        />
      </Row>
    </>
  );
}

function MessagingHeader() {
  return (
    <Col span={6} className="d-flex justify-content-start align-items-center">
      <span className="hs-fs-14">Message</span>
    </Col>
  );
}

export default SendSMS;
