import React, { useState } from "react";
import { Row, Col, Drawer, Menu } from "antd";
import SubAccountsListing from "../subAccount/SubAccountsListing";

export function TopMenuItems(props) {
  var urlParams = new URLSearchParams(window.location.search);
  urlParams.delete("sideBar");
  urlParams.delete("topBar");

  let settingsMenuItems = [
    {
      key: "2",
      icon: <Col className="actionIconsSpriteForSideBar inboxIcon" />,
      className: "d-flex align-items-center cursor-pointer",
      label: (
        <span>
          <a style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer" href={`/inbox?${urlParams}`} className="text-dark">
            Inbox
          </a>
        </span>
      ),
    },
    {
      key: "3",
      icon: <Col className="actionIconsSpriteForSideBar campaignIcon" />,
      className: "d-flex align-items-center cursor-pointer",
      label: (
        <span>
          <a style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer" href={`/campaign?${urlParams}`} className="text-dark">
            Campaigns
          </a>
        </span>
      ),
    },
    {
      key: "4",
      icon: <Col className="actionIconsSpriteForSideBar templatesIcon" />,
      className: "d-flex align-items-center cursor-pointer",
      label: (
        <span>
          <a style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer" href={`/templates?${urlParams}`} className="text-dark">
            Templates
          </a>
        </span>
      ),
    },
    {
      key: "5",
      icon: <Col className="actionIconsSpriteForSideBar overviewIcon" />,
      className: "d-flex align-items-center cursor-pointer",
      label: (
        <span>
          <a
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
            href={`/campaigns/list?${urlParams}`}
            className="text-dark"
          >
            Analytics
          </a>
        </span>
      ),
    },
  ];
  const [settingsOffCanvas, setSettingsOffCanvas] = useState(false);
  const showSettingsMenu = () => {
    setSettingsOffCanvas(true);
  };
  const onClose = () => {
    setSettingsOffCanvas(false);
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <span className={props.class} onClick={showSettingsMenu}>
          <div className="actionIconsSprite sendSMSMoreMenuIcon" alt="menu" />
        </span>
      </div>

      {/* {props.appProperties !== undefined && props.appProperties.licenseObj ? (
        <NewAnnouncement
        description={"Elevate Your Messaging with SMS Campaigns!"}
        title={"Whats New"}
        />
      ) : null} */}
      <Drawer
        className="removeOutlineAntDrawer"
        title={
          <Row className="">
            <Col span={8} onClick={onClose}>
              <div className="d-flex justify-content-start">
                <div className="actionIconsSprite topBarArrow" alt="menu" />
              </div>
            </Col>
            <Col span={8}>
              <div className="d-flex justify-content-center">
                <div>Menu</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="d-flex justify-content-end">
                <div className="sprite-icon topBarHellosend" alt="menu" />
              </div>
            </Col>
          </Row>
        }
        placement="right"
        closable={false}
        onClose={onClose}
        width={250}
        open={settingsOffCanvas}
      >
        <Row>
          <Col span={24}>
            <SubAccountsListing closeOffCanvas={onClose} selectComponentWidth={"90%"} selectComponentHeight={50} showSubHeader={true} />
          </Col>
        </Row>
        <Menu className="settingsMenuListClass custom-menu" items={settingsMenuItems} />
      </Drawer>
      {/* <Drawer title="Basic Drawer" onClose={onClose} open={settingsOffCanvas}>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Drawer> */}
      {/* <Dropdown
        trigger={["click"]}
        menu={{
          items,
        }}
        overlayClassName="sendSMSTopBarWidth"
      > */}

      {/* </Dropdown> */}
    </>
  );
}
