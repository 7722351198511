import { CheckOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Drawer, Dropdown, Input, Menu, Modal, Row, Select, Skeleton, Space, Spin, Table } from "antd";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import ReusableFilePreview from "../components/custom/ReusableFilePreview";
import { AppContext } from "../context/AppContext";
import { SavedTemplatesSelect } from "../pages/outbound/SavedTemplatesSelect";
import "./../assets/css/automationMessages.css";
import { failureNotification, successNotification } from "./utils/CommonNotifications";

import { Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import useHttp from "../hooks/useHttp";
import useTemplates from "../hooks/useTemplates";
import { mapToMediaList } from "./utils/commonUtils";
import { CommonLoadingV2, FromNumberComponent, NoChannelPresent, formatDate } from "./utils/CommonVessels";
const { Text } = Typography;

function AutomatedMessages() {
  const [appProperties] = useContext(AppContext);
  const [tableData, setTableData] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messageAreaLoading, setMessageAreaLoading] = useState(false);
  const [addBtnLoading, setAddBtnLoading] = useState(false);
  const [savedNumbersList, setSavedNumbersList] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [valueObj, setValueObj] = useState({});
  const { fetchData } = useHttp();
  const method = useRef(null);
  const friendlyNameRef = useRef(null);
  const [visibleDropdown, setVisibleDropdown] = useState(null);

  const [record, setRecord] = useState(null);

  const handleMenuClick = (e, record) => {
    setVisibleDropdown(visibleDropdown === record.eventId ? null : record.eventId);
  };

  const menu = (record) => <AutomatedMessageDelete record={record} />;

  const AutomatedMessageDelete = (props) => {
    const [open, setOpen] = useState(false);
    const { record } = props;

    const handleDeleteClick = () => {
      setOpen(true);
      setRecord(record);
      setVisibleDropdown(null); // Close the dropdown when opening the modal
    };
    return (
      <>
        <Menu>
          <Menu.Item
            key="editTemplate"
            onClick={() => {
              method.current = "edit";
              setDrawerOpen(true);
              setValueObj(record);
            }}
          >
            <div className="actionIconsSprite editTemplate-icon">
              <span className="ps-4" style={{ fontSize: "12px" }}>
                Edit
              </span>
            </div>
          </Menu.Item>
          <Menu.Item key="deletetemplate" onClick={() => handleDeleteClick(record)}>
            <div className="actionIconsSprite deleteTemplate-icon">
              <span className="ps-4" style={{ fontSize: "12px" }}>
                Delete
              </span>
            </div>
          </Menu.Item>
        </Menu>
        <DeleteComponent setTableData={setTableData} record={record} appProperties={appProperties} setModalOpen={setOpen} modalOpen={open} />
      </>
    );
  };
  const columns = [
    {
      title: "Webhook URL",
      key: "eventUrl",
      dataIndex: "eventUrl",
      width: "14%",
    },
    {
      title: "Friendly Name",
      dataIndex: "friendlyName",
      key: "friendlyName",
      width: "12%",
      ellipsis: true,
    },
    {
      title: "Module",
      dataIndex: "module",
      key: "module",
      width: "9%",
    },
    {
      title: "Created Time",
      key: "createdTime",
      dataIndex: "createdTime",
      width: "16%",
    },
    {
      key: "editDeleteSettings",
      render: (record) => (
        <Dropdown
          rootClassName="editDeletSettingDropdown"
          overlay={menu(record)}
          trigger={["click"]}
          visible={visibleDropdown === record.eventId}
          onVisibleChange={() => handleMenuClick(null, record)}
        >
          <div className="actionIconsSprite  editDeleteSettingsIcon"></div>
        </Dropdown>
      ),
      width: "5%",
    },
  ];
  const mapDataToTable = (dataList) => {
    dataList = dataList?.map((obj) => {
      return {
        ...obj,
        eventUrl: (
          <Text
            key={obj?.eventUrl}
            className="d-flex w-100 align-items-center mb-0 hs-color-violet copy-icon-webhook"
            copyable={{
              text: obj?.eventUrl,
              icon: [
                <span key="copy-icon" style={{ textDecoration: "underline" }}>
                  Copy Webhook URL
                </span>,
                <CheckOutlined key="copied-icon" />,
              ],
            }}
          />
        ),
        createdTime: formatDate(obj.createdTime),
        key: obj.eventId,
        module: <span className="text-capitalize">{obj?.event?.split("_")?.reverse()?.join(" / ")}</span>,
        fieldApi: JSON.parse(obj?.phoneField),
      };
    });
    return dataList;
  };

  useEffect(() => {
    let integId = appProperties?.randomIntegId;
    if (appProperties.serviceInstalled && integId) {
      setLoading(true);
      fetchData(`omessage/${integId}/webhookNotifications?type=url`, "GET", null, appProperties)
        .then(function (response) {
          setLoading(false);
          if (response) {
            let data = JSON.parse(response);
            let dataList = data?.data?.webhookNotifications;
            dataList = mapDataToTable(dataList);
            setTableData(dataList);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [appProperties.serviceInstalled, fetchData, appProperties]);

  useEffect(() => {
    if (method.current === "edit") {
      setVisibleDropdown(null);
      let integId = appProperties.randomIntegId;
      if (integId && valueObj?.templateId) {
        fetchData(`omessage/${integId}/template/${valueObj?.templateId}/preview`, "GET", null, appProperties).then(function (response) {
          if (response) {
            const responseData = JSON.parse(response);
            if (responseData?.data?.data) {
              setValueObj((prev) => ({
                ...prev,
                message: responseData?.data?.data,
              }));
            }
          }
        });
      }
    }
  }, [method, drawerOpen, savedNumbersList, valueObj?.templateId, appProperties, fetchData]);
  useEffect(() => {
    if (drawerOpen) {
      if (method.current !== "edit") {
        const phoneObj = savedNumbersList?.find((obj) => obj?.defaultNumber === 1) || savedNumbersList?.[0];
        setValueObj((prev) => ({
          ...prev,
          phoneNum: phoneObj?.phoneNumber,
          integId: phoneObj?.integId,
        }));
      }
      friendlyNameRef.current?.focus();
    }
  }, [drawerOpen, savedNumbersList]);

  const handleAddAutomation = () => {
    let notificationMessage = "";
    if (
      valueObj.integId &&
      valueObj.phoneNum &&
      valueObj.fieldApi &&
      valueObj.templateId &&
      valueObj.friendlyName &&
      valueObj?.integId &&
      valueObj.fieldApi.length > 0
    ) {
      delete valueObj.eventUrl;
      delete valueObj.key;
      valueObj.fieldApiList = valueObj?.fieldApi;
      delete valueObj.fieldApi;
      setAddBtnLoading(true);
      var targetUrl;
      var targetMethod;
      if (method.current === "edit") {
        notificationMessage = "Webhook edited successfully. Review and test the changes.";
        targetUrl = `omessage/${valueObj?.integId}/webhookNotification/${valueObj?.eventId}`;
        targetMethod = "PUT";
      } else {
        notificationMessage = "Webhook configured! Automate messaging with workflow rules.";
        targetUrl = `omessage/${valueObj?.integId}/addWebhook`;
        targetMethod = "POST";
      }
      delete valueObj.createdTime;
      delete valueObj.modifiedTime;
      fetchData(targetUrl, targetMethod, valueObj, appProperties).then(function (response) {
        if (response) {
          successNotification(notificationMessage);
          let data = JSON.parse(response);
          let webhookNotificationEntity = data?.data?.data;
          webhookNotificationEntity = mapDataToTable([webhookNotificationEntity]);
          setTableData((prev) => {
            var existingIndex = prev?.findIndex((item) => item?.eventId === valueObj?.eventId);
            if (existingIndex !== -1) {
              const newList = [...(prev || [])];
              newList[existingIndex] = webhookNotificationEntity?.[0];
              return newList;
            } else {
              return [...(prev || []), ...webhookNotificationEntity];
            }
          });
          setDrawerOpen(false);
          setValueObj({});
          setAddBtnLoading(false);
        } else {
          setAddBtnLoading(false);
        }
      });
    } else {
      const fieldsObj = {
        friendlyName: "Friendly name is empty",
        integId: "From number is not valid",
        phoneNum: "From number is not valid",
        fieldApi: "Selected field is not valid",
        templateId: "Selected template is not valid",
      };
      const fields = Object.keys(fieldsObj);
      const nullField = fields.find((field) => {
        const value = valueObj[field];
        return !value || value?.length < 1;
      });
      if (nullField) {
        failureNotification("Error", `${fieldsObj[nullField]} ! `);
      }
    }
  };

  const handleValueObj = useCallback(
    (newValueObj) => {
      setValueObj(newValueObj);
    },
    [setValueObj]
  );
  const handleMedia = (mediaFiles) => {
    setFileList(mapToMediaList(mediaFiles, false));
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-between m-3 me-5">
        <div className="ms-3">
          <Row className="mt-2">
            <Col className="actionIconsSprite authInfo-icon"></Col>
            <Col className="ms-2">
              Configure webhook to Automate SMS based on any Workflow rules, to know more{" "}
              <a
                href="https://help.oapps.xyz/portal/en/kb/articles/automate-sms-in-pipedrive"
                target="_blank"
                rel="noreferrer"
                className="custom-anchor"
              >
                Click here
              </a>
            </Col>
          </Row>
        </div>
        <Button
          className="addTempBtn me-3"
          onClick={() => {
            method.current = "add";
            setDrawerOpen(true);
          }}
          icon={<div className="actionIconsSprite plusIconWhite" />}
        >
          Create Webhook
        </Button>
      </div>

      <div className="mt-3">
        {appProperties.serviceInstalled ? (
          <>
            {!loading ? (
              <Row className="m-2">
                <Col span={15} className="ms-4">
                  <Table
                    className="automationTable"
                    m-3
                    me-5
                    mb-2
                    dataSource={tableData}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 700 }}
                  />
                </Col>
                <Col span={8} className="p-2">
                  <VideoComponent appProperties={appProperties} />
                </Col>
              </Row>
            ) : (
              <>
                {" "}
                <Space className="p-5 pb-1 pt-3 d-flex justify-content-between">
                  <Skeleton.Button active size={"small"} shape={""} />
                  <Skeleton.Input active size={"small"} />
                </Space>
                <Skeleton className="p-5 pt-2" active size="small" paragraph={{ rows: 12 }} />
              </>
            )}
            <Drawer
              open={drawerOpen}
              closable
              title={method.current === "edit" ? "Edit Configuration" : "Create Webhook"}
              width={500}
              onClose={() => {
                setDrawerOpen(false);
                setValueObj({});
              }}
              className="automation-drawer"
              footer={
                <>
                  {method.current === "edit" && (
                    <>
                      <div className="p-3 w-100 trialAccount ">
                        <ExclamationCircleOutlined style={{ fontSize: 20, color: "#FF9800" }} />
                        <div>
                          After configuration editing, the webhook URL remains unchanged, no need to update the{" "}
                          {appProperties?.leftServiceDisplayName} automation
                        </div>
                      </div>
                    </>
                  )}
                  <div className="d-flex align-items-center justify-content-end">
                    <Button
                      loading={addBtnLoading}
                      className="addTempBtn w-100 m-3 justify-content-center hs-fs-16"
                      onClick={handleAddAutomation}
                    >
                      {method.current === "edit" ? "Edit Configuration" : "Create Webhook URL"}
                    </Button>
                  </div>
                </>
              }
            >
              <Space direction="vertical w-100">
                Friendly Name
                <Input
                  placeholder="Enter friendly name"
                  className="rounded hs-bg-offwhite focus-border h-45 font-size-14"
                  value={valueObj?.friendlyName}
                  ref={friendlyNameRef}
                  onChange={(e) => {
                    setValueObj((prev) => ({
                      ...prev,
                      friendlyName: e.target.value,
                    }));
                  }}
                />
                From
                <FromNumberComponent
                  disabled={method.current === "edit" ? true : false}
                  customClasses={"rounded hs-bg-offwhite focus-border h-45 "}
                  fromNumber={valueObj?.phoneNum}
                  handleFromNumberChange={(e) => {
                    setValueObj((prev) => ({ ...prev, phoneNum: e }));
                    setValueObj((prev) => ({
                      ...prev,
                      integId: savedNumbersList?.find((obj) => obj?.phoneNumber === e)?.integId,
                    }));
                  }}
                  savedNumbersList={savedNumbersList}
                  setSavedNumbersList={setSavedNumbersList}
                />
                To
                <ToNumberComponent
                  method={method.current}
                  valueObj={valueObj}
                  setValueObj={handleValueObj}
                  drawerOpen={drawerOpen}
                  setFileList={setFileList}
                />
                <div className="d-flex align-items-center justify-content-between m-3 mb-0">
                  <span>Message</span>
                  <SavedTemplatesSelect
                    setMessageAreaLoading={setMessageAreaLoading}
                    setTemplateId={(e) => {
                      setValueObj((prev) => ({ ...prev, templateId: e }));
                    }}
                    setLoading={null}
                    handleMessage={(e) => {
                      setValueObj((prev) => ({ ...prev, message: e }));
                    }}
                    handleMedia={handleMedia}
                    loadTemplates={true}
                    templateId={valueObj?.templateId}
                    showPreview={false}
                    refetchOnModuleChange={true}
                    moduleName={valueObj?.event?.split("_")[valueObj?.event?.split("_")?.length - 1]}
                  />
                </div>
                <Spin spinning={messageAreaLoading}>
                  <Row className="justify-content-center m-3 rounded hs-bg-offwhite focus-border">
                    <Col xs={24}>
                      <TextArea
                        className="pe-none rounded m-2 font-size-14"
                        placeholder={"Your text goes here"}
                        size="large"
                        autoSize={{ maxRows: 8, minRows: 8 }}
                        bordered={null}
                        value={valueObj.message}
                        onChange={(e) => {
                          setValueObj((prev) => ({
                            ...prev,
                            message: e.target.value?.trim(),
                          }));
                        }}
                      />
                      <div className="d-flex align-items-end" style={{ minHeight: "48px" }}>
                        <ReusableFilePreview
                          fileList={fileList}
                          setFileList={setFileList}
                          shape={"circle"}
                          direction={"horizontal"}
                          customClasses={"ps-3 ms-1"}
                        />
                      </div>
                    </Col>
                  </Row>
                </Spin>
              </Space>
            </Drawer>
          </>
        ) : (
          <NoChannelPresent />
        )}
      </div>
    </>
  );
}
const ToNumberComponent = ({ valueObj, method, setValueObj, drawerOpen, setFileList }) => {
  const memoizedSetValueObj = useCallback(
    (newValueObj) => {
      setValueObj(newValueObj);
    },
    [setValueObj]
  );
  const { fetchData } = useHttp();
  const [appProperties] = useContext(AppContext);
  const { fetchModules, fetchAllFields } = useTemplates();
  const [modulesList, setModulesList] = useState([]);
  const [associatedModules, setAssociatedModules] = useState([]);
  const [fieldOptions, setFieldOptions] = useState(null);
  const [fieldData, setFieldData] = useState(null);
  const [searchDropdownLoading, setSearchDropdownLoading] = useState(<div className="actionIconsSprite fromNumberDropDownIcon" />);
  useEffect(() => {
    if (valueObj?.event) {
      appProperties?.controller?.associatedModules().then(function (response) {
        setAssociatedModules(response);
      });
      setSearchDropdownLoading(<div className="actionIconsSprite search-loading"></div>);
      fetchAllFields(appProperties, valueObj?.event).then(function (response) {
        if (response) {
          let allFields = [];
          response?.data?.map((obj) => {
            let fields = JSON.parse(obj.data);
            let byPhone = fields?.filter((field) => field?.fieldType === "phone");
            allFields.push(...byPhone);
            return obj;
          });
          setFieldOptions(allFields);
          setFieldData(allFields);

          setSearchDropdownLoading(<div className="actionIconsSprite fromNumberDropDownIcon" />);
        } else {
          setSearchDropdownLoading(<div className="actionIconsSprite fromNumberDropDownIcon" />);
        }
      });
    }
  }, [appProperties.serviceInstalled, appProperties, fetchData, valueObj?.event, fetchAllFields]);

  useEffect(() => {
    fetchModules(appProperties, null, "templates").then(function (response) {
      let modules = response;
      let allModules = modules?.data;
      allModules = allModules?.map((obj) => ({
        label: <span className="text-capitalize">{obj}</span>,
        value: obj,
      }));
      setModulesList(allModules);
    });
  }, [appProperties, fetchModules, method, memoizedSetValueObj]);

  useEffect(() => {
    if (fieldData && valueObj?.event) {
      let supportedFields = fieldData?.filter((obj) => associatedModules[valueObj?.event]?.includes(obj?.moduleName));
      setFieldOptions(supportedFields);
    }
  }, [fieldData, drawerOpen, valueObj?.event, associatedModules]);

  useEffect(() => {
    if (drawerOpen && method !== "edit") {
      memoizedSetValueObj((prev) => ({
        ...prev,
        event: modulesList?.[0]?.value,
      }));
    }
  }, [method, memoizedSetValueObj, modulesList, drawerOpen]);

  return (
    <div className="d-flex align-items-center hs-bg-offwhite rounded">
      <Select
        options={modulesList}
        allowClear={false}
        className="rounded hs-bg-offwhite h-45"
        onChange={(e) => {
          memoizedSetValueObj((prev) => ({
            ...prev,
            event: e,
            fieldApi: null,
            fieldName: null,
            templateId: null,
            message: null,
          }));
          setFileList([]);
        }}
        placeholder="Select Module "
        value={valueObj?.event}
        suffixIcon={
          <>
            <div className="actionIconsSprite fromNumberDropDownIcon" />
          </>
        }
        style={{ minWidth: 170 }}
        bordered={false}
      />{" "}
      <Divider type="vertical" className="webhook-divider-vertical" />
      <Select
        mode="multiple"
        className="rounded hs-bg-offwhite h-100 overflow-auto"
        placeholder={`Select fields`}
        suffixIcon={
          <div className="d-flex" style={{ height: "45px", marginTop: "16px" }}>
            <span>{searchDropdownLoading}</span>
          </div>
        }
        filterOption={false}
        options={
          (fieldOptions &&
            fieldOptions?.map((obj) => ({
              label: (
                <span>
                  {valueObj?.event === obj?.moduleName ? (
                    obj?.fieldName
                  ) : (
                    <span className="text-capitalize d-flex">
                      {obj?.moduleName?.length > 8 ? obj?.moduleName?.slice(0, 3) : obj?.moduleName}
                      <span className="actionIconsSprite right-arrow-icon" /> {obj?.fieldName}
                    </span>
                  )}
                </span>
              ),
              value: obj?.fieldApi,
            }))) ||
          []
        }
        onDeselect={(e) => {
          let item = fieldOptions?.find((obj) => obj.fieldApi === e);
          memoizedSetValueObj((prev) => {
            const newFieldApi = (valueObj?.fieldApi || []).filter((str) => {
              const key = Object.keys(str)[0];
              return str[key] !== item.fieldApi;
            });

            const newFieldName = (valueObj?.fieldName || []).filter((name) => name !== item.fieldName);

            return {
              ...prev,
              fieldApi: newFieldApi,
              fieldName: newFieldName,
            };
          });
        }}
        onSelect={(e) => {
          let item = fieldOptions?.find((obj) => obj.fieldApi === e);
          memoizedSetValueObj((prev) => ({
            ...prev,
            fieldApi: [
              ...(valueObj?.fieldApi || []),
              {
                [valueObj?.event === item.moduleName ? item.moduleName : valueObj?.event + "_::_" + item.moduleName]: item.fieldApi,
              },
            ],
            fieldName: [...(valueObj?.fieldName || []), item.fieldName],
          }));
        }}
        value={valueObj?.fieldApi?.map((obj) => Object.values(obj)[0])}
        bordered={false}
        style={{ minWidth: 250, maxHeight: 120, minHeight: 36 }}
      />
    </div>
  );
};
export default AutomatedMessages;

const DeleteComponent = ({ record, setTableData, appProperties, setModalOpen, modalOpen }) => {
  const [loading, setLoading] = useState(false);
  const [outlineColor, setOutlineColor] = useState(false);
  const [deleteText, setDeleteText] = useState("");
  const { fetchData } = useHttp();
  const inputRef = useRef(null);

  useEffect(() => {
    if (modalOpen) {
      inputRef.current.focus();
    }
  }, [modalOpen]);

  const closeModal = () => {
    setModalOpen(false);
    setOutlineColor(false);
    setDeleteText("");
  };

  const handleDeleteAutomation = () => {
    if (deleteText === "delete" && record?.integId && record?.eventId) {
      setLoading(true);
      fetchData(`omessage/${record?.integId}/webhookNotification/${record?.eventId}?type=automation`, "DELETE", record, appProperties).then(
        (response) => {
          if (response) {
            successNotification("Webhook deleted successfully. Consider adding a new one to automate SMS");
            response = JSON.parse(response);
            const webhookNotificationEntity = response?.data?.data;
            setTableData((prev) => prev.filter((obj) => obj?.eventId !== webhookNotificationEntity?.eventId));
            closeModal();
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      );
    } else if (deleteText !== "delete") {
      setOutlineColor(true);
      setDeleteText("");
    }
  };

  return (
    <>
      <Modal
        open={modalOpen}
        onCancel={closeModal}
        width={600}
        centered
        footer={
          <div className="d-flex align-items-center justify-content-between mt-3 m-2">
            <Button className="w-100 me-2 h-45 hs-fs-16 commonGhostButton" onClick={closeModal}>
              No, cancel
            </Button>
            <Button className="w-100 ms-2 h-45 addTempBtn hs-fs-16" type="primary" onClick={handleDeleteAutomation}>
              Yes, confirm
            </Button>
            {loading ? <CommonLoadingV2 /> : null}
          </div>
        }
      >
        <Row>
          <Col span={3}>
            <div className="phoneLogoSprites delete-icon mt-4"></div>
          </Col>
          <Col span={21} className="mt-2">
            <div>
              <div style={{ fontWeight: 600, fontSize: 18, color: "#030229" }}>Are you sure you want to disconnect?</div>
              <div className="m-1" style={{ color: "rgba(3, 2, 41, 0.5)" }}>
                This webhook URL may be connected to Automation rules, which could result in your customers not receiving important messages or
                updates. We won't recommend deleting the webhook URL. Please type 'delete' to proceed.
              </div>
              <Input
                ref={inputRef}
                placeholder="delete"
                className="rounded hs-bg-offwhite m-2 h-45"
                bordered={false}
                value={deleteText}
                style={{
                  width: 120,
                  outline: outlineColor ? "1.5px solid red" : "1.5px solid rgba(96, 91, 255, 1)",
                }}
                onChange={(e) => {
                  setDeleteText(e.target.value);
                }}
              />
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

const VideoComponent = ({ appProperties }) => {
  const videoObj = useMemo(
    () => [
      {
        headline: "Configuring Automated SMS in 2 Simple Steps:",
        sourceUrl: "https://www.youtube.com/embed/iv2i324FmRc?rel=0",
        steps: ["Create Webhook URL", `Set Up an Automation in ${appProperties?.leftServiceDisplayName}`],
      },
      {
        headline: `Automate Text Messages When Deal Stages Change in ${appProperties?.leftServiceDisplayName}`,
        sourceUrl: "https://www.youtube.com/embed/nxXgaxZPXU4?rel=0",
        steps: ["Customize SMS Templates for Every Pipeline Stage", `Efficiently Map and Automate with Webhooks`],
      },
      {
        headline: `Automate SMS in ${appProperties?.leftServiceDisplayName}`,
        sourceUrl: "https://www.youtube.com/embed/iUayvyukksM?rel=0",
        steps: ["Create templates and Automated webhooks.", `Create workflow rules for business requirements and automate SMS`],
      },
    ],
    [appProperties?.leftServiceDisplayName]
  );
  const [openVideoIndex, setOpenVideoIndex] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [videoSourceObj, setVideoSourceObj] = useState(videoObj);

  useEffect(() => {
    let filteredObj = videoObj.filter((item) => item.headline.toLowerCase().includes(searchInput.toLowerCase()));
    setVideoSourceObj(filteredObj);
  }, [searchInput, videoObj]);

  return (
    <div>
      <Input
        placeholder="Search videos"
        className="hs-bg-offwhite rounded border-default focus-border h-45 mb-2"
        bordered={false}
        prefix={<span className="actionIconsSprite search-icon"></span>}
        onChange={(e) => {
          setSearchInput(e.target.value?.trim());
        }}
      />
      <div className="overflow-auto d-flex flex-grow-1 flex-column" style={{ maxHeight: "70vh" }}>
        {videoSourceObj.map((obj, index) => (
          <div key={index} className="d-flex align-item-center video-component-wrap mb-2">
            <div className="video-info  d-flex align-items-center justify-content-center">
              <div className={`video-thumbnail-${index}`}>
                <div
                  className="actionIconsSprite videoLogo-webhook m-auto"
                  onClick={() => {
                    setOpenVideoIndex(index);
                  }}
                ></div>
              </div>
              {openVideoIndex === index && (
                <Modal
                  className="webhook-video-modal"
                  footer={null}
                  open={openVideoIndex === index}
                  onCancel={() => {
                    setOpenVideoIndex(null);
                  }}
                  centered
                  width={"80vw"}
                  style={{
                    top: 20,
                    height: "80vh",
                  }}
                >
                  <div className="iframe-wrapper">
                    <iframe
                      src={obj.sourceUrl}
                      title="Oapps"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                    ></iframe>
                  </div>
                </Modal>
              )}
            </div>
            <div className="ms-2 video-info-list">
              <p>{obj?.headline}</p>
              <ol>
                {obj?.steps?.map((listItem, index) => (
                  <li key={index}>{listItem}</li>
                ))}
              </ol>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
