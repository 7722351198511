import { Button, Row, Col } from "antd";
import { Link, useLocation } from "react-router-dom";

export default function CampaignHeader(props) {
  const { appProperties } = props;
  const isAuthorized = appProperties.installedApps?.[0]?.right?.auth.authorized;
  const location = useLocation();
  return (
    <div className="d-flex justify-content-between ms-3">
      <div>
        {!isAuthorized ? (
          <Button type="primary" className="hs-bg-violet">
            <Link className="all-unset" to={"/channels" + location.search}>
              Add Channel
            </Link>
          </Button>
        ) : (
          // Empty div for alignment purpose
          <div></div>
        )}
      </div>
    </div>
  );
}
