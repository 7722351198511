import React, { useContext, useEffect, useState } from "react";
import { PlusOutlined, SearchOutlined, ExportOutlined, LoadingOutlined } from "@ant-design/icons";

import { Button, Input, Select, Row, Col, Table, List, Drawer, Image, Popover, Space, Menu, Dropdown } from "antd";
import useTemplates from "../hooks/useTemplates";
import useWorkFlow from "../hooks/useWorkFlow";
import { AppContext } from "../context/AppContext";
import "./../assets/css/templates.css";
import { DeleteComponent } from "./utils/CommonVessels";

import EmtpyBoxSVG from "../assets/svg/empty-box .svg";

import { failureNotificationWithLink, successNotifyWithDescription } from "./utils/CommonNotifications";
import { CommonLoading, NoChannelPresent, formatDate } from "./utils/CommonVessels";
import { ASSIST_MAIL } from "../constants/AppConstants";
import MediaUploadIconWrapper from "../components/custom/MediaUploadIconWrapper";
import ReusableFilePreview from "../components/custom/ReusableFilePreview";
import { mapToMediaList } from "./utils/commonUtils";

function Templates() {
  const [appProperties] = useContext(AppContext);
  const { fetchSavedTemplates, deleteTemplate } = useTemplates();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [savedTemplates, setSavedTemplates] = useState([]);
  const [callSavedTempTable, setCallSavedTempTable] = useState(true);

  const [selectedModule, setSelectedModule] = useState("");
  const [content, setContent] = useState({
    deal: "",
    lead: "",
    person: "",
    organization: "",
  });
  const [fileList, setFileList] = useState([]);
  const [friendlyName, setFriendlyName] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [tempModal, setTempModal] = useState("");

  const [openPopover, setOpenPopover] = useState({});
  const [showTable, setShowTable] = useState(false);
  const [isWfDrawerOpen, setIsWfDrawerOpen] = useState(false);
  const [isWorkFlowIcon, setIsWorkFlowIcon] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [disabledSelect, setDisabledSelect] = useState(false);
  const [visibleDropdown, setVisibleDropdown] = useState(null);

  let urlParams = new URLSearchParams(window.location.search);
  let openDrawer = urlParams.get("openDrawer");
  let moduleResource = urlParams.get("resource");

  console.log("selectedModule>>>>>>>", selectedModule);

  useEffect(() => {
    if (openDrawer) {
      setIsDrawerOpen(true);
      setTempModal("AddTemp");
      setSelectedModule(moduleResource);
    }
  }, []);

  const addTemplate = () => {
    setIsDrawerOpen(true);
    setFriendlyName("");
    setSelectedModule(moduleResource);
    setTemplateId("");
    setDisabledSelect(false);
    setTempModal("AddTemp");
  };
  const editTemplate = (tempData) => {
    setVisibleDropdown(null);
    setFriendlyName(tempData.friendlyName);
    setSelectedModule(tempData.moduleName);
    setDisabledSelect(true);
    setContent({ ...content, [tempData.moduleName]: tempData.content });
    setTemplateId(tempData.templateId);
    setTempModal("updateTemp");
    setFileList(tempData?.mediaFileList ? mapToMediaList(tempData?.mediaFileList, false) : []);
    setIsDrawerOpen(true);
  };
  const workflowChart = (tempData) => {
    setTemplateId(tempData.templateId);

    setVisibleDropdown(null);
    setIsWfDrawerOpen(true);
  };

  const deleteTemp = (templateData, setModalOpen) => {
    var templateId = templateData.templateId;
    deleteTemplate(appProperties, templateId).then((response) => {
      if (response?.success === true) {
        setCallSavedTempTable(true);
        successNotifyWithDescription("Delete Template", "Template deleted. Create a new one as needed.");
      }
      setOpenPopover((prevOpenPopover) => ({
        ...prevOpenPopover,
        [templateId]: false,
      }));
    });
  };
  const handleMenuClick = (e, record) => {
    setVisibleDropdown(visibleDropdown === record.templateId ? null : record.templateId);
  };

  const menu = (record) => <TemplateDelete record={record} />;

  const TemplateDelete = (props) => {
    const [open, setOpen] = useState(false);
    const { record } = props;

    const handleDeleteClick = () => {
      setOpen(true);
      setVisibleDropdown(null); // Close the dropdown when opening the modal
    };
    return (
      <>
        <Menu>
          {isWorkFlowIcon && (
            <Menu.Item className="actionIconsSprite workflow-icon mb-2" key="workflowIcon" onClick={() => workflowChart(record)}>
              <span className="ps-3" style={{ fontSize: "12px" }}>
                Workflow
              </span>
            </Menu.Item>
          )}
          <Menu.Item key="editTemplate" onClick={() => editTemplate(record)}>
            <div className="actionIconsSprite editTemplate-icon">
              <span className="ps-4" style={{ fontSize: "12px" }}>
                Edit
              </span>
            </div>
          </Menu.Item>
          <Menu.Item key="deletetemplate" onClick={() => handleDeleteClick(record)}>
            <div className="actionIconsSprite deleteTemplate-icon">
              <span className="ps-4" style={{ fontSize: "12px" }}>
                Delete
              </span>
            </div>
          </Menu.Item>
        </Menu>
        <DeleteComponent
          modalOpen={open}
          setModalOpen={setOpen}
          record={record}
          confirmDeleteComponent={"template? It might still be used in automations or campaigns."}
          appProperties={appProperties}
          handleDelete={deleteTemp}
        />
      </>
    );
  };

  const columns = [
    {
      title: "Friendly Name",
      dataIndex: "friendlyName",
      key: "friendlyName",
      width: 88,
    },
    {
      title: "Module",
      dataIndex: "moduleName",
      key: "moduleName",
    },
    {
      title: "Template ID",
      dataIndex: "templateId",
      key: "templateId",
      width: 120,
    },
    {
      title: "Created Time",
      key: "createdTime",
      dataIndex: "createdTime",
    },
    {
      key: "editDeleteSettings",
      render: (record) => (
        <Dropdown
          rootClassName="editDeletSettingDropdown"
          overlay={menu(record)}
          trigger={["click"]}
          visible={visibleDropdown === record.templateId}
          onVisibleChange={() => handleMenuClick(null, record)}
        >
          <div className="actionIconsSprite  editDeleteSettingsIcon"></div>
        </Dropdown>
      ),
      width: "5%",
    },
  ];
  useEffect(() => {
    if ((appProperties?.serviceInstalled && callSavedTempTable === true) || appProperties.subAccount) {
      console.log("fetchSavedTemplates >>>>>>>>>>>>>>>>>");
      fetchSavedTemplates(appProperties).then((response) => {
        const updatedSavedTable = response?.data?.map((tempobj) => {
          return {
            ...tempobj,
            createdTime: <div>{formatDate(tempobj?.createdTime)}</div>,
          };
        });

        setSavedTemplates(updatedSavedTable);

        if (response?.data !== null) {
          setShowTable(true);
          setIsWorkFlowIcon(appProperties.controller.workFlowIconInTemplates);
        }
        setCallSavedTempTable(false);
        setShowLoading(false);
      });
    }
  }, [appProperties.serviceInstalled, appProperties.subAccount, callSavedTempTable]);
  return (
    <div className="m-3">
      {appProperties?.installedApps?.some((authorizeData) => authorizeData?.right?.auth?.authorized) ? (
        <>
          <div className="d-flex align-items-center justify-content-between  mb-3 pb-1">
            <div className="ms-3">
              <Row className="mt-2">
                <Col className="actionIconsSprite authInfo-icon"></Col>
                <Col className="ms-2">Simplify your messaging with customizable templates for efficient communication.</Col>
              </Row>
            </div>
            <Button className="addTempBtn" type="" onClick={addTemplate} icon={<div className="actionIconsSprite plusIconWhite" />}>
              Add Templates
            </Button>
          </div>

          {isDrawerOpen ? (
            <AddTemplates
              setIsDrawerOpen={setIsDrawerOpen}
              isDrawerOpen={isDrawerOpen}
              appProperties={appProperties}
              setCallSavedTempTable={setCallSavedTempTable}
              setSelectedModule={setSelectedModule}
              selectedModule={selectedModule}
              content={content}
              setContent={setContent}
              friendlyName={friendlyName}
              setFriendlyName={setFriendlyName}
              templateId={templateId}
              tempModal={tempModal}
              disabledSelect={disabledSelect}
              fileList={fileList}
              setFileList={setFileList}
            />
          ) : null}

          {showLoading ? (
            <CommonLoading />
          ) : showTable ? (
            <Table
              scroll={{ x: 1100 }}
              className="savedTempTable ms-3 automationTable"
              pagination={false}
              columns={columns}
              dataSource={savedTemplates}
            />
          ) : (
            <div className="d-flex justify-content-center">
              <Image style={{ width: "10rem", marginTop: "10rem" }} src={EmtpyBoxSVG} preview={false} draggable={false} />
            </div>
          )}
          {isWfDrawerOpen ? (
            <WorkFlow
              templateId={templateId}
              appProperties={appProperties}
              isWfDrawerOpen={isWfDrawerOpen}
              setIsWfDrawerOpen={setIsWfDrawerOpen}
            />
          ) : null}
        </>
      ) : (
        <NoChannelPresent />
      )}
    </div>
  );
}

export default Templates;

const AddTemplates = React.memo((props) => {
  const {
    isDrawerOpen,
    setIsDrawerOpen,
    appProperties,
    setCallSavedTempTable,
    friendlyName,
    setFriendlyName,
    setSelectedModule,
    selectedModule,
    content,
    setContent,
    templateId,
    tempModal,
    disabledSelect,
    fileList,
    setFileList,
  } = props;

  const [module, setModule] = useState([]);

  const [drawerOpen, setDrawerOpen] = useState(isDrawerOpen);

  const { fetchModules, fetchFields, addTemplate, updateTemplate } = useTemplates();
  const [searchFields, setSearchFields] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [fields, setFields] = useState([]);
  const [invalidName, setInvalidName] = useState(false);
  const [invalidContent, setInvalidContent] = useState(false);
  const [invalidField, setInvalidField] = useState(false);
  const [fetchField, setFetchFeild] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [fieldLoading, setFieldLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  console.log("filelist>>", fileList);
  const handleClose = () => {
    if (templateId !== "") {
      setContent({ ...content, [selectedModule]: "" });
    }
    setFileList([]);
    setDrawerOpen(false);
    setIsDrawerOpen(false);
  };
  useEffect(() => {
    if (appProperties?.serviceInstalled) {
      fetchModules(appProperties, null, "templates").then((response) => {
        setModule(response?.data ?? []);
        if (!selectedModule) {
          setSelectedModule(response.data[0]);
        }
        setFetchFeild(true);
      });
    }
  }, []);
  useEffect(() => {
    if (appProperties?.serviceInstalled && selectedModule !== "" && fetchField === true) {
      fetchFields(appProperties, selectedModule).then((response) => {
        if (response) {
          const field = JSON.parse(response.data);

          setFields(field);
          setFieldLoading(false);
        }
      });
    }
  }, [selectedModule, fetchField]);

  const handleChangeModule = (value) => {
    console.log("value>>>>>>", value);
    // setContent(`${content}`);
    setInvalidField(false);
    setSelectedModule(value);
  };

  const handleSearchField = (event) => {
    const field = event.target.value;
    setSearchFields(field);
  };

  const filteredFields = fields?.filter((field) => field.fieldName.toLowerCase().includes(searchFields.toLowerCase()));

  const handleSelectFields = (fieldName) => {
    const selectedFieldObj = fields?.find((field) => field.fieldName === fieldName);
    if (selectedFieldObj) {
      const fields = " {{" + selectedFieldObj.fieldApi + "}} ";

      const beforeCursor = content[selectedModule]?.slice(0, cursorPosition);
      const afterCursor = content[selectedModule]?.slice(cursorPosition);

      const newContent = beforeCursor + fields + afterCursor;

      const newCursorPosition = cursorPosition + fields.length;
      setSelectedField([...selectedField, fieldName]);
      setContent({ ...content, [selectedModule]: newContent });
      setCursorPosition(newCursorPosition);
      setSearchFields("");
    }
  };
  const handleContentChange = (event) => {
    setContent({ ...content, [selectedModule]: event.target.value });
    setCursorPosition(event.target.selectionStart);
  };
  const handleTextAreaClick = (event) => {
    const cursorPosition1 = event.target.selectionStart;
    setCursorPosition(cursorPosition1);
  };
  const handleTemplate = () => {
    const mediaFileList = fileList
      ? fileList.map((file) => {
          return {
            name: file.name ?? "file",
            url: file.url,
            size: file.size ?? -1,
            contentType: file.contentType ?? "dummy/dummy",
            thumbnailUrl: file.thumbnailUrl,
          };
        })
      : [];
    let integId = appProperties.randomIntegId;
    const tempData = {
      content: content[selectedModule],
      friendlyName: friendlyName,
      integId: integId,
      moduleName: selectedModule,
      mediaFileList: mediaFileList,
    };

    if (friendlyName?.trim() === "") {
      setInvalidName(true);
      console.log("asdfghjkl", templateId);
    } else if (!selectedModule) {
      console.log("asdfghjkl", templateId);
      setInvalidField(true);
    } else if ((!content[selectedModule] || content[selectedModule]?.trim() === "") && (!mediaFileList || mediaFileList?.length < 1)) {
      console.log("asdfghjkl", templateId);
      setInvalidContent(true);
    } else if (friendlyName !== "" && (content[selectedModule] !== "" || (mediaFileList && mediaFileList?.length > 0))) {
      console.log("asdfghjkl", templateId);
      if (templateId !== "") {
        setLoading(true);
        updateTemplate(appProperties, tempData, templateId).then((response) => {
          if (response?.success === true) {
            setCallSavedTempTable(true);
            setIsDrawerOpen(false);
            successNotifyWithDescription("Edit Template", "Template updated! Your message is refreshed.");
          }
          setLoading(false);
        });
      } else {
        setLoading(true);
        addTemplate(appProperties, tempData).then((response) => {
          if (response?.success === true) {
            setCallSavedTempTable(true);
            setIsDrawerOpen(false);

            setContent({ ...content, [selectedModule]: "" });
            successNotifyWithDescription("Add Template", "Template added! Personalize client messages with ease.");
            setLoading(false);
          } else if (response?.license?.code === "FEATURE_LIMIT_EXCEEDED") {
            var temLimit = response?.license?.description;
            failureNotificationWithLink(temLimit, "mail to", `mailto:${ASSIST_MAIL}`);
            setLoading(false);
          }
        });
      }
    }
    console.log("asdfghjkl", templateId);
  };

  return (
    <>
      <Drawer
        className="templateDrawer d-flex flex-column"
        title={tempModal === "AddTemp" ? "Add Template" : "Edit Template"}
        open={drawerOpen}
        onClose={handleClose}
        placement="right"
        closeIcon={<div className="actionIconsSprite modalCloseIcon" />}
        width={"30rem"}
        height={"100rem"}
        footer={
          <div className=" templatesAddButton ">
            <Button className="templateAdd" type="" size="large" onClick={handleTemplate} loading={loading}>
              {tempModal === "AddTemp" ? "Add" : "Update"}
            </Button>
          </div>
        }
      >
        <div className="templateModal">
          <Row>
            <Col xs={24} md={13} offset={1} id="friendlyName">
              Friendly name<sup>*</sup>
              <div>
                <Input
                  onFocus={() => {
                    setInvalidName(false);
                  }}
                  type="text"
                  name="name"
                  id="friendlyNameinput"
                  className="hs-bg-offwhite focus-border "
                  bordered={false}
                  onChange={(val) => {
                    setFriendlyName(val.target.value);
                  }}
                  value={friendlyName}
                />
              </div>
              <div className="d-flex">
                <span id="fdnameError" style={{ display: invalidName ? "inherit" : "none" }}>
                  Enter friendlyName
                </span>
                <span
                  id="freindlyNameEg"
                  style={{
                    color: invalidName ? "#ee3248" : "#C2CFE0",
                    marginLeft: invalidName ? "1rem" : "7rem",
                  }}
                >
                  Eg: US Support,Sale
                </span>
              </div>
            </Col>
            <Col xs={24} md={9} offset={1} className="d-flex  align-items-center">
              <div className="mt-3">
                <Select
                  suffixIcon={<div className="actionIconsSprite fromNumberDropDownIcon" />}
                  className="selectModule rounded-5 focus-border rounded hs-bg-offwhite"
                  bordered={false}
                  value={selectedModule ? [selectedModule] : module?.[0]}
                  size="large"
                  style={{ width: "9rem" }}
                  onChange={handleChangeModule}
                  disabled={disabledSelect}
                >
                  {Array.isArray(module) &&
                    module.map((module, index) => {
                      return (
                        <Select.Option key={index} value={module}>
                          {module}
                        </Select.Option>
                      );
                    })}
                </Select>
                <div className="d-flex" style={{ height: 16 }}>
                  <span id="contentError" style={{ display: invalidField ? "inherit" : "none" }}>
                    Template content is required.
                  </span>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col
              xs={22}
              className="d-flex flex-column align-items-center"
              style={{
                marginTop: "1rem",
                borderRadius: 37,
                border: "1px solid #EFEFEF",
                padding: "2px 10px 10px 10px",
              }}
            >
              <Input
                prefix={<SearchOutlined style={{ color: "#C2CFE0" }} />}
                placeholder="Search fields"
                className="hs-bg-offwhite focus-border mt-2"
                bordered={false}
                style={{
                  borderRadius: 37,
                  backgroundColor: "#F5F8FA",
                  width: "95%",
                  height: "2.5rem",
                  border: "none",
                }}
                value={searchFields}
                onChange={handleSearchField}
              />
              <div className="justify-content-center text-align-center w-100">
                {fieldLoading ? (
                  <div style={{ height: "4rem" }}>
                    <LoadingOutlined
                      style={{
                        fontSize: 24,
                        display: "flex",
                        marginTop: "4rem",
                        justifyContent: "center",
                        color: "#DEDEDE",
                      }}
                      spin
                    />
                  </div>
                ) : (
                  <List
                    grid={{ column: 2 }}
                    dataSource={filteredFields}
                    renderItem={(field, index) => (
                      <div className={`listFields ${index % 2 === 0 ? "evenListFields" : "oddListFeilds"}`}>
                        <List.Item
                          className="d-flex justify-content-center"
                          style={{
                            marginBlockEnd: "0px",
                          }}
                          onClick={() => handleSelectFields(field.fieldName)}
                        >
                          {field?.fieldName?.length > 20 ? field?.fieldName?.slice(0, 20) + "..." : field?.fieldName}
                        </List.Item>
                      </div>
                    )}
                    style={{
                      maxHeight: "11rem",
                      overflowY: "auto",
                      cursor: "pointer",
                      lineHeight: 2.5,
                    }}
                  />
                )}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center m-3 rounded hs-bg-offwhite focus-border">
            <Col xs={24}>
              <Input.TextArea
                className="templatesContent hs-bg-offwhite"
                bordered={null}
                autoFocus
                value={content[selectedModule]}
                type="text"
                onChange={handleContentChange}
                placeholder="Your text goes here "
                onClick={handleTextAreaClick}
                style={{
                  borderRadius: 8,
                  resize: "none",
                }}
                onFocus={() => {
                  setInvalidContent(false);
                }}
              />
              <div className="d-flex align-items-end" style={{ minHeight: "48px" }}>
                <ReusableFilePreview
                  fileList={fileList}
                  setFileList={setFileList}
                  shape={"circle"}
                  direction={"horizontal"}
                  customClasses={"ps-3"}
                />
              </div>
              <div className="ms-2 mb-2">
                <MediaUploadIconWrapper
                  fileList={fileList}
                  setFileList={setFileList}
                  showMMS={true}
                  integId={appProperties?.randomIntegId}
                  skipFilter="true"
                />
              </div>
            </Col>
          </Row>
          <div className="d-flex">
            <span id="contentError" style={{ display: invalidContent ? "inherit" : "none" }}>
              Template content is required.
            </span>
          </div>
        </div>
      </Drawer>
    </>
  );
});

const WorkFlow = React.memo((props) => {
  const { setIsWfDrawerOpen, isWfDrawerOpen, appProperties, templateId } = props;

  const [drawerOpen, setDrawerOpen] = useState(isWfDrawerOpen);
  const [iconState, setIconState] = useState({});
  const [data, setData] = useState([]);
  const [helpLink, setHelpLink] = useState("");
  const { fetchWebhookNotifications } = useWorkFlow();
  console.log(props, "props for workFlow");
  const handleClose = () => {
    setDrawerOpen(false);
    setIsWfDrawerOpen(false);
  };
  useEffect(() => {
    if (appProperties?.serviceInstalled) {
      console.log(props, "props for workFlow");

      fetchWebhookNotifications(appProperties, templateId).then((response) => {
        console.log(response, "workflow response", response?.data?.data ?? []);
        const responseData = response?.data?.data;
        console.log(response, "workflow response", responseData);
        const updatedData = responseData.map((obj) => {
          const borderBox = obj.copyAction
            ? {
                border: "1px solid #d0d0d0",
                height: "2.5rem",
                whiteSpace: "noWrap",
                overflowX: "auto",
                maxWidth: "25rem",
                borderRadius: 8,
              }
            : { fontStyle: "italic" };
          return {
            ...obj,
            name: <div style={{ fontWeight: "600", maxWidth: "13rem" }}>{obj.name}</div>,
            value: <div style={{ ...borderBox, padding: "6px 2px 0px 2px" }}>{obj.value}</div>,
          };
        });
        setData(updatedData);
        const helpLinks = response?.data?.helpDocumentLink;
        setHelpLink(helpLinks);
      });
    }
  }, []);

  const handleWorkFlowData = (record) => {
    navigator.clipboard
      .writeText(record.value.props.children)
      .then(() => {
        setIconState((prevState) => ({
          ...prevState,
          [record.id]: true,
        }));
        setTimeout(() => {
          setIconState((prevState) => ({
            ...prevState,
            [record.id]: false,
          }));
        }, 500);
      })

      .catch((error) => {
        console.error("copy failed ", error);
      });
  };
  const handleHelpLink = () => {
    window.open(helpLink);
  };
  const columns = [
    { dataIndex: "name", key: "name" },
    {
      dataIndex: "value",
      key: "value",
    },
    {
      dataIndex: "copyAction",
      key: "copyAction",
      render: (copyAction, record) => (
        <div className="d-grid">
          {iconState[record.id] ? <div className="copiedWorkFlowData">copied!</div> : null}
          {copyAction ? <div className="tempIcons copyWorkFlowData-icon" onClick={() => handleWorkFlowData(record)}></div> : null}
        </div>
      ),
    },
  ];

  return (
    <>
      <Drawer
        className=" workFlowDrawer"
        title={
          <div className="d-flex align-item-center justify-content-center p-2">
            WorkFlow Setup
            <Button
              style={{
                color: "#109cf1",
                borderColor: "#109cf1",
                marginLeft: ".5rem",
              }}
              icon={<ExportOutlined />}
              ghost
              onClick={handleHelpLink}
            >
              help
            </Button>
          </div>
        }
        open={drawerOpen}
        closeIcon={<div className="actionIconsSprite modalCloseIcon" />}
        onClose={handleClose}
        placement="left"
        width={"50rem"}
        height={"100rem"}
      >
        <div className="d-flex justify-content-center">
          <Table className="workFlowTemp" pagination={false} columns={columns} dataSource={data} />
        </div>
      </Drawer>
    </>
  );
});
