import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Modal, Input, Checkbox } from "antd";
import "../../assets/css/otpModal.css";
import useHttp from "./../../hooks/useHttp";
import { urlParams } from "./../../constants/AppConstants";
import mailPng from "./../../assets/icons/Mail.png";

const { Search } = Input;

export default function OTPModal(props) {
  const open = props.open;

  const [currentStep, setCurrentStep] = useState("1");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const companyId = urlParams.get("companyId");
  const leftServiceId = urlParams.get("leftServiceId");
  const rightServiceId = urlParams.get("rightServiceId");
  const userId = urlParams.get("userId");
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [otpBtnLoading, setOtpBtnLoading] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState(true);
  const [emailMessageContent, setEmailMessageContent] = useState(true);

  useEffect(() => {
    if (open) {
      const emailFromParam = urlParams.get("email");
      if (emailFromParam) {
        setUserEmail(emailFromParam);
      }
      setIsModalOpen(true);
    } else if (open === false) {
      setIsModalOpen(false);
    }
  }, [open]);

  const onChange = (e) => {
    setChecked(e.target.checked);
  };

  const { fetchData } = useHttp();

  const login = (email) => {
    setUserEmail(email);
    setOtpBtnLoading(true);
    fetchData(
      `login?email=${email}&companyId=${companyId}&leftServiceId=${leftServiceId}&rightServiceId=${rightServiceId}&userDetail=${userId}&rememberMe=${checked}`,
      "POST",
      null
    )
      .then(function (response) {
        if (response) {
          const responseData = JSON.parse(response);
          if (responseData?.data?.emailResponse?.isValid) {
            setOtpBtnLoading(false);
            setCurrentStep("2");
          } else {
            setEmailMessageContent(responseData?.data?.emailResponse?.message);
            setDisabled(false);
            setOtpBtnLoading(false);
          }
        }
      })
      .catch((error) => {
        console.log(error?.message);
      });
  };

  return (
    <>
      <Modal id="otp" className="otpModal" maskClosable={false} open={isModalOpen} footer={null} centered={true} closeIcon={false}>
        <div id="modalContainer" style={{ marginTop: "2px", marginBottom: ".5rem" }}>
          <div id="otpModalIconDiv">
            <img src={mailPng} draggable="false" alt="mail.png" />
          </div>

          {currentStep === "1" && (
            <GetEmail
              otpBtnLoading={otpBtnLoading}
              login={login}
              setUserEmail={setUserEmail}
              setDisabled={setDisabled}
              disabled={disabled}
              emailMessageContent={emailMessageContent}
              onChange={onChange}
            />
          )}
          {currentStep === "2" && (
            <GetOTP
              userEmail={userEmail}
              checked={checked}
              setOtpErrorMessage={setOtpErrorMessage}
              otpErrorMessage={otpErrorMessage}
              login={login}
              setOtpModalState={props.setOtpModalState}
            />
          )}
        </div>
      </Modal>
    </>
  );
}

const GetEmail = (props) => {
  const { login, setDisabled, disabled, emailMessageContent, onChange, otpBtnLoading } = props;
  const [email, setEmail] = useState(urlParams.get("email"));
  return (
    <div id="otpModal">
      <p className="commonStyleHeading"> Email Verification </p>
      <p className="commonStyleMessage" style={{ marginBottom: "2rem" }}>
        {" "}
        Please enter your registered email address. An OTP will be sent to verify your account.{" "}
      </p>
      <div id="otpEmailInput" className="shadow-sm">
        <Search
          loading={otpBtnLoading}
          placeholder="Enter your email"
          allowClear={false}
          enterButton="Get OTP"
          size="large"
          onSearch={() => {
            login(email);
          }}
          onChange={(e) => {
            setEmail(e.target.value);
            setDisabled(true);
          }}
          bordered={false}
          autoSize={true}
          value={email}
        />
        <div className="d-flex" style={{ paddingLeft: "12px", paddingTop: "6px" }}>
          <p style={{ color: "red" }} hidden={disabled}>
            {emailMessageContent}
          </p>
        </div>
      </div>
      <div className="d-flex align-items-end flex-column bd-highlight mb-3" style={{ paddingRight: "10px", fontSize: "large" }}>
        <p
          style={{
            marginBottom: "20px",
          }}
        >
          <Checkbox onChange={onChange} className="d-none">
            Remember Me
          </Checkbox>
        </p>
      </div>
    </div>
  );
};

const GetOTP = (props) => {
  var length = 4;

  const { userEmail, setOtpErrorMessage, otpErrorMessage, checked, login, setOtpModalState } = props;
  const [otp, setOtp] = useState(Array(length).fill(""));
  const [otpMessageContent, setOtpMessageContent] = useState(true);

  const companyId = urlParams.get("companyId");
  const leftServiceId = urlParams.get("leftServiceId");
  const rightServiceId = urlParams.get("rightServiceId");
  const userId = urlParams.get("userId");

  const { fetchData } = useHttp();

  const inputRefs = useRef([]);

  const handleInputChange = (value, index) => {
    const newOtp = otp ? [...otp] : [];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value !== "" && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleBackspace = (index) => {
    if (index > 0 && otp[index] === "") {
      inputRefs.current[index - 1].focus();
      inputRefs.current[index - 1].select();
    }

    const newOtp = otp ? [...otp] : [];
    newOtp[index] = "";
    setOtp(newOtp);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").trim().replace(/\s/g, "").slice(0, length);

    const newOtp = Array(length).fill("");
    for (let i = 0; i < pastedData.length; i++) {
      newOtp[i] = pastedData[i];
    }
    inputRefs.current[3].focus();

    setOtp(newOtp);
  };

  const validateOtp = useCallback(
    (otpValues) => {
      fetchData(
        `validate?otp=${otpValues}&companyId=${companyId}&leftServiceId=${leftServiceId}&rightServiceId=${rightServiceId}&userDetail=${userId}&rememberMe=${checked}&email=${userEmail}`,
        "POST",
        null,
        null
      )
        .then(function (response) {
          if (response) {
            const responseData = JSON.parse(response);
            if (responseData?.data?.otpResponse?.isValid) {
              setOtpModalState(false);
              window.location.reload();
            } else {
              setOtpMessageContent(responseData?.data?.otpResponse?.message);
              setOtpErrorMessage(false);
            }
          }
        })
        .catch((error) => {
          console.log(error?.message);
        });
    },
    [fetchData, checked, companyId, leftServiceId, rightServiceId, setOtpErrorMessage, setOtpModalState, userEmail, userId]
  );

  useEffect(() => {
    const otpString = otp.join("");

    if (otpString.length === 4) {
      validateOtp(otpString);
    }
  }, [otp, validateOtp]);
  return (
    <div id="otpModal">
      <p className="commonStyleHeading"> Email Verification </p>
      <p>
        {" "}
        <span className="commonStyleMessage">Enter the OTP sent to</span> <br />{" "}
        <span style={{ fontSize: "small", fontWeight: "bolder", opacity: "revert" }}>{userEmail}</span>{" "}
      </p>
      <div>
        {otp.map((value, index) => (
          <input
            key={index}
            id="otpInput"
            type="text"
            maxLength="1"
            style={{
              borderBottom: value ? "2px solid #605BFF" : "2px solid rgb(212, 212, 212)",
            }}
            value={value}
            onChange={(e) => {
              handleInputChange(e.target.value, index);
              setOtpErrorMessage(true);
            }}
            onPaste={handlePaste}
            onKeyUp={(e) => {
              if (e.key === "Backspace") {
                handleBackspace(index);
              }
            }}
            ref={(el) => (inputRefs.current[index] = el)}
          />
        ))}

        <div
          className="d-flex"
          style={{
            paddingLeft: "5rem",
            fontSize: "small",
            marginBottom: "-2.5rem",
            height: "32px",
          }}
        >
          <p style={{ color: "red" }} hidden={otpErrorMessage}>
            {otpMessageContent}
          </p>
        </div>
      </div>
      <p
        style={{
          fontSize: "small",
          opacity: "50%",
          marginBottom: "0rem",
          marginTop: "2rem",
        }}
      >
        {" "}
        Didn’t you receive the OTP?{" "}
      </p>
      <Button
        type="link"
        style={{ marginTop: "0rem" }}
        onClick={() => {
          login(userEmail);
        }}
      >
        {" "}
        Resend OTP
      </Button>
    </div>
  );
};
