import {} from "@testing-library/react";
import React, { createContext, useEffect, useMemo, useState } from "react";

import { PipeDriveSMS } from "./../controller/PipedriveController.js";
import { DefaultOmessage } from "./../controller/DefaultOmessageController.js";
import { ZohoSupportDesk } from "./../controller/ZohodeskController.js";
import { ParentController } from "./../controller/ParentController.js";

import { Hubspot } from "./../controller/HubspotController";

import { useScript } from "./../hooks/Hooks";
import useHttp from "../hooks/useHttp.jsx";
import useServices from "../hooks/useServices";
import { APP_REACT_URL } from "../constants/AppConstants.js";
import { ProviderCommonObj } from "../constants/ProviderConstants.js";

export const AppContext = createContext();
const parentControllerObj = Object.assign({}, ParentController);

export const ProviderObj = (props) => {
  const { fetchInstalledServices, getAllTextSMSServices } = useServices();
  const setLoading = props.setLoading;
  const setOtpModalState = props.setOtpModalState;
  const setPermissionDeniedToSubAccount = props.setPermissionDeniedToSubAccount;
  const query = new URLSearchParams(window.location.search);
  const [appProperties, setAppProperties] = useState({});
  const [scriptUrl, setScriptUrl] = useState("");
  const appPropertie = useMemo(() => [appProperties, setAppProperties], [appProperties, setAppProperties]);

  let serviceFromUrl = query.get("service");

  const serviceName = serviceFromUrl != null ? serviceFromUrl : "default";

  const { fetchData } = useHttp();

  const [serviceObject, setServiceObject] = useState({});
  let appProps = {
    controller: serviceObject,
    setOtpModalState: setOtpModalState,
    setPermissionDeniedToSubAccount: setPermissionDeniedToSubAccount,
  };

  const chargeBeeScript = useScript("https://js.chargebee.com/v2/chargebee.js");

  if (chargeBeeScript === "ready") {
    var siteAttribute = "oapps";
    var locationHref = window.location.origin;
    if (
      locationHref.indexOf("jupiter.rekzyl.com") !== -1 ||
      locationHref.indexOf("localhost") !== -1 ||
      locationHref.indexOf("client3.rekzyl.com") !== -1 ||
      locationHref.indexOf("client4.rekzyl.com") !== -1 ||
      locationHref.indexOf("client5.rekzyl.com") !== -1 ||
      locationHref.indexOf("pre-omessage.oapps.xyz") !== -1
    ) {
      siteAttribute = "oapps-test";
    }
    window.Chargebee.init({ site: siteAttribute });
  }

  var status = "";
  status = useScript(scriptUrl);
  if (status === "ready") {
    console.log(status, "status >>>>>>>>>>>>>>>>>>>>>", scriptUrl);
  }

  useEffect(() => {
    if (serviceName !== null && serviceName !== undefined) {
      let serviceObj = {};
      console.log("serviceName sss >>>>>>>>>>>>>>>>>>>>>", serviceName);
      if (serviceName === "pdOmni") {
        serviceObj = Object.assign(parentControllerObj, PipeDriveSMS);
      } else if (serviceName === "zohodesk") {
        serviceObj = Object.assign(parentControllerObj, ZohoSupportDesk);
      } else if (serviceName === "default") {
        serviceObj = Object.assign(parentControllerObj, DefaultOmessage);
      } else if (serviceName === "hubspotcrm") {
        serviceObj = Object.assign(parentControllerObj, Hubspot);
      }

      serviceObj.loadScripts().then((response) => {
        setScriptUrl(response);
        console.log(response, "embedded response");
      });
      setServiceObject(serviceObj);
      appProps = {
        controller: serviceObj,
      };
    }
  }, [serviceName, status]);

  useEffect(() => {
    var urlParams = new URLSearchParams(window.location.search);
    console.log("useEffect  INIT CALLED >>>>>>>>>>>>>> called", serviceObject);

    if (!isEmpty(serviceObject)) {
      console.log("useEffect  INIT CALLED INSIDE>>>>>>>>>>>>>> called", serviceObject);

      serviceObject.init().then(function () {
        serviceObject.checkIsLicensed(fetchInstalledServices, appProps).then((response) => {
          const responses = response?.data;
          if (responses) {
            console.log("responseInteg", responses);
            appProps.isParentOsyncAccount = false;
            let assignerObj1 = Object.assign(ProviderCommonObj);
            appProps.providerCommonObj = assignerObj1;
            const licenseObject = responses?.license;
            appProps.licenseObj = licenseObject;
            appProps.setLoading = setLoading;
            const account = responses?.accountDetails;
            if (account) {
              appProps.accountStatus = account?.accountStatus;
              appProps.osyncId = account?.osyncId;
              appProps.hash = account?.hash;
              appProps.isParentOsyncAccount = account?.isParentHelloSendAccount === 1;
              appProps.agencyAccount = account?.agencyAccount === 1;
              appProps.extensionInstalledUserEmailId = account?.email;
            }

            appProps.hasPermissionToAccessMainAccount = responses?.hasPermissionToAccessMainAccount;
            appProps.currentUserInstalledTheExtension = responses?.currentUserInstalledTheExtension;
            console.log("responseInteg ddd appProps.isParentOsyncAccount", appProps.isParentOsyncAccount);

            if (responses?.subAccounts) {
              appProps.subAccountsList = responses?.subAccounts;
            }

            console.log("responseInteg ddd account?.hasPermissionToAccessMainAccount ", appProps?.hasPermissionToAccessMainAccount);
            console.log("responseInteg ddd account?.osyncId ", appProps?.osyncId);
            console.log("responseInteg ddd account?.urlParams.has(osyncId) ", urlParams.has("osyncId"));
            if (urlParams.has("osyncId") && urlParams.get("osyncId") === "" && !appProps?.hasPermissionToAccessMainAccount) {
              setPermissionDeniedToSubAccount(true);
              return;
            }
            if (window.self !== window.top && localStorage.getItem("recent-accessed-sub-account") && !urlParams.has("osyncId")) {
              console.log("responseInteg ddd inside 2222 account?.urlParams.has(osyncId) ", urlParams.has("osyncId"));

              reloadWindow(urlParams, localStorage.getItem("recent-accessed-sub-account"));
              return;

              // To reflect the updated URL parameters in the browser's address bar
            }

            if (!appProps?.isParentOsyncAccount && appProps?.osyncId && !urlParams.has("osyncId")) {
              console.log("responseInteg ddd inside 111 account?.urlParams.has(osyncId) ", urlParams.has("osyncId"));
              reloadWindow(urlParams, appProps?.osyncId);
              return;
            }

            let integDetails = responses?.integrationDetails;
            if (integDetails) {
              appProps.settingsLink = integDetails?.external_link?.url;
              appProps.settingsReactLink = integDetails?.external_react_link?.url;

              appProps.phoneList = true;

              appProps.userLogo = integDetails?.userInfo?.userLogo;
              appProps.userId = integDetails?.userInfo?.userId ?? urlParams.get("userId");
              appProps.userName = integDetails?.userInfo?.userName;
              appProps.companyId = integDetails?.userInfo?.companyId;
              appProps.currentUserEmail = integDetails?.userInfo?.userEmail;

              appProps.fetchData = fetchData;
              appProps.emailId = integDetails?.email;
              appProps.domain = integDetails?.domain;
              appProps.isOmniAccount = integDetails?.omniAccount;
              appProps.phone = integDetails?.phone;
              appProps.leftServiceName = integDetails?.leftServiceName;
              appProps.leftServiceDisplayName = integDetails?.leftServiceDisplayName;
              appProps.leftServiceId = integDetails?.leftServiceId;
              if (integDetails?.email) {
                let email = integDetails?.email; // Changed const to let
                email = decodeURIComponent(email);

                window.pagesense = window.pagesense || [];
                window.pagesense.push(["identifyUser", email]);
                console.log(":::::::: emailid-successfully-set-to-pagesense 22222 ::::::::::::", integDetails?.emailId);
              }
              console.log(
                "::::::::hasAccessToParentAccount licenseObject?.allLicensedUserDetails 2 ::::::::::::",
                licenseObject?.allLicensedUserDetails
              );
              let hasAccessToCurrentSubAccount = false;
              if (licenseObject?.allLicensedUserDetails) {
                let userLicense = licenseObject?.allLicensedUserDetails.filter((item) => item.remoteUserId === appProps.userId);
                if (userLicense && userLicense.length > 0) {
                  hasAccessToCurrentSubAccount = true;
                }
              }

              if (!hasAccessToCurrentSubAccount) {
                setPermissionDeniedToSubAccount(true);
                return;
              }

              var templateLink = APP_REACT_URL + "/templates?" + query.toString();
              console.log("templateLink @@@@@@", templateLink);
              appProps.templateLink = templateLink;
            }

            const responseInteg = responses?.integrations?.filter((item) => item?.integProps);
            console.log("responseInteg responseInteg>>>>>>>>>>> ", responseInteg);

            console.log("responseInteg integProps>>>>>>>>>>> ", integDetails);
            if (responseInteg) {
              let installedServicesResp = responses;
              let existingIntegrations = installedServicesResp?.integrations.filter((obj) => obj?.integProps);
              console.log("responseInteg integProps existingIntegrations>>>>>>>>>>> ", existingIntegrations);
              if (existingIntegrations?.length > 0) {
                appProps.serviceInstalled = true;
                appProps.installedApps = existingIntegrations;
                let randomIntegId = existingIntegrations.filter((obj) => obj?.right?.service_name !== "hello_send")?.[0]?.integProps?.integId;
                if (!randomIntegId) {
                  randomIntegId = existingIntegrations?.[0]?.integProps?.integId;
                }
                appProps.randomIntegId = randomIntegId;
              } else {
                let leftServiceName = installedServicesResp?.left?.service_display_name;

                console.log("responseInteg integProps leftServiceName>>>>>>>>>>> ", leftServiceName);
                let leftService = leftServiceName?.charAt(0)?.toUpperCase() + leftServiceName?.slice(1)?.toLowerCase();

                console.log("responseInteg integProps leftService>>>>>>>>>>> ", leftService);
                appProps.authTitle = "Service not Authorized";
                appProps.authContent = "Restore SMS channel access by authorizing " + leftService + ".";
                appProps.authButton = "Authorize";
                appProps.authLink = installedServicesResp?.left?.auth?.url;
                appProps.authorizeObj = installedServicesResp;

                console.log("responseInteg integProps authorizeObj after>>>>>>>>>>> ", appProps.authorizeObj);
              }
              if (installedServicesResp) {
                const licenseObject = installedServicesResp?.license;
                appProps.licenseObj = licenseObject;
                appProps.savedNumbers = installedServicesResp?.numbers;
              }
              const responseInteg = existingIntegrations;
              if (responseInteg) {
                for (const authorizeData of responseInteg) {
                  let leftAuth = authorizeData?.left?.auth?.authorized;

                  console.log("responseInteg integProps leftAuth>>>>>>>>>>> ", leftAuth);

                  if (!leftAuth) {
                    let leftServiceName = authorizeData?.left?.service_display_name;
                    let leftService = leftServiceName?.charAt(0)?.toUpperCase() + leftServiceName?.slice(1)?.toLowerCase();
                    appProps.authTitle = "Service not Authorized";
                    appProps.authContent = "Restore SMS channel access by authorizing " + leftService + ".";
                    appProps.authButton = "Authorize";
                    appProps.authLink = authorizeData?.left?.auth?.url;
                    appProps.authorizeObj = authorizeData;
                    break;
                  }
                  const allAuthorizedFalse = responseInteg.every((authorizeData) => !authorizeData?.right?.auth?.authorized);

                  console.log("responseInteg integProps allAuthorizedFalse>>>>>>>>>>> ", allAuthorizedFalse);
                  if (allAuthorizedFalse) {
                    let rightAuth = authorizeData?.right?.auth?.authorized;

                    console.log("responseInteg integProps rightAuth>>>>>>>>>>> ", leftAuth);

                    if (!rightAuth) {
                      appProps.authTitle = "Service not Authorized";
                      appProps.authContent = "Restore SMS channel access by authorizing SMS Channel.";
                      appProps.authButton = "Authorize";
                      appProps.authLink = authorizeData?.right?.auth?.url;
                      appProps.authorizeObj = authorizeData;
                      break;
                    }
                  }
                }
              }
            }
          } else {
            console.log("getting integrations error >>>>>>>>>>");
          }
          console.log("osyncId>>>", appProps.osyncId);
          if (appProps.osyncId) {
            getAllTextSMSServices(appProps)
              .then((res) => {
                let apps = res.data.apps;
                appProps.apps = apps;
                setAppProperties(appProps);
                setLoading(false);
              })
              .catch((error) => {
                // Handle errors here
                console.error("Error:", error);
              });
          }
        });
      });
    }
  }, [isEmpty(serviceObject)]);
  console.log(appProperties, "responseInteg appProperties", status);

  return <AppContext.Provider value={appPropertie}>{props.children}</AppContext.Provider>;
};

function isEmpty(obj) {
  for (var x in obj) {
    return false;
  }
  return true;
}

function reloadWindow(urlParams, osyncId) {
  urlParams.append("osyncId", osyncId);
  var newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + urlParams.toString();
  window.location.href = newUrl;
}
