import { urlParams } from "./../constants/AppConstants";

import AppExtensionsSDK from "@pipedrive/app-extensions-sdk";

function getAllUrlParams() {
  var allUrlParams = new URLSearchParams(window.location.search);
  var resourceObj = {};
  for (var key of allUrlParams.keys()) {
    resourceObj[key] = allUrlParams.get(key);
  }
  var resourceStr = JSON.stringify(resourceObj);
  return encodeURIComponent(resourceStr);
}
export let PipeDriveSMS = {
  service: "Pipedrive",
  isOmniLicense: true,
  productId: "102",
  isInboxEnabled: true,
  isWorkflowTableFormat: true,
  isWorkflowtext: "Webhook",
  workFlowIconInTemplates: false,
  api: "api/v2/",
  redirectPath: "/persons/list",

  sideBarExtend: {
    homeSideBar: false,
    serviceSideBar: true,
    inboxSidebar: true,
    conversationSidebar: false,
    sendSmsSidebar: false,
    sendBulkSmsSidebar: false,
    campaignSidebar: true,
    campaignOverviewSidebar: true,
    phoneSidebar: false,
    pagesSidebar: false,
    templateSideBar: true,
    workFlowSideBar: true,
    usersSideBar: true,
    automationSideBar: false,
    customModuleTabSideBar: false,
    workflowTabName: "Webhook",
    workflowTabSingularName: "Webhook",
    hideSMSTabsInSideBar: false,
    settingSideBar: true,
  },

  planDetails: {
    topPlan: "Gold",
    topPlanKey: "PD_GOLD",
    topPlanId: "Gold-USD-Monthly",
    totalUsers: 3,
  },
  init: function () {
    return new Promise((resolve, reject) => {
      console.log(" :::::::::::; PIPEDRIVE INIT CALLLED :::::::::: ");
      const id = urlParams.get("id");
      console.log("under init function in pipedrive :::::: id >>>>>>>>>>>>>>>>>", id);

      let a = Date.now();
      try {
        console.log("APP SDK START TIME >>>>>>>>>>", a);
        const sdk = new AppExtensionsSDK({ identifier: id })
          .initialize({
            size: { height: 550, width: 550 },
          })
          .then(() => {
            let b = Date.now();
            let c = b - a;
            console.log("APP SDK END SUCCESSFUL TIME and RESOLVED >>>>>>>>>>", c);
          })
          .catch(() => {
            let b = Date.now();
            let c = b - a;
            console.log("APP SDK END SUCCESSFUL CATCH TIME and REJECTED >>>>>>>>>>", c);
          });
      } catch (error) {
        let b = Date.now();

        let c = b - a;
        console.log("APP SDK END ERROR CATCH TIME and REJECTED>>>>>>>>>>", c);
        console.log(" error happend for pipedrive sms init ", error);
      }
      resolve();
    });
  },
  pricingLink: function (appProperties) {
    return new Promise((resolve, reject) => {
      const { hash, osyncId, domain } = appProperties;
      var leftServiceId = urlParams.get("leftServiceId");
      var allUrlParams = getAllUrlParams();
      console.log("allUrlParams>>>>", allUrlParams);
      var hrefUrl = `/pricing?productId=102&groupName=OMNI_CHANNEL&domain=${domain}&hash=${hash}&leftServiceId=${leftServiceId}&osyncId=${osyncId}&urlParams=${allUrlParams}`;

      console.log("hrefUrl >>>>>>>>>>>>>>>>>>>.", hrefUrl);
      resolve(hrefUrl);
    });
  },
  getWebhookContent: function (fetchData, fetchWebhookNotifications, appProperties) {
    return new Promise((resolve, reject) => {
      let integId = appProperties?.randomIntegId;
      fetchData("omessage/" + integId + "/webhookNotifications", "GET", null, appProperties).then((response) => {
        let webhookNotification = JSON.parse(response);
        console.log(webhookNotification, "useworkflow response");
        resolve(webhookNotification);
      });
    });
  },
  getUserData: function () {
    var companyId = urlParams.get("companyId");
    var emailId = urlParams.get("email");
    var userId = urlParams.get("userId");
    var domain = urlParams.get("domain");
    var companyOrgId = companyId;
    return new Promise((resolve, reject) => {
      var userData = {
        companyId: companyId,
        userId: userId,
        companyOrgId: companyOrgId,
        domain: domain,
        emailId: emailId,
      };
      resolve(userData);
    });
  },
  savePhone: function (thisObj) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  getSavedNumbers: function () {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  getAssociatedObjectId: function (thisObj) {
    return new Promise((resolve, reject) => {
      console.log(urlParams.get("selectedIds"), "associated id");
      resolve(urlParams.get("selectedIds"));
    });
  },
  getAssociatedObjectType: function (thisObj) {
    return new Promise((resolve, reject) => {
      resolve(urlParams.get("resource"));
    });
  },
  getAssociatedIntegId: function (thisObj, rightServiceName) {
    return new Promise((resolve, reject) => {
      resolve(urlParams.get("integId"));
    });
  },
  getAssociatedHash: function (thisObj, rightServiceName) {
    return new Promise((resolve, reject) => {
      resolve(urlParams.get("hash"));
    });
  },

  fetchContact: function (thisObj) {
    return new Promise((resolve, reject) => {
      resolve(urlParams.get("phone").trim());
    });
  },
  getSubscriptionId: function (fetchSubscriptionData, appProperties) {
    return new Promise((resolve, reject) => {
      fetchSubscriptionData(appProperties).then((response) => {
        resolve(response);
      });
    });
  },
  doAfterSuccessfulEnable: function (dataObj) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  associatedModules: function () {
    return new Promise((resolve) => {
      resolve({
        person: ["person", "organization"],
        deal: ["deal", "person", "organization"],
        lead: ["lead", "person", "organization"],
        organization: ["organization"],
      });
    });
  },
};
