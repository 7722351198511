import { useCallback } from "react";
import useHttp from "./useHttp";

export default function useTemplates() {
  const { fetchData } = useHttp();

  const fetchSavedTemplates = (appProperties) => {
    return new Promise((resolve, reject) => {
      let integId = appProperties?.randomIntegId;

      if (appProperties !== undefined) {
        fetchData(`omessage/${integId}/templates`, "GET", null, appProperties).then((response) => {
          let responseData = "";
          if (response) {
            responseData = JSON.parse(response);
          }

          let savedTemplates = responseData?.data;

          resolve(savedTemplates);
        });
      }
    });
  };
  const fetchSavedTemplatesByModule = (appProperties, moduleName) => {
    return new Promise((resolve, reject) => {
      let integId = appProperties.randomIntegId;

      if (appProperties !== undefined) {
        fetchData(`omessage/${integId}/templatesByModule?moduleName=${moduleName}`, "GET", null, appProperties).then((response) => {
          let responseData = "";
          if (response) {
            responseData = JSON.parse(response);
          }
          let savedTemplates = responseData?.data;

          resolve(savedTemplates);
        });
      }
    });
  };
  const fetchModules = useCallback(
    (appProperties, includeFilters, action) => {
      let integId = appProperties.randomIntegId;

      return new Promise((resolve, reject) => {
        if (appProperties !== undefined) {
          let targetUrl = `omessage/${integId}/modules?action=${action}`;
          targetUrl += includeFilters ? `&includeFilters=true` : ``;
          fetchData(targetUrl, "GET", null, appProperties).then((response) => {
            let responseData = "";
            if (response) {
              responseData = JSON.parse(response);
            }
            let modules = responseData?.data;

            resolve(modules);
          });
        }
      });
    },
    [fetchData]
  );
  const fetchFiltersByModule = useCallback(
    (appProperties, module) => {
      let integId = appProperties?.installedApps?.[0]?.integProps?.integId;
      return new Promise((resolve, reject) => {
        if (appProperties !== undefined) {
          fetchData(`${integId}/campaign/filters?moduleName=${module}`, "GET", null, appProperties).then((response) => {
            let responseData = "";
            if (response) {
              responseData = JSON.parse(response);
            }
            let filters = responseData?.data;

            resolve(filters);
          });
        }
      });
    },
    [fetchData]
  );

  const fetchFields = useCallback(
    (appProperties, moduleName) => {
      let integId = appProperties.randomIntegId;
      return new Promise((resolve, reject) => {
        if (appProperties !== undefined) {
          fetchData(`omessage/${integId}/module/${moduleName}/fields`, "GET", null, appProperties).then((response) => {
            let responseData = "";
            if (response) {
              responseData = JSON.parse(response);
            }
            let fields = responseData?.data;

            resolve(fields);
          });
        }
      });
    },
    [fetchData]
  );

  const fetchAllFields = useCallback(
    (appProperties, moduleName) => {
      let integId = appProperties.randomIntegId;
      return new Promise((resolve, reject) => {
        if (appProperties !== undefined) {
          fetchData(`module/fields?integId=${integId}&moduleName=${moduleName}`, "GET", null, appProperties).then((response) => {
            let responseData = "";
            if (response) {
              responseData = JSON.parse(response);
            }
            let fields = responseData?.data;

            resolve(fields);
          });
        }
      });
    },
    [fetchData]
  );

  const addTemplate = (appProperties, body) => {
    return new Promise((resolve, reject) => {
      let integId = appProperties.randomIntegId;

      if (appProperties !== undefined) {
        fetchData(`omessage/${integId}/template`, "POST", body, appProperties).then((response) => {
          let responseData = "";
          if (response) {
            responseData = JSON.parse(response);
          }
          let addedTemp = responseData?.data;

          resolve(addedTemp);
        });
      }
    });
  };

  const updateTemplate = (appProperties, body, templateId) => {
    return new Promise((resolve, reject) => {
      let integId = appProperties.randomIntegId;

      if (appProperties !== undefined) {
        fetchData(`omessage/${integId}/template/${templateId}`, "PUT", body, appProperties).then((response) => {
          let responseData = "";
          if (response) {
            responseData = JSON.parse(response);
          }
          let addedTemp = responseData?.data;

          resolve(addedTemp);
        });
      }
    });
  };
  const deleteTemplate = (appProperties, templateId) => {
    return new Promise((resolve, reject) => {
      let integId = appProperties.randomIntegId;

      if (appProperties !== undefined) {
        fetchData(`omessage/${integId}/template/${templateId}`, "DELETE", null, appProperties).then((response) => {
          let responseData = "";
          if (response) {
            responseData = JSON.parse(response);
          }
          let deleteTemp = responseData?.data;

          resolve(deleteTemp);
        });
      }
    });
  };

  const fetchFilterInfoByFilterId = (appProperties, integId, moduleName, filterId) => {
    return new Promise((resolve, reject) => {
      let targetUrl = `filter/records/count?integId=${integId}&moduleName=${moduleName}&filterId=${filterId}`;
      fetchData(targetUrl, "GET", null, appProperties)
        .then((res) => {
          const responseJson = JSON.parse(res);
          if (responseJson?.data) {
            resolve(responseJson.data);
          } else {
            reject(new Error("Failed to fetch records count"));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return {
    fetchSavedTemplates,
    fetchModules,
    fetchFields,
    addTemplate,
    updateTemplate,
    deleteTemplate,
    fetchSavedTemplatesByModule,
    fetchAllFields,
    fetchFiltersByModule,
    fetchFilterInfoByFilterId,
  };
}
