import useHttp from "./useHttp";
export default function useUsers() {
  const { fetchData } = useHttp();

  const fetchCurrentUsers = (appProperties) => {
    return new Promise((resolve, reject) => {
      const osyncId = appProperties.osyncId;
      const leftServiceId = appProperties.leftServiceId;

      if (appProperties !== undefined) {
        fetchData("omni/license?leftServiceId=" + leftServiceId + "&osyncId=" + osyncId, "GET", null, appProperties).then((response) => {
          if (response) {
            let responseData = JSON.parse(response);
            let savedUsers = responseData.data;
            console.log(responseData, "responseData>>>>>>fetchCurrentUsers");
            resolve(savedUsers);
          }
        });
      }
    });
  };

  const fetchUsers = (appProperties) => {
    return new Promise((resolve, reject) => {
      let integId = appProperties.randomIntegId;
      const leftServiceId = appProperties.leftServiceId;

      console.log("fetchUsers> in integId>>>>", integId);
      console.log("fetchUsers> in leftServiceId>>>>", leftServiceId);

      if (appProperties !== undefined) {
        fetchData("omni/license/agents?leftServiceId=" + leftServiceId + "&integId=" + integId, "GET", null, appProperties).then((response) => {
          if (response) {
            let responseData = JSON.parse(response);
            let savedUsers = responseData.data;
            console.log(responseData, "responseData>>>>>>fetchUsers");
            resolve(savedUsers);
          }
        });
      }
    });
  };

  const addUsers = (appProperties, body) => {
    return new Promise((resolve, reject) => {
      console.log("addUSers>>>>body>>>", body);
      if (appProperties !== undefined) {
        fetchData(`omni/userLicense/add`, "POST", body, appProperties).then((response) => {
          console.log("response>>>>>", response);
          if (response) {
            let responseData = JSON.parse(response);
            console.log(responseData, "responseData");
            resolve(responseData);
          } else {
            resolve(false);
          }
        });
      }
    });
  };

  const deleteUser = (appProperties, body) => {
    return new Promise((resolve, reject) => {
      console.log(body, "userId>>>>");
      if (appProperties !== undefined) {
        fetchData(`omni/userLicense/delete`, "DELETE", body, appProperties).then((response) => {
          if (response) {
            let responseData = JSON.parse(response);
            resolve(responseData);
          }
        });
      }
    });
  };

  return {
    fetchUsers,
    addUsers,
    deleteUser,
    fetchCurrentUsers,
  };
}
