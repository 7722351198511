import { Col, Row, Grid, Tooltip, Typography, Badge } from "antd";

export function CampaignOverallMetric(props) {
  const { data, icon, message, comingSoon } = props;
  return (
    <>
      {comingSoon ? (
        <Badge.Ribbon rootClassName="w-100 me-2 metricContainer" className="hs-bg-violet" text={<div className="hs-fs-12">Coming Soon</div>}>
          <MetricContent data={data} icon={icon} message={message} />
        </Badge.Ribbon>
      ) : (
        <MetricContent data={data} icon={icon} message={message} />
      )}
    </>
  );
}

const ResponsiveTitle = ({ value }) => {
  const { xl, xxl } = Grid.useBreakpoint();
  const { Title } = Typography;
  const level = xxl ? 3 : xl ? 4 : 5;
  return (
    <Tooltip placement="left" title={value}>
      <Title ellipsis className="pb-0 mb-0 hs-fw-800 hs-fs-22" level={level}>
        {value < 999999 ? `${value}` : `999999+`}
      </Title>
    </Tooltip>
  );
};

const MetricContent = ({ data, icon, message }) => {
  return (
    <Row className="w-100 bg-white hs-border-10 me-2 metricContainer">
      <Row className="w-100 pt-2" justify={"center"} align={"middle"}>
        <Col
          xxl={{
            offset: 2,
            span: 5,
          }}
          xl={{
            offset: 3,
            span: 4,
          }}
          lg={{
            offset: 2,
            span: 3,
          }}
        >
          {icon}
        </Col>
        <Col
          xxl={{
            span: 14,
            offset: 1,
          }}
          xl={{
            span: 13,
            offset: 1,
          }}
          lg={{
            span: 12,
            offset: 5,
          }}
          style={{ marginInlineStart: "2px" }}
        >
          <div className="hs-fs-15">{message}</div>
        </Col>
      </Row>
      <div className="ps-5 ms-5" style={{ marginTop: "-29px" }}>
        <ResponsiveTitle value={data} />
      </div>
    </Row>
  );
};
