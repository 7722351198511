import React, { useState, useMemo } from "react";
import { Select, Input, Typography } from "antd";
const { Option } = Select;

const CustomSearchDropdownSelect = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { options, customProps, listEndComponent } = props;
  const { Text } = Typography;

  const filteredOptions = useMemo(() => {
    return searchTerm
      ? options.filter((option) =>
          option.label.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : options;
  }, [options, searchTerm]);

  const dropdownRender = (menu) => (
    <div className="px-1">
      <div className="d-flex mt-2 mb-3">
        <Input
          size="small"
          className="rounded"
          prefix={<div className="actionIconsSprite customSelectSearchIcon" />}
          placeholder={"Search"}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="dropDownList">{menu}</div>
      <div>{listEndComponent}</div>
    </div>
  );

  return (
    <>
      <Select
        {...customProps}
        filterOption={false}
        popupClassName="customSelectPopup"
        dropdownRender={dropdownRender}
        onDropdownVisibleChange={(visible) => {
          if (!visible) {
            setSearchTerm("");
          }
        }}
      >
        {filteredOptions?.map((option) => (
          <Option key={option?.value} value={option?.value}>
            <div className="d-flex align-items-center hs-w-90 justify-content-between">
              <Text disabled={customProps?.disabled} ellipsis={true}>
                {option?.label}
              </Text>
              {option?.count && (
                <div className="hs-bg-light-silver px-2 rounded-pill">
                  {option?.count}
                </div>
              )}
            </div>
          </Option>
        ))}
      </Select>
    </>
  );
};

export default CustomSearchDropdownSelect;
