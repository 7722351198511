import { urlParams } from "../constants/AppConstants";
import useHttp from "./useHttp";

export default function useServices() {
  var userId = urlParams.get("userId");
  var companyId = urlParams.get("companyId");

  var leftServiceId = urlParams.get("leftServiceId");
  var rightServiceId = urlParams.get("rightServiceId");

  var selectedIds = urlParams.get("selectedIds");
  var resource = urlParams.get("resource");

  const { fetchData } = useHttp();

  const getAllTextSMSServices = (appProperties) => {
    console.log("getAllTextSMSServices", appProperties);
    return new Promise((resolve, reject) => {
      fetchData("omessage/apps", "GET", null, appProperties)
        .then((data) => {
          let response = "";
          if (data) {
            response = JSON.parse(data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const fetchInstalledServices = (appProperties) => {
    return new Promise((resolve, reject) => {
      fetchData(
        `integrations?leftServiceId=${leftServiceId}&rightServiceId=${rightServiceId}&selectedIds=${selectedIds}&resource=${resource}&companyId=${companyId}&userId=${userId}`,
        "GET",
        null,
        appProperties
      )
        .then((response) => {
          let responseData = "";
          if (response) {
            responseData = JSON.parse(response);
          }
          resolve(responseData);
        })
        .catch((error) => {
          console.log("fetchInstalledServices >>>>>>>>>>>>>>>>", error);
        });
    });
  };

  return {
    getAllTextSMSServices,
    fetchInstalledServices,
  };
}
