import { checkGivenDateIsAfterCurrentDate, getMomentDate } from "../../utils/dateUtils";

export const campaignStatus = {
  1: "SENDING",
  2: "SCHEDULED",
  30: "SENT",
  10: "THROTTLE_LIMIT_REACHED",
  31: "STOPPED",
  35: "PAUSED",
  51: "RECORD_LIMIT_EXCEEDED",
  55: "FORCE_STOPPED",
};

const MAXIMUM_SUPPORTED_RECORDS_FOR_CAMPAIGN_COUNT = 5000;
export const MAXIMUM_SUPPORTED_RECORDS_FOR_CAMPAIGN = {
  count: MAXIMUM_SUPPORTED_RECORDS_FOR_CAMPAIGN_COUNT,
  message: (
    <div className="mt-1">
      {`Supports upto ${MAXIMUM_SUPPORTED_RECORDS_FOR_CAMPAIGN_COUNT} records.
      For more, email us at assist@oapps.xyz`}
    </div>
  ),
};

export const campaignStatusNameMap = {
  sending: "In Progress",
  sent: "Completed",
  scheduled: "Scheduled",
  paused: "Paused",
  failed: "Failed",
  pending: "Sent",
  force_stopped: "Not sent",
  record_limit_exceeded: "Not sent",
};

export function getStatusTextFromTimeInMillis(targetDate, statusText) {
  if (
    statusText === "failed" ||
    statusText === "paused" ||
    statusText === "force_stop" ||
    statusText === "record_limit_exceeded" ||
    !targetDate ||
    !checkGivenDateIsAfterCurrentDate(targetDate)
  ) {
    return campaignStatusNameMap[statusText];
  }

  const formattedTime = getMomentDate(targetDate).fromNow(true);
  return `Sending in ${formattedTime}`;
}

export const getClassNameForCampaignStatus = (statusText) => {
  let statusClassName = null;
  switch (statusText?.toLowerCase()) {
    case "sending":
    case "scheduled":
      statusClassName = "sendingOrScheduledStatus";
      break;
    case "sent":
      statusClassName = "sentStatus";
      break;
    case "pending":
      statusClassName = "pendingStatus";
      break;
    case "stopped":
      statusClassName = "stoppedStatus";
      break;
    case "paused":
      statusClassName = "pausedStatus";
      break;
    case "force_stop":
      statusClassName = "forceStoppedStatus";
      break;
    case "record_limit_exceeded":
      statusClassName = "forceStoppedStatus";
      break;
    default:
      statusClassName = "";
      break;
  }
  return statusClassName;
};

export const getClassNameForCampaignType = (type) => {
  if (type === "IMMEDIATE") {
    return "immediateBadge";
  } else {
    return "scheduledBadge";
  }
};

export const getCampaignStatusFromStatusCode = (code) => {
  return campaignStatus[code]?.toLowerCase();
};
