import React, { useContext, useEffect, useRef, useState } from "react";
import ConversationView from "./ConversationPage";
import { AppContext } from "../context/AppContext";
import useHttp from "../hooks/useHttp";
import { initPusher } from "./utils/CommonVessels";

export const ConvContext = React.createContext();

const ConvProvider = ({ children, setLoaded, chatView }) => {
  const [appProperties] = useContext(AppContext);
  const channelRef = useRef();
  const pusherRef = useRef();
  const selectedContactId = useRef();
  const { fetchData } = useHttp();

  useEffect(() => {
    if (!chatView) {
      initPusher(fetchData, appProperties).then(function (pusherObj) {
        pusherRef.current = pusherObj;
        channelRef.current = pusherObj?.channel;
        setLoaded(true);
      });

      return () => {
        pusherRef.current.dispose();
      };
    } else {
      setLoaded(true);
    }
  }, []);

  const sendsms = (integId, body) => {
    return new Promise((resolve, reject) => {
      fetchData(`omessage/${integId}/send`, "POST", body, appProperties)
        .then(function (response) {
          try {
            if (response) {
              resolve(response);
            } else {
              reject(new Error("Empty response"));
            }
          } catch (error) {
            console.error("Error processing response:", error);
            reject(error);
          }
        })
        .catch((error) => {
          console.log("ERROR   SEND  SMS", error);
          reject(error);
        });
    });
  };

  return (
    <ConvContext.Provider
      value={{
        channel: channelRef.current,
        selectedContactId,
        sendsms,
      }}
    >
      {children}
    </ConvContext.Provider>
  );
};

const ConversationWrapper = (props) => {
  const { chatView, toNumber, setHelloSend } = props;
  const [loaded, setLoaded] = useState(false);
  return (
    <ConvProvider setLoaded={setLoaded} chatView={chatView}>
      {loaded && <ConversationView chatView={chatView} toNumber={toNumber} setHelloSend={setHelloSend} />}
    </ConvProvider>
  );
};

export default ConversationWrapper;
