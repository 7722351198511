import { Alert, Button, Checkbox, Col, DatePicker, Divider, Form, Input, Modal, Row, Space, Spin, TimePicker, Upload } from "antd";
import React, { useCallback, useRef, useState } from "react";

import dayjs from "dayjs";
import { useEffect } from "react";
import CustomSearchDropDownSelect from "../../../../components/custom/CustomSearchDropDownSelect";
import ReusableFilePreview from "../../../../components/custom/ReusableFilePreview";
import ReusableFileUploadModal from "../../../../components/custom/ReusableFileUploadModal";
import useCampaign from "../../../../hooks/useCampaign";
import useTemplates from "../../../../hooks/useTemplates";
import SavedTemplatesSelect from "../../../outbound/SavedTemplatesSelect";
import { failureNotification, successNotification } from "../../../utils/CommonNotifications";
import { FormInput, FromNumberComponent } from "../../../utils/CommonVessels";

import { checkGivenDateIsAfterCurrentDate, getIsoFormattedDateAndTime } from "../../../utils/dateUtils";

import useAttachment from "../../../../hooks/useAttachment";
import {
  checkWhetherServiceIsAuthorized,
  getRandomNumber,
  getServiceObjectFromIntegId,
  mapToMediaList,
  openServiceNotAuthorizedModal,
} from "../../../utils/commonUtils";
import { MAXIMUM_SUPPORTED_RECORDS_FOR_CAMPAIGN } from "../../utils/campaignUtils";
import CampaignActionArea from "./CampaignActionArea";

const MINUTES_TO_DISABLE = 15;
const MONTHS_TO_DISABLE = 1;

function CampaignContent({ appProperties, initialValues, setInitialValues, setAppProperties }) {
  const [fileList, setFileList] = useState(initialValues?.mediaFileList ?? []);
  const [message, setMessage] = useState(initialValues?.message ?? "");
  const [isTestModalOpen, setIsTestModalOpen] = useState(false);
  const [templateId, setTemplateId] = useState();
  const [campaignForm] = Form.useForm();

  return (
    <>
      <Form
        form={campaignForm}
        disabled={initialValues?.disabled}
        initialValues={{
          date: dayjs(),
          time: dayjs().add(MINUTES_TO_DISABLE, "minutes"),
          ...initialValues,
        }}
      >
        <Row className="p-3">
          <Col className="gutter-row me-4 rounded bg-white" span={13}>
            <SendCampaignForm
              setInitialValues={setInitialValues}
              initialValues={initialValues}
              templateId={templateId}
              setTemplateId={setTemplateId}
              campaignForm={campaignForm}
              isTestModalOpen={isTestModalOpen}
              setIsTestModalOpen={setIsTestModalOpen}
              message={message}
              setMessage={setMessage}
              fileList={fileList}
              setFileList={setFileList}
              appProperties={appProperties}
              setAppProperties={setAppProperties}
            />
          </Col>
          <Col className="gutter-row rounded bg-white campaignCol" span={9}>
            <div className="row w-100 mx-auto p-3">
              <PreviewHeader message={message} setIsTestModalOpen={setIsTestModalOpen} appProperties={appProperties} />
              <div className="overflow-auto bg-white px-3" style={{ maxHeight: "70dvh" }}>
                <div className="d-flex flex-column gap-3">
                  {message && (
                    <div className="w-100 hs-bg-v-light-grey h-auto text-wrap rounded p-4" style={{ wordBreak: "break-word" }}>
                      <pre className="previewCls">{message}</pre>
                    </div>
                  )}
                  <ReusableFilePreview
                    customClasses="bg-white"
                    shape={"card"}
                    direction={"vertical"}
                    setFileList={setFileList}
                    fileList={fileList}
                    initialValues={initialValues}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}

function PreviewHeader(props) {
  const { setIsTestModalOpen, message } = props;
  return (
    <>
      <div className="w-100 m-2 my-3">
        <div className="d-flex justify-content-between align-items-center">
          <span style={{ minHeight: "33px" }}>Preview</span>
          {message && (
            <Button style={{ borderColor: "#605bff", color: "#605bff" }} onClick={() => setIsTestModalOpen(true)}>
              Send Test
            </Button>
          )}
        </div>
      </div>
      <Divider />
    </>
  );
}

function SendTestModal(props) {
  const { isTestModalOpen, setIsTestModalOpen, loading, handleSendTestMessage } = props;
  return (
    <Modal
      centered
      footer={null}
      title={
        <div className="d-flex align-items-center justify-content-between">
          <p className="mb-0">Send test message</p>
          <div onClick={() => setIsTestModalOpen(false)} className="actionIconsSprite testModalCloseIcon" />
        </div>
      }
      className="testModal"
      open={isTestModalOpen}
      onCancel={() => setIsTestModalOpen(false)}
      closeIcon={null}
    >
      <div className="w-100 mt-3 px-1">
        <Form.Item colon={false} labelCol={{ span: 24 }} label="Send test SMS to" name="testTo">
          <Input size="large" className="bgLightWhite hs-focus-border w-100 rounded" />
        </Form.Item>
        <div className="d-flex w-100 justify-content-end">
          <Button
            size="large"
            style={{ borderRadius: "4px" }}
            className="hs-bg-violet d-flex align-items-center px-4 text-white"
            onClick={handleSendTestMessage}
            loading={loading}
          >
            <span className="hs-fs-12"> Send test</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function SendCampaignForm(props) {
  const {
    appProperties,
    fileList,
    setIsTestModalOpen,
    isTestModalOpen,
    setFileList,
    templateId,
    setTemplateId,
    message,
    setMessage,
    initialValues,
    setInitialValues,
    campaignForm,
    setAppProperties,
  } = props;
  const { sendCampaignMessage, loading, sendTestMessage } = useCampaign(appProperties);

  const [fromNumber, setFromNumber] = useState(initialValues?.fromNumber ?? "");
  const [integId, setIntegId] = useState("");
  const [savedNumbersList, setSavedNumbersList] = useState([]);
  const [csvData, setCsvData] = useState();
  const [module, setModule] = useState(initialValues?.moduleName);
  const [filter, setFilter] = useState(initialValues?.filterId);
  console.log("initialvalues....", initialValues);
  const [moduleOptions, setModuleOptions] = useState(initialValues?.moduleOptions);
  const [filterOptions, setFilterOptions] = useState(initialValues?.filterOptions);
  const [showNotVisibleFilterBanner, setShowNotVisibleFilterBanner] = useState(false);

  const [isInvalidFilter, setIsInvalidFilter] = useState(false);

  const [testLoading, setTestLoading] = useState(false);
  const [confirmActionModalOpen, setConfirmActionModalOpen] = useState(false);

  useEffect(() => {
    const integId = savedNumbersList.find((item) => item.phoneNumber === fromNumber)?.integId;
    setIntegId(integId);
  }, [fromNumber, savedNumbersList]);

  const sendCampaign = useCallback(
    async (isUpdate = false) => {
      try {
        let serviceObject = getServiceObjectFromIntegId(appProperties, integId);
        if (!checkWhetherServiceIsAuthorized(appProperties, integId, serviceObject, false)) {
          openServiceNotAuthorizedModal(serviceObject?.right);
          return;
        }
        const formFields = await campaignForm.validateFields();
        if (!message) {
          failureNotification(null, "Message body should not be empty");
        } else if (!fromNumber) {
          failureNotification(null, "From number cannot be empty");
        } else if (!formFields.campaignName) {
          failureNotification(null, "Campaign name should not be empty");
        } else if (!csvData?.file && !filter) {
          setIsInvalidFilter(true);
        } else {
          const mediaFileList =
            fileList?.length > 0
              ? fileList.map((file) => {
                  return {
                    name: file.name,
                    url: file.url,
                    contentType: file?.contentType,
                    size: file?.size,
                  };
                })
              : null;
          const csvFile = csvData?.file;
          let payload = {
            campaignId: initialValues?.campaignId,
            messageScheduled: false,
            moduleName: csvFile ? null : module,
            filterId: csvFile ? null : filter,
            filterName: csvFile ? csvFile.name : filterOptions.find((item) => item?.filterId === filter)?.filterName,
            csvFileId: initialValues?.csvFileId,
            from: fromNumber,
            mediaFileList: mediaFileList,
            campaignName: formFields.campaignName,
            messageBody: message,
            templateId: templateId,
          };
          if (formFields.date && formFields.time) {
            const scheduledTime = getIsoFormattedDateAndTime(formFields.date, formFields.time);
            payload = {
              ...payload,
              scheduledTime: scheduledTime,
              messageScheduled: true,
            };
          }
          const formData = new FormData();
          formData.append("data", JSON.stringify(payload));

          if (csvFile) {
            formData.append("file", csvFile);
          }
          if (isUpdate) {
            formData.append("action", "start");
          }
          const response = await sendCampaignMessage(formData, integId, isUpdate);
          return response;
        }
      } catch (e) {}
    },
    [
      campaignForm,
      csvData?.file,
      fileList,
      filter,
      fromNumber,
      initialValues?.campaignId,
      integId,
      message,
      module,
      sendCampaignMessage,
      templateId,
    ]
  );
  const handleSendTestMessage = () => {
    const { testTo } = campaignForm.getFieldsValue();
    setTestLoading(true);
    sendTestMessage({
      payload: {
        from: fromNumber,
        to: testTo,
        messageBody: message,
        templateId: templateId,
        associatedObjectType: module.value,
        mediaFileList: fileList
          ? fileList.map((file) => ({
              name: file.name,
              url: file.url,
            }))
          : [],
      },
      integId: integId,
      onSuccess: () => {
        setIsTestModalOpen(false);
      },
    }).finally(() => {
      setTestLoading(false);
    });
  };

  const handleClearAllFields = () => {
    setMessage("");
    setFileList([]);
    campaignForm.resetFields();
  };

  const handleClearCsvData = () => {
    if (setInitialValues) {
      setInitialValues((prev) => {
        return {
          ...prev,
          csvFileName: null,
          csvFileId: null,
        };
      });
    }
    setCsvData({});
  };

  const showActionArea = () => {
    return (
      initialValues?.campaignStatusName !== "sending" &&
      initialValues?.campaignStatusName !== "stopped" &&
      initialValues?.type === "scheduled" &&
      (checkGivenDateIsAfterCurrentDate(initialValues?.campaign?.scheduledTime) || initialValues?.campaignStatusName === "paused")
    );
  };

  return (
    <>
      <div className="w-100 mt-4">
        {showActionArea() && (
          <CampaignActionArea
            showNotVisibleFilterBanner={showNotVisibleFilterBanner}
            confirmActionModalOpen={confirmActionModalOpen}
            setConfirmActionModalOpen={setConfirmActionModalOpen}
            loading={loading}
            appProperties={appProperties}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            sendCampaign={sendCampaign}
          />
        )}
        <Space size={"large"} direction="vertical" className="campaignContentContainer">
          <SendTestModal
            setIsTestModalOpen={setIsTestModalOpen}
            isTestModalOpen={isTestModalOpen}
            loading={testLoading}
            campaignForm={campaignForm}
            handleSendTestMessage={handleSendTestMessage}
          />
          <CampaignName />
          <div className="d-flex flex-column gap-2">
            <span>From</span>
            <FromNumberComponent
              tabIndex={2}
              disabled={false}
              fromNumber={fromNumber}
              savedNumbersList={savedNumbersList}
              setSavedNumbersList={setSavedNumbersList}
              defaultSelectFirstOption={initialValues?.fromNumber ? false : true}
              handleFromNumberChange={setFromNumber}
              customClasses={"h-50 hs-fs-16 fw-bold hs-focus-border hs-bg-offwhite"}
            />
          </div>

          {csvData?.length > 0 || initialValues?.csvFileName ? (
            <CsvPreview
              initialValues={initialValues}
              handleClearCsvData={handleClearCsvData}
              length={csvData?.length}
              name={csvData?.file?.name ?? initialValues?.csvFileName}
            />
          ) : (
            <RecipientSelect
              initialValues={initialValues}
              csvData={csvData}
              filterOptions={filterOptions}
              moduleOptions={moduleOptions}
              setModuleOptions={setModuleOptions}
              filter={filter}
              module={module}
              setFilterOptions={setFilterOptions}
              setCsvData={setCsvData}
              setFilter={setFilter}
              setModule={setModule}
              appProperties={appProperties}
              setAppProperties={setAppProperties}
              showNotVisibleFilterBanner={showNotVisibleFilterBanner}
              setShowNotVisibleFilterBanner={setShowNotVisibleFilterBanner}
              isInvalidFilter={isInvalidFilter}
              setIsInvalidFilter={setIsInvalidFilter}
              integId={integId}
            />
          )}
          <MessagingArea
            setTemplateId={setTemplateId}
            fileList={fileList}
            setFileList={setFileList}
            setMessage={setMessage}
            message={message}
            module={module}
            integId={integId}
            appProperties={appProperties}
          />

          <ScheduleArea initialValues={initialValues} />
          {initialValues?.source === "analytics" ? (
            <span className="pb-2" />
          ) : (
            <SendMessageArea
              handleClearAllFields={handleClearAllFields}
              loading={loading}
              sendCampaign={sendCampaign}
              isInvalidFilter={isInvalidFilter}
            />
          )}
        </Space>
      </div>
    </>
  );
}

const CsvPreview = (props) => {
  const { length, name, handleClearCsvData, initialValues } = props;
  return (
    <div className="d-flex">
      To : {name}
      {!initialValues?.disabled && (
        <>
          {length && <b className="ms-1">({length})</b>}
          <div className="ms-2 my-auto">
            <div className="actionIconsSprite closeIcon" onClick={handleClearCsvData} />
          </div>
        </>
      )}
    </div>
  );
};

function ScheduleArea({ initialValues }) {
  const [currentCheckedState, setCurrentCheckedState] = useState(initialValues?.type ?? "immediate");
  const [date, setDate] = useState(dayjs());

  const disableFutureDates = (current) => {
    return current && (current < dayjs().startOf("day") || current > dayjs().add(MONTHS_TO_DISABLE, "month"));
  };

  const disabledTime = () => {
    const now = dayjs();
    const next15Minutes = dayjs(now).add(15, "minutes");
    const isSameDate = now.isSame(dayjs(date), "date");
    return {
      disabledHours: () => {
        if (isSameDate) {
          return [...Array(next15Minutes.hour()).keys()];
        }
        return [];
      },
      disabledMinutes: (selectedHour) => {
        if (selectedHour === next15Minutes.hour() && isSameDate) {
          return [...Array(next15Minutes.minute()).keys()];
        }
        return [];
      },
      disabledSeconds: () => [],
    };
  };

  return (
    <div className="d-flex flex-column gap-6">
      <div className="mb-4">
        <b className="mt-2">Send</b>
        <ScheduleOption currentCheckedState={currentCheckedState} setCurrentCheckedState={setCurrentCheckedState} />
      </div>
      {currentCheckedState === "scheduled" && (
        <div className={`d-flex flex-column gap-2 ${currentCheckedState !== "scheduled" ? "hidden" : "visibile"}`}>
          <span className="mb-2">Time & Date</span>
          <Row justify="start" style={{ height: 70 }}>
            <Col span={9} className="hs-bg-offwhite d-flex me-3 rounded">
              <div className="d-flex align-items-center m-2 ps-1">
                <div className="actionIconsSprite calendarIcon" />
                <Form.Item name={"date"} rootClassName="mb-0">
                  <DatePicker
                    className="datePicker"
                    suffixIcon={null}
                    superNextIcon={null}
                    superPrevIcon={null}
                    showSecond={null}
                    bordered={false}
                    popupClassName="datePickerPanel"
                    allowClear={false}
                    value={date}
                    onChange={(e) => setDate(e)}
                    showToday={false}
                    disabledDate={disableFutureDates}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={9} className="hs-bg-offwhite d-flex align-items-center mx-3 rounded">
              <div className="d-flex align-items-center m-2 ps-1">
                <div className="actionIconsSprite clockIcon" />
                <Form.Item name={"time"} rootClassName="mb-0">
                  <TimePicker
                    className="w-100 ps-2"
                    popupClassName="timePickerPopupPanel"
                    disabledTime={disabledTime}
                    suffixIcon={null}
                    showNow={false}
                    style={{ all: "unset" }}
                    allowClear={false}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

function ScheduleOption(props) {
  const { currentCheckedState, setCurrentCheckedState } = props;
  const options = [
    { value: "immediate", label: "Immediate" },
    {
      value: "scheduled",
      label: "Custom Date",
    },
  ];
  return (
    <div className="d-flex mt-2 gap-3">
      {options.map((option) => {
        return (
          <Checkbox
            key={option.value}
            className="schedule-check-box hs-fs-16"
            value={option.value}
            onChange={(e) => {
              setCurrentCheckedState(e.target.value);
            }}
            checked={currentCheckedState === option.value}
          >
            {option.label}
          </Checkbox>
        );
      })}
    </div>
  );
}

function SendMessageArea(props) {
  const { sendCampaign, loading, handleClearAllFields, isInvalidFilter } = props;
  return (
    <div className="d-flex justify-content-end pb-4">
      <div className="d-flex align-items-center gap-4">
        <Button danger className="hs-border-10 med-button px-3" onClick={() => handleClearAllFields()}>
          <span className="px-3">Cancel</span>
        </Button>
        <Button
          className="hs-bg-violet hs-border-10 big-button hs-fs-16 px-3"
          type="primary"
          name="submitButton"
          tabIndex={6}
          onClick={() => sendCampaign()}
          loading={loading}
          disabled={isInvalidFilter}
        >
          <span className="px-3">Launch</span>
        </Button>
      </div>
    </div>
  );
}

function MessagingArea(props) {
  const { TextArea } = Input;
  const { setMessage, message, module, fileList, setFileList, appProperties, setTemplateId, integId } = props;

  const [modalVisible, setModalVisible] = useState(false);

  const handleTextChange = (e) => {
    setMessage(e.target.value);
  };

  function handleMessage(message) {
    console.log("meddage", message);
    setMessage(message);
  }

  function handleMedia(mediaFile) {
    setFileList(mediaFile ? mapToMediaList(mediaFile, false) : []);
  }

  const [messageAreaLoading, setMessageAreaLoading] = useState(false);
  return (
    <div className="d-flex flex-column gap-2 ">
      <span>Message</span>
      <ReusableFileUploadModal
        integId={integId}
        visible={modalVisible}
        setVisible={setModalVisible}
        fileList={fileList}
        setFileList={setFileList}
        source={"campaign"}
      />
      <div className="hs-bg-offwhite hs-focus-border rounded">
        <MessagingAreaHeader
          fileList={fileList}
          setFileList={setFileList}
          appProperties={appProperties}
          setTemplateId={setTemplateId}
          module={module}
          setMessageAreaLoading={setMessageAreaLoading}
          handleMessage={handleMessage}
          setModalVisible={setModalVisible}
          handleMedia={handleMedia}
        />
        <Spin spinning={messageAreaLoading}>
          <TextArea
            tabIndex={1}
            className="hs-fs-14"
            placeholder={"Your text goes here"}
            size="large"
            autoSize={{ maxRows: 8, minRows: 8 }}
            bordered={null}
            value={message}
            onChange={handleTextChange}
          />
        </Spin>
      </div>
    </div>
  );
}

function MessagingAreaHeader(props) {
  const { setMessageAreaLoading, handleMessage, module, setTemplateId, setModalVisible, handleMedia } = props;

  return (
    <div
      className="d-flex h-50 bg-light justify-content-between rounded-top d-flex align-items-center px-3"
      style={{
        border: ".5px solid #D8DDDE",
      }}
    >
      <div className="actionIconsSprite mediaUploadIconModern" onClick={() => setModalVisible(true)} />
      <SavedTemplatesSelect
        setTemplateId={setTemplateId}
        tabIndex={5}
        openAddTemplateDrawer={true}
        moduleName={module}
        handleMessage={handleMessage}
        handleMedia={handleMedia}
        setMessageAreaLoading={setMessageAreaLoading}
        showPreview={false}
        refetchOnModuleChange={true}
        fetchOnlyByModule={true}
      />
    </div>
  );
}

const RecipientSelect = React.memo((props) => {
  const {
    filter,
    setFilter,
    module,
    setModule,
    appProperties,
    setCsvData,
    setFilterOptions,
    filterOptions,
    csvData,
    initialValues,
    setAppProperties,
    setModuleOptions,
    moduleOptions,
    showNotVisibleFilterBanner,
    setShowNotVisibleFilterBanner,
    setIsInvalidFilter,
    isInvalidFilter,
    integId,
  } = props;

  const { fetchModules, fetchFiltersByModule, fetchFilterInfoByFilterId } = useTemplates();

  const [loading, setLoading] = useState(false);
  const [isImportCSVModalOpen, setIsImportCSVModalOpen] = useState(false);

  const openImportCSVModal = () => {
    setIsImportCSVModalOpen(true);
  };

  const filterRef = useRef(null);

  const ImportCSVButton = () => {
    return (
      <div className="d-flex align-items-center py-2 cursor-pointer" onClick={openImportCSVModal}>
        <div className="actionIconsSprite csvImportIcon" />
        <div className="hs-color-violet ms-2">Import CSV</div>
      </div>
    );
  };

  const mapModuleOptions = (options) => {
    let labelledOptions = [];
    if (options) {
      options.forEach((ele) => {
        if (ele) {
          labelledOptions?.push({
            label: ele?.charAt(0)?.toUpperCase() + ele?.slice(1),
            value: ele,
          });
        }
      });
    }
    return labelledOptions;
  };

  const mapAndSetModuleOptions = useCallback((modules) => {
    const labelledOptions = mapModuleOptions(modules);
    setModuleOptions(labelledOptions);
    setModule(initialValues?.moduleName ?? labelledOptions?.[0]?.value);
  }, []);

  const mapAndSetFilterOptions = useCallback((filters, moduleName) => {
    let options = [
      {
        label: `All ${moduleName}`,
        value: "-1",
        filterId: "-1",
        filterName: `All ${moduleName}`,
        systemDefined: false,
      },
    ];
    filters?.forEach((filter) => {
      const obj = {
        ...filter,
        label: filter.filterName,
        value: filter.filterId,
      };
      options.push(obj);
    });
    let filter = null;
    const filterId = initialValues?.filterId;
    if (!initialValues?.sharedFilter && initialValues?.disabled) {
      options.push({
        filterId: initialValues?.filterId,
        filterName: initialValues?.filterName,
        label: initialValues?.filterName,
        value: initialValues?.filterId,
        invisible: true,
      });
    }
    setFilterOptions(options);
    if (filterId) {
      filter = options.find((obj) => obj.value === filterId);
      setFilter(filter?.value ?? options?.[0]?.value);
    }
  }, []);

  useEffect(() => {
    const modules = initialValues?.moduleOptions ?? appProperties.modules;
    if (modules) {
      mapAndSetModuleOptions(modules);
    } else {
      setLoading(true);
      fetchModules(appProperties, true)
        .then((response) => {
          if (response?.success) {
            setAppProperties((prev) => {
              const defaultModuleName = response?.data?.[0];
              let filterObject = {};
              filterObject[defaultModuleName] = response?.filters;
              return {
                ...prev,
                modules: response?.data,
                filters: filterObject,
              };
            });
            mapAndSetModuleOptions(response?.data);
          } else {
            failedToFetch("modules");
          }
        })
        .catch((e) => {
          console.error(e);
          failedToFetch("modules");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [appProperties, fetchModules, initialValues?.moduleName, setModule]);

  const failedToFetch = (type) => {
    setLoading(false);
    failureNotification(null, `Something went wrong while fetching ${type}`);
  };

  useEffect(() => {
    if (filter && !initialValues?.sharedFilter) {
      const selectedFilter = filterOptions.find((item) => item?.filterId === filter?.toString());
      if (selectedFilter?.invisible) {
        setShowNotVisibleFilterBanner(true);
      } else {
        setShowNotVisibleFilterBanner(false);
      }
    }
  }, [filterOptions]);

  useEffect(() => {
    const moduleName = module?.value ? module.value : module;
    if (moduleName && appProperties?.serviceInstalled) {
      const filtersByModule = appProperties?.filters?.[moduleName];
      if (filtersByModule) {
        mapAndSetFilterOptions(filtersByModule, moduleName);
      } else if (moduleName !== moduleOptions[0]?.value) {
        setLoading(true);
        fetchFiltersByModule(appProperties, moduleName)
          .then((res) => {
            mapAndSetFilterOptions(res?.filters, moduleName);
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [appProperties, fetchFiltersByModule, initialValues?.campaign?.filterId, module, setFilter]);

  const handleModuleSelect = (ele) => {
    setFilterOptions([]);
    setFilter();
    setModule(ele);
  };

  useEffect(() => {
    if (isInvalidFilter) {
      if (filterRef) {
        filterRef.current?.focus();
      }
    }
  }, [isInvalidFilter]);

  const handleFilterSelect = async (ele) => {
    setLoading(true);
    let count;
    const filter = filterOptions.find((item) => item?.value === ele);
    if (!filter?.count) {
      fetchFilterInfoByFilterId(appProperties, integId, module, ele)
        .then((res) => {
          count = res?.count;
          if (count && count <= MAXIMUM_SUPPORTED_RECORDS_FOR_CAMPAIGN.count) {
            setIsInvalidFilter(false);
          } else {
            setIsInvalidFilter(true);
          }
        })
        .catch((err) => {
          console.log(err);
          failureNotification(null, "Failed to fetch filter info");
        })
        .finally(() => {
          setFilterOptions((prev) =>
            prev.map((item) => {
              if (item?.value === ele) {
                item.count = count;
                return item;
              } else {
                return item;
              }
            })
          );
          setFilter(ele);
          setLoading(false);
        });
    } else {
      setFilter(ele);
      setLoading(false);
    }
  };

  return (
    <div className="d-flex flex-column gap-2">
      <span>To</span>
      <Row className="w-100 gap-3">
        <Col span={6}>
          <div id="selectModule" className="w-100">
            <CustomSearchDropDownSelect
              customProps={{
                popupMatchSelectWidth: 200,
                disabled: initialValues?.disabled,
                bordered: null,
                suffixIcon: loading ? (
                  <div className="actionIconsSprite dropDownLoading" />
                ) : (
                  <div className="actionIconsSprite fromNumberDropDownIcon" />
                ),
                value: module,
                tabIndex: 3,
                className: "hs-bg-offwhite hs-focus-border h-50 w-100 rounded",
                onSelect: handleModuleSelect,
              }}
              listEndComponent={<ImportCSVButton />}
              options={moduleOptions}
            />
          </div>
        </Col>
        <Col flex="auto">
          <div id="selectFilter" className="w-100">
            <CustomSearchDropDownSelect
              customProps={{
                disabled: initialValues?.disabled,
                placeholder: "Select filter",
                popupMatchSelectWidth: 300,
                bordered: null,
                suffixIcon: loading ? (
                  <div className="actionIconsSprite dropDownLoading" />
                ) : (
                  <div className="actionIconsSprite fromNumberDropDownIcon" />
                ),
                value: loading ? "" : filter,
                tabIndex: 3,
                className: `hs-bg-offwhite hs-focus-border h-50 w-100 rounded ${isInvalidFilter && "hs-focus-border-red"}`,
                ref: filterRef,
                onSelect: handleFilterSelect,
              }}
              options={filterOptions}
            />
          </div>
        </Col>
        {showNotVisibleFilterBanner && <Alert message="Filter is not visible to you" banner />}
        <Alert
          rootClassName={`bg-transparent border border-0 hs-fs-12 p-0 ${isInvalidFilter && "hs-color-red"}`}
          message={MAXIMUM_SUPPORTED_RECORDS_FOR_CAMPAIGN.message}
          type="info"
          showIcon={true}
          icon={<div className={`staticIconsSprite ${isInvalidFilter ? "infoIconRed" : "infoIconViolet"}`} />}
        />
      </Row>
      <div></div>
      <ImportCSVModal
        csvData={csvData}
        setIsImportCSVModalOpen={setIsImportCSVModalOpen}
        isImportCSVModalOpen={isImportCSVModalOpen}
        setCsvData={setCsvData}
        setIsInvalidFilter={setIsInvalidFilter}
      />
    </div>
  );
});

const ImportCSVModal = (props) => {
  const { isImportCSVModalOpen, setIsImportCSVModalOpen, setCsvData, setIsInvalidFilter } = props;
  const [errCsvData, setErrCsvData] = useState();
  const [csvParsing, setCsvParsing] = useState(false);
  const { downloadAFile } = useAttachment();

  const closeImportCSVModal = () => {
    setErrCsvData();
    setCsvParsing(false);
    setIsImportCSVModalOpen(false);
  };
  const { Dragger } = Upload;

  const handleChange = async (info) => {
    successNotification("CSV contacts imported. Start sending campaigns.");
    const { file } = info;
    try {
      setCsvParsing(true);
      const Papa = await import("papaparse");
      const text = await file.text();
      Papa.parse(text, {
        header: true,
        skipEmptyLines: true,
        encoding: "UTF-8",
        complete: (parsedData) => {
          if (
            parsedData?.errors?.length > 0 ||
            !parsedData?.meta?.fields?.includes("Name") ||
            !parsedData?.meta?.fields?.includes("PhoneNumber")
          ) {
            console.error("Error parsing CSV:", parsedData.errors, parsedData?.meta?.fields);
            setErrCsvData({
              length: parsedData.data?.length,
              file: file,
              error: "Ensure the CSV file includes Name and Phone number fields.\nPlease refer to the sample CSV file for guidance.",
            });
            setCsvParsing(false);
            return;
          }

          if (parsedData.data?.length > MAXIMUM_SUPPORTED_RECORDS_FOR_CAMPAIGN.count) {
            setErrCsvData({
              length: parsedData.data?.length,
              file: file,
              error: MAXIMUM_SUPPORTED_RECORDS_FOR_CAMPAIGN.message,
            });
            setCsvParsing(false);
            return;
          } else {
            setIsInvalidFilter(false);
            setCsvData({ length: parsedData.data?.length, file: file });
            setCsvParsing(false);
            setIsImportCSVModalOpen(false);
          }
        },
        error: () => setCsvParsing(false),
      });
    } catch (e) {
      console.error(e);
      setCsvParsing(false);
    }
  };

  const handleDownload = async () => {
    downloadAFile("CAMPAIGN_SAMPLE_CSV_FILE", "sample.csv");
  };

  return (
    <Modal
      cancelButtonProps={{
        hidden: true,
      }}
      okButtonProps={{
        hidden: true,
      }}
      className="importCSVModal"
      closeIcon={null}
      maskClosable={false}
      title={null}
      open={isImportCSVModalOpen}
    >
      <div className="row mx-auto w-100">
        <div className="d-flex justify-content-between">
          <div className="my-auto hs-fs-18">Import CSV</div>
          <div className="actionIconsSprite modalCloseIcon" onClick={closeImportCSVModal} />
        </div>
        <div className="row w-100 mx-auto px-3">
          <div className="mt-4 px-0 mb-4">
            <Dragger
              showUploadList={false}
              openFileDialogOnClick
              multiple={false}
              beforeUpload={() => false}
              className={`w-100 h-50 ${errCsvData?.length > 0 ? "csvError" : "csvDragger"}`}
              type="drag"
              accept="text/csv"
              onChange={handleChange}
            >
              <div className="my-5">
                <div className="d-flex justify-content-center w-100">
                  {csvParsing ? <div className="actionIconsSprite dropDownLoading" /> : <div className="actionIconsSprite csvUploadIcon" />}
                </div>
                <div className="hs-fs-12 hs-color-dark-grey">
                  Drag and drop file here or
                  <span className="hs-color-violet"> Click to upload</span>
                </div>
                <div className="d-flex mt-2 justify-content-center">
                  <div className="csvErrorDescription hs-fs-12">{errCsvData?.error && <span>{errCsvData?.error}</span>}</div>
                </div>
              </div>
            </Dragger>
            <div className="hs-color-dark-grey hs-fs-12">Supported format : CSV</div>
          </div>
          <div className="w-100 hs-bg-light-grey hs-border-dark-grey my-4">
            <Row>
              <Col span={22}>
                <div className="ps-3 py-2 my-1">
                  <div>CSV file requirement :</div>
                  <div className="hs-fs-12 hs-color-dark-grey lh-sm">
                    Please ensure that the CSV file includes both the "PhoneNumber" and "Name" fields. Attached is a <b>Sample CSV</b> file for
                    your reference.
                  </div>
                </div>
              </Col>
              <Col span={2}>
                <div className="d-flex align-items-center h-100">
                  <div className="actionIconsSprite sampleCsvDownloadIcon" onClick={handleDownload} />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Modal>
  );
};

function CampaignName() {
  return (
    <FormInput
      formItemName={"campaignName"}
      formItemLabel={"Campaign Name"}
      formItemRules={[
        {
          required: true,
          message: <span className="position-absolute">Required!</span>,
        },
      ]}
      formExtraContent="Only for analytics purposes, not exposed to your customers."
      instantfocus={true}
    />
  );
}

export default CampaignContent;
