import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { Row, Col, Card, Divider } from "antd";
import "../../assets/css/services/frame.css";
import "../../assets/css/services/textsms.css";
import ServicesSidebar from "./ServicesSidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { ProviderCommonObj, ProviderExtendedCommonObj } from "../../constants/ProviderConstants";

const currentlySupporting = ["twilio", "ringcentral", "hello_send"];

function TextSMS() {
  const [appProperties] = useContext(AppContext);
  const [textSMSServices, setTextSMSServices] = useState({});

  useEffect(() => {
    const getSMSServicesData = (installedApps, availableApps) => {
      var filteredAvailableApps = availableApps.filter((app) => {
        const installedApp = installedApps?.find((installedApp) => installedApp.right?.service_id === app.serviceId);
        return installedApp && installedApp?.right?.auth?.url;
      });
      filteredAvailableApps = availableApps.filter((obj) => !filteredAvailableApps.includes(obj));
      const installedAppsList = installedApps?.filter((app) => app.right?.auth?.url && !app.right?.service_display_name?.includes("Messenger"));
      filteredAvailableApps = filteredAvailableApps.filter((obj) => currentlySupporting.includes(obj.name));
      setTextSMSServices({
        installed: installedAppsList,
        available: filteredAvailableApps,
      });
    };
    if (appProperties.apps) {
      getSMSServicesData(appProperties.installedApps, [...appProperties.apps.SMS]);
    }
  }, [appProperties.apps, appProperties.installedApps]);

  return (
    <>
      {!appProperties.serviceInstalled ? (
        <Row>
          <Col span={24}>
            <Guide />
          </Col>
        </Row>
      ) : null}
      <TextSMSServices services={textSMSServices} serviceInstalled={appProperties.serviceInstalled} />
    </>
  );
}

const TextSMSServices = React.memo((props) => {
  const [appProperties, setAppProperties] = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const navigateToAuthPage = (serviceName, serviceId) => {
    appProperties.rightServiceName = serviceName.toLowerCase();
    let assignerObj1 = Object.assign(ProviderCommonObj, ProviderExtendedCommonObj[serviceName]);
    appProperties.providerCommonObj = assignerObj1;
    setAppProperties(appProperties);
    if (serviceName === "hello_send") {
      navigate(`/channels/textsms/helloSend${location.search}`);
    } else {
      navigate(`/channels/textsms/${serviceName}${location.search}`);
    }
  };

  const { services } = props;

  return (
    <>
      <Row className="ms-4 mt-3">
        {props.serviceInstalled ? (
          <Col span={4} lg={4} md={4} xl={4} sm={4} xs={24} className="ms-2">
            <ServicesSidebar />
          </Col>
        ) : null}
        <Col
          style={{ minHeight: "76vh", height: "100%" }}
          span={props.serviceInstalled ? 19 : 24}
          lg={props.serviceInstalled ? 19 : 24}
          md={props.serviceInstalled ? 19 : 24}
          xl={props.serviceInstalled ? 19 : 24}
          sm={props.serviceInstalled ? 19 : 24}
          xs={24}
        >
          {appProperties.serviceInstalled && (
            <div className="ms-4" style={{ fontSize: 14, color: "#030229" }}>
              Connected integrations
            </div>
          )}
          <div
            style={{
              margin: "1rem 1rem",
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(16rem, 0fr))",
            }}
          >
            {services.installed?.length > 0 &&
              services?.installed?.map((obj) => (
                <Card
                  className="card mb-3"
                  style={{
                    width: "14rem",
                    height: "11.5rem",
                    border: "none",
                    cursor: "pointer",
                    boxShadow: 0,
                    //   paddingTop: "1rem",
                    //   margin: "0 2rem 2rem 0",
                  }}
                  bordered={false}
                  key={obj?.right?.service_name}
                  // hoverable={true}
                  onClick={() => navigateToAuthPage(obj?.right?.service_name, obj?.right?.service_id)}
                >
                  <div>
                    <div className={`rsLogo  ${obj?.right?.service_name?.toLowerCase()}`}></div>
                  </div>
                </Card>
              ))}
          </div>
          <>
            <Divider
              style={{
                minWidth: "50%",
                width: "50%",
                margin: "10px auto",
                border: "1px solid #F3F3F3",
                borderRadius: 20,
              }}
            />
            <div className="mt-3 ms-4" style={{ fontSize: 14, color: "#030229" }}>
              Available integrations
            </div>
          </>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(16rem, 0fr))",
              margin: "1rem 1rem",
            }}
          >
            {services.available?.length > 0 &&
              services?.available?.map((obj) => (
                <Card
                  className="card mb-3"
                  style={{
                    width: "14rem",
                    height: "11.5rem",
                    border: "none",
                    cursor: "pointer",
                    display: obj?.name === "hello_send" ? "none" : "block",
                  }}
                  bordered={false}
                  key={obj?.name}
                  onClick={() => navigateToAuthPage(obj?.name, obj?.serviceId)}
                >
                  <div>
                    <div className={`rsLogo  ${obj?.name.toLowerCase()}`}></div>
                  </div>
                </Card>
              ))}
          </div>
        </Col>
      </Row>
    </>
  );
});

const Guide = () => {
  return (
    <div className="frame guide m-2" style={{ width: "98%" }}>
      <p className="text-wrapper">Just 3 steps away to Enable the SMS Channel</p>
      <div className="enable-sms-channel-info">
        <div>
          <p>
            <strong>1. Select an SMS Channel:</strong> Choose a compatible SMS channel with your own phone number. Don't have an account with any
            of the listed services? Go back and click on the{" "}
            <strong>
              <em> "Buy Number"</em>
            </strong>{" "}
            option to acquire your own business number.
          </p>
        </div>

        <div>
          <p>
            <strong>2. Authorize:</strong> Log into the selected service and grant permission.
          </p>
        </div>

        <div>
          <p>
            <strong>3. Configure & Use:</strong> Select the Phone number and start using the integrated SMS channel.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TextSMS;
