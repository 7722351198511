import { Breadcrumb } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import necessary items from React Router
import "../../assets/css/breadCrumb.css";

const BreadCrumb = (props) => {
  const { breadcrumbItems, setBreadCrumbItems } = props;
  const [breadCrumbSegmentItems, setBreadCrumbSegmentItems] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const pathSegments = location.pathname.split("/").filter((path) => path);
    setBreadCrumbItems(pathSegments);
  }, [location.pathname, setBreadCrumbItems]);

  const [isHome, setIsHome] = useState(false);
  const navigateToRoute = useCallback(
    (path) => {
      const trimmedPath = location.pathname.substring(0, location.pathname.indexOf(path ?? "") + path?.length);
      console.log("path,,, trim", trimmedPath);
      const currentParams = new URLSearchParams(window.location.search);
      const newParams = currentParams.toString();
      navigate(`${trimmedPath}?${newParams}`);
    },
    [location.pathname, navigate]
  );

  function BreadCrumbItem(props) {
    const { path, name, index, length } = props;
    return (
      <span
        onClick={() => {
          navigateToRoute(path);
        }}
        style={{
          cursor: "pointer",
          fontSize: 13,
          color: length === index + 1 ? "" : "#605bff",
        }}
      >
        {name}
      </span>
    );
  }

  useEffect(() => {
    if (breadcrumbItems?.[0]?.custom) {
      setBreadCrumbSegmentItems(() => {
        return breadcrumbItems.map((item, index) => {
          return {
            title: <BreadCrumbItem path={item?.link} name={item?.title} index={index} length={breadcrumbItems?.length} />,
          };
        });
      });
    } else {
      setBreadCrumbSegmentItems(() => {
        const mappedBreadCrumbItems = breadcrumbItems?.map((path, index) => {
          return {
            title: (
              <BreadCrumbItem
                path={path}
                name={
                  path === "textsms"
                    ? "Text SMS"
                    : path === "twilio_whatsapp"
                    ? "WhatsApp"
                    : path === "workflow"
                    ? "Automation"
                    : path.charAt(0).toUpperCase() + path.slice(1)
                }
                index={index}
                length={breadcrumbItems?.length}
              />
            ),
          };
        });
        return mappedBreadCrumbItems;
      });
    }
  }, [breadcrumbItems, navigateToRoute]);

  const containerStyle = {
    borderTop: "1px solid var(--Stroke, #F1F3F9)",
    width: "auto",
    height: "26px",
    padding: "1px 0px 1px 7px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: "0px",
  };

  useEffect(() => {
    setIsHome(breadcrumbItems?.length === 2 && breadcrumbItems?.[0] === "campaigns" && breadcrumbItems?.[1] === "list");
  }, [breadcrumbItems]);

  useEffect(() => {
    if (isHome) {
      setBreadCrumbSegmentItems([]);
    }
  }, [isHome]);

  return (
    <div style={containerStyle}>
      <Breadcrumb
        items={[
          {
            title: (
              <div
                className={`ms-4 ${isHome ? "homeIcon" : "homeIconFilled"}`}
                onClick={() => {
                  navigate(`/campaigns/list${location.search}`);
                }}
              >
                Home
              </div>
            ),
          },
          ...(breadCrumbSegmentItems ?? []),
        ]}
      />
    </div>
  );
};

export default BreadCrumb;
