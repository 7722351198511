import ".././PricingPage.css";
import React from "react";
import { Card, Col, Row } from "antd";
import { Switch, Collapse, theme } from "antd";
import { Button, Space, Modal, Layout } from "antd";
import { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import useHttp from "../hooks/useHttp";
import { useScript } from "./../hooks/Hooks";

function PricingPage() {
  const { token } = theme.useToken();
  const panelStyle = {
    borderTop: "2px solid #D7DEF0",
  };
  const [show, setShow] = useState(true);
  const [plan, setPlan] = useState([]);
  const [feature, setFeature] = useState([]);
  const [defaults, setDefaults] = useState([]);
  const [key, setKey] = useState(0);
  const [domainUrl, setDomainUrl] = useState("");
  const [successModal, setSuccessModal] = useState(false);

  let [isSuccessCheckout, setIsSuccessCheckout] = useState(false);
  const { fetchData } = useHttp();
  const queryString = window.location.search;
  const urlParamsObj = new URLSearchParams(queryString);
  var locationHref = window.location.origin;

  const onChange = (checked) => {
    setShow(checked);
  };
  const handleRefresh = () => {
    setKey((prevKey) => prevKey + 1);
  };
  const urlParams = new URLSearchParams(window.location.search);

  const handleClickDomainUrl = () => {
    window.open(domainUrl);
  };

  const chargeBeeScript = useScript("https://js.chargebee.com/v2/chargebee.js");

  if (chargeBeeScript === "ready") {
    var siteAttribute = "oapps";
    if (
      locationHref.indexOf("jupiter.rekzyl.com") !== -1 ||
      locationHref.indexOf("client4.rekzyl.com") !== -1 ||
      locationHref.indexOf("localhost") !== -1
    ) {
      siteAttribute = "oapps-test";
    }
    window.Chargebee.init({ site: siteAttribute });
  }
  const items = [
    {
      key: "1",
      label: (
        <span
          className="FAQuestion"
          style={{
            fontWeight: "700",
            fontSize: "18px",
            color: "black",
          }}
        >
          {" "}
          Does an SMS extension support two-way communication?{" "}
        </span>
      ),
      children: (
        <p>
          Yes, our extension supports two-way messaging, enabling interactive conversations with customers. Users can receive and respond to SMS
          replies directly from the CRM system.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "2",
      label: (
        <span className="FAQuestion" style={{ fontWeight: "700", fontSize: "18px", color: "black" }}>
          How does an SMS extension help with lead generation?
        </span>
      ),
      children: (
        <p>
          By utilizing SMS campaigns, an SMS extension can capture leads and nurture them by sending personalized text messages, driving
          engagement and guiding prospects through the sales funnel.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "3",
      label: (
        <span className="FAQuestion" style={{ fontWeight: "700", fontSize: "18px", color: "black" }}>
          Can an SMS extension automate communication processes?{" "}
        </span>
      ),
      children: (
        <p>
          Yes, an SMS extension allows for automated communication, such as sending automated notifications, reminders, or follow-ups based on
          CRM triggers or events
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "4",
      label: (
        <span
          className="FAQuestion"
          style={{
            fontWeight: "700",
            fontSize: "18px",
            color: "black",
          }}
        >
          {" "}
          What features are available during the free trial?{" "}
        </span>
      ),
      children: (
        <p>
          During the free trial, you’ll have access to the complete range of features our app offers. Explore and evaluate its capabilities to
          make an informed decision.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "5",
      label: (
        <span className="FAQuestion" style={{ fontWeight: "700", fontSize: "18px", color: "black" }}>
          How are SMS messages billed when using an SMS extension?
        </span>
      ),
      children: (
        <p>
          With our SMS extension, we employ a subscription-based billing model that eliminates per SMS charges. Instead, you can choose from
          monthly or yearly subscription plans that include a specified number of SMS messages within the subscription cost. This approach
          ensures cost predictability and removes the need to pay for individual messages.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "6",
      label: (
        <span className="FAQuestion" style={{ fontWeight: "700", fontSize: "18px", color: "black" }}>
          What’s the difference between annual and monthly billing?
        </span>
      ),
      children: (
        <p>
          Monthly billing charges occur on the same day each month, while annual billing involves a single payment for the entire year, with the
          added benefit of a discounted price.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "7",
      label: (
        <span className="FAQuestion" style={{ fontWeight: "700", fontSize: "18px", color: "black" }}>
          Are there any additional costs to consider?{" "}
        </span>
      ),
      children: <p>There are no additional costs apart from the subscription fee.</p>,
      style: panelStyle,
    },
    {
      key: "8",
      label: (
        <span className="FAQuestion" style={{ fontWeight: "700", fontSize: "18px", color: "black" }}>
          How can I contact customer support?
        </span>
      ),
      children: (
        <p>
          For assistance, please email us at <a href="mailto: assist@oapps.xyz">assist@oapps.xyz</a>, and our team will promptly respond to your
          queries.
        </p>
      ),
      style: panelStyle,
    },
  ];

  function handleClick() {
    const urlParams = new URLSearchParams(window.location.search);
    var osyncId = urlParams.get("osyncId");
    var leftServiceId = urlParams.get("leftServiceId");
    var hash = urlParams.get("hash");
    var appProperties = {};
    appProperties.hash = hash;

    if (window.Chargebee !== undefined && window.Chargebee !== null) {
      window.Chargebee.registerAgain();
    }
    console.log("leftServiceId>>>>>", leftServiceId);
    const cbInstance = window.Chargebee.getInstance();
    const cart = cbInstance.getCart();
    cart.setCustomer({
      cf_oapps_id: osyncId,
      cf_leftserviceid: leftServiceId,
      cf_accounttype: "Omni",
      cf_servicename: "pipedrive",
    });

    cbInstance.setCheckoutCallbacks(function (cart) {
      // you can define a custom callbacks based on cart object
      return {
        loaded: function () {},
        close: function () {
          handleRefresh();
          isSuccessCheckout ? setSuccessModal(true) : console.log("failure");
        },

        success: function (hostedPageId) {
          setIsSuccessCheckout(true);
          if (hostedPageId !== null && hostedPageId !== "") {
            var url = `chargebee/license/add?hostedPageId=${hostedPageId}`;
            fetchData(url, "POST", null, appProperties).then((response) => {});
            handleRefresh();
          }
        },
        step: function (value) {
          if (value === "thankyou_screen") {
            isSuccessCheckout = true;
          }
        },
      };
    });
  }

  useEffect(() => {
    const domain = urlParams.get("domain");
    var pipedrivePagedomainUrl = domain + "/persons/list";
    setDomainUrl(pipedrivePagedomainUrl);

    var productId = urlParamsObj.get("productId");
    var groupName = urlParamsObj.get("groupName");
    if (productId !== undefined && groupName !== undefined) {
      let targetUrl = `omni/license/${productId}?groupName=${groupName}`;
      fetchData(targetUrl, "GET", null, null).then((response) => {
        response = JSON.parse(response).data;
        var defaultsData = response.defaults;
        const defaultsAscending = [...defaultsData].sort((a, b) => a.featureNumber - b.featureNumber);
        setDefaults(defaultsAscending);
        var featuresData = response.features;
        setFeature(featuresData);
        var plans = response.plans;
        setPlan(plans);
        if (window.Chargebee !== undefined && window.Chargebee !== null) {
          window.Chargebee.registerAgain();
        }
      });
    }
  }, []);
  const planData = plan.map((data, id) => {
    var splittedNum = data.data.yearlyPricing.toString().split(".");
    var nonDecimalYear = splittedNum[0];
    var decimalYear = splittedNum[1];

    splittedNum = data.data.monthlyPricing.toString().split(".");
    var nonDecimalMonth = splittedNum[0];
    var decimalMonth = splittedNum[1];

    if (data.data.trialDays === null || data.data.trialDays === undefined || data.data.trialDays === "") {
      data.data.trialDays = 0;
    }
    var card = (
      <Space key={key} direction="vertical" size={16}>
        <Card
          xs={24}
          xl={8}
          className="shadow pricingCard"
          title={
            <div style={{ padding: "0px", width: "300" }}>
              <div>
                <p
                  style={{
                    textAlign: "right",
                    color: "#009EFD",
                    fontSize: "10px",
                    marginBottom: "0px",
                    visibility: show ? "visible" : "hidden",
                  }}
                >
                  <b>Save {data.data.yearlyDiscount}% </b>on yearly plan!
                </p>
                <div
                  style={{
                    width: "62px",
                    height: "22px",
                    backgroundColor: "#E2F4FF",
                    borderRadius: "25px",
                    paddingTop: "2px",
                    visibility: show ? "visible" : "hidden",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "#009EFD",
                      fontSize: "10px",
                      fontWeight: "700",
                    }}
                  >
                    Popular
                  </p>
                </div>
              </div>
              <div>
                {show ? (
                  <>
                    <p
                      style={{
                        textAlign: "center",
                        paddingTop: "7px",
                        fontWeight: "600",
                        fontSize: "30px",
                        marginBottom: "0px",
                        color: "#243B53",
                      }}
                    >
                      <s style={{ fontSize: "20px", fontWeight: "400" }}>
                        &nbsp;<b>${data.data.yearlyOriginalPricing}&nbsp;</b>
                      </s>{" "}
                      ${nonDecimalYear}
                      <span style={{ fontSize: "small" }}>{decimalYear === undefined ? "" : <span>.{decimalYear}</span>}</span>{" "}
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#829AB1",
                        }}
                      >
                        /month
                      </span>{" "}
                    </p>
                    <p
                      style={{
                        fontSize: "x-small",
                        textAlign: "center",
                        fontFamily: "inter",
                        paddingTop: "0px",
                        color: "#009EFD",
                        visibility: "visible",
                      }}
                    >
                      Billed yearly
                    </p>
                    <Button
                      data-cb-type="checkout"
                      data-cb-item-0="Gold-USD-Yearly"
                      data-cb-item-0-quantity="1"
                      className="getStarted"
                      type="primary"
                      onMouseEnter={handleClick}
                    >
                      <p
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          paddingTop: "5px",
                        }}
                      >
                        Get started
                      </p>
                    </Button>
                  </>
                ) : (
                  <>
                    <p
                      style={{
                        textAlign: "center",
                        paddingTop: "7px",
                        marginBottom: "0px",
                        fontWeight: "600",
                        fontSize: "30px",
                        color: "#243B53",
                      }}
                    >
                      <s style={{ fontSize: "20px", fontWeight: "400" }}>
                        &nbsp;<b>${data.data.monthlyOriginalPricing}&nbsp;</b>
                      </s>{" "}
                      ${nonDecimalMonth}
                      <span style={{ fontSize: "small" }}>{decimalMonth === undefined ? "" : <span>.{decimalMonth}</span>}</span>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#829AB1",
                        }}
                      >
                        /month
                      </span>{" "}
                    </p>

                    <Button
                      data-cb-type="checkout"
                      data-cb-item-0="Gold-USD-Monthly"
                      data-cb-item-0-quantity="1"
                      className="getStarted mt-4"
                      type="primary"
                      onMouseEnter={handleClick}
                    >
                      <p
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          paddingTop: "5px",
                        }}
                      >
                        Get started
                      </p>
                    </Button>
                  </>
                )}
              </div>
            </div>
          }
        >
          <div style={{ textAlign: "left" }}>
            <h5
              className="listTitle"
              style={{
                fontSize: "16px",
                fontFamily: "inter",
                fontWeight: 600,
                marginBottom: "0px",
                color: "#18181B",
              }}
            >
              What you get :
            </h5>
            <ul
              className="text-muted fw-light featureList"
              style={{
                fontFamily: "inter",
                fontSize: "10px",
                fontWeight: "400",
                paddingBottom: "0px",
                marginBottom: "10px",
                height: "50dvh",
                overflowY: "auto",
              }}
            >
              {defaults.map((pricingFeaturesData, id) => {
                return (
                  <li>
                    {pricingFeaturesData.key.includes("PD_GOLD_OM") && !pricingFeaturesData.key.includes("PD_GOLD_OM_TWSS") ? (
                      <span>
                        {" "}
                        <img className="checkMark" src={require("../assets/images/CheckMark.png")} alt="OAppS" /> &nbsp; &nbsp;
                        {pricingFeaturesData.pricingDetails}
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                );
              })}
              {feature.map((featureDefaultdata, id) => {
                let str = featureDefaultdata.featureName;
                let strCaps = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
                let word = str.split(" ");
                return (
                  <li>
                    {featureDefaultdata.featureKey.includes("OM") &&
                    featureDefaultdata.featureKey !== "OM_PNS" &&
                    featureDefaultdata.featureKey !== "OM_US" &&
                    featureDefaultdata.featureKey !== "OM_ORL" &&
                    featureDefaultdata.featureKey !== "OM_T" &&
                    featureDefaultdata.featureKey !== "OM_BSS" ? (
                      <span>
                        {" "}
                        <img className="checkMark" src={require("../assets/images/CheckMark.png")} alt="OAppS" /> &nbsp; &nbsp;
                        {!featureDefaultdata.featureName.includes("SMS") && !featureDefaultdata.featureName.includes("MMS")
                          ? strCaps
                          : word[0] === "Automated" || word[0] === "Two-Way"
                          ? word[0].charAt(0).toUpperCase() +
                            word[0].slice(1).toLowerCase() +
                            " " +
                            word[1].toUpperCase() +
                            " " +
                            word[2].toLowerCase()
                          : word[0].toUpperCase() + " " + word[1].toLowerCase()}
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          <p
            style={{
              textAlign: "right",
              fontSize: "10px",
              marginBottom: "5px",
              marginRight: "0px",
              color: "#486581",
            }}
          >
            Subscriptions powered by Chargebee and Stripe
          </p>
        </Card>
      </Space>
    );
    return (
      <Col xl={8} xs={24}>
        {card}
      </Col>
    );
  });
  return (
    <Layout className="pricingContent">
      <header
        className=" shadow-sm campaignHeader bg-white"
        style={{
          position: "fixed",
          width: "100%",
          height: "60px",
          zIndex: "1",
          top: 0,
          display: "flex",
        }}
      >
        <img className="oappsLogo" src={require("../assets/images/Artboard4.png")} alt="OAppS" />
        {/* <hr /> */}
      </header>
      <content className="bg-white ">
        <Row className="bg-white pricingRow mb-5 pb-5 ps-5 ms-5">
          <Col xs={24} sm={24} md={24} lg={18} xl={14} className="pt-4 mt-4">
            <Row className="d-flex justify-content-center">
              <Col className="pt-5 mt-5">
                <h1
                  className="over pt-5 mt-5"
                  style={{
                    fontSize: "40px",
                    fontFamily: "inter",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  “Over 500+ businesses have chosen us”
                </h1>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center pt-3">
              <Col>
                <p
                  className="certified"
                  style={{
                    textAlign: "center",
                    fontWeight: "500",
                    fontSize: "20px",
                    fontFamily: "inter",
                    color: "#486581",
                  }}
                >
                  Certified solution partner : Empowering success through expert solutions
                </p>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center pt-4">
              <Col className="d-flex justify-content-center">
                <p
                  className="certified"
                  style={{
                    textAlign: "center",
                    fontWeight: "500",
                    fontSize: "13px",
                    fontFamily: "inter",
                    color: "#486581",
                  }}
                >
                  Transparent pricing | 7 days cashback guarantee | Cancel anytime
                </p>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col className="d-flex justify-content-center">
                <img className="pt-3 mt-3 w-80" src={require("../assets/images/PartnersLogo.png")} alt="OAppS" />
              </Col>
            </Row>
          </Col>
          <Col
            className="pricingCol"
            // xs={24}
            // sm={6}
            // md={6}
            // lg={6}
            // xl={10}
            style={{
              textAlign: "right",
              fontSize: "20px",
              float: "right",
              marginRight: "20vh",
            }}
          >
            {" "}
            <div
              className="PricingPart"
              style={{
                zIndex: "-1px",
              }}
            >
              <div
                className="d-flex justify-content-center ps-5 ms-5"
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  marginTop: "15px",
                }}
              >
                <span style={{ opacity: show ? "50%" : "100%" }}>Monthly &nbsp;</span>{" "}
                <Switch id="yearly" defaultChecked onChange={onChange} style={{ backgroundColor: "#009EFD" }} />{" "}
                <span style={{ opacity: show ? "100%" : "50%" }}> &nbsp; Yearly</span>
                <br />
              </div>
              <div className="Yearly ms-5 ps-5 w-100">{<Row gutter={3}>{planData}</Row>}</div>
            </div>
          </Col>
        </Row>
        <Row className="pb-5 mt-5 pt-5 ps-5 ms-5 FAQRow">
          <Col className="pb-3 mt-5 pt-5">
            <div className="mt-5 pt-5 ms-5 FAQDiv">
              <div className="mt-5 pt-5">
                <p className="FAQTitle mt-5 pt-5 mb-0">Frequently Asked Questions</p>
              </div>
              <br />
              <br />
              <div id="FAQ">
                <Collapse
                  accordion
                  bordered={false}
                  expandIconPosition="end"
                  expandIcon={({ isActive }) => (
                    <PlusOutlined
                      style={{
                        fontSize: "100%",
                        color: "black",
                        fontWeight: "200",
                      }}
                      rotate={isActive ? 45 : 0}
                    />
                  )}
                  size="large"
                  style={{
                    width: "600px",
                    background: token.colorBgContainer,
                    borderBottom: "3px solid #D7DEF0",
                    borderRadius: "0px",
                  }}
                  items={items}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              className="FAQIcon"
              style={{
                bottom: "3%",
                left: "3%",
              }}
            >
              <a href="#FAQ">
                <img src={require("../assets/images/faq.png")} alt="OAppS" />{" "}
              </a>
            </div>
          </Col>
        </Row>
        <Modal className="successPricingModel" centered closable={false} open={successModal} footer={null}>
          {" "}
          <Row>
            <Col>
              <div className="mb-5">
                <Row className="ms-3">
                  <Col>
                    <img style={{ paddingLeft: "32px" }} src={require("../assets/images/successImage.png")} alt="OAppS" />
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center ms-3">
                  <Col className="d-flex justify-content-center">
                    <h5>Congratulations!</h5>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center pt-3">
                  <Col className="d-flex justify-content-center ms-3">
                    <p style={{ fontSize: "14px" }}>Subscription activated successfully</p>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                  <Col className="d-flex justify-content-center ms-3">
                    <p
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      Thank you for choosing our services!
                    </p>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center pt-3">
                  <Col className="d-flex justify-content-center ms-3">
                    <button
                      style={{
                        backgroundColor: "#67D76B",
                        border: "none",
                        color: "#FFFFFF",
                        width: "180px",
                        height: "30px",
                      }}
                      onClick={handleClickDomainUrl}
                    >
                      Go to pipedrive
                    </button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Modal>
        {/* </div> */}
      </content>
    </Layout>
  );
}
export default PricingPage;
