import { LoadingOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Empty,
  Image,
  Input,
  List,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Switch,
  Tag,
  Tooltip,
  TreeSelect,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import messageTone from "../assets/audio/Message.mp3";
import "../assets/css/conversationPage.css";
import SearchIcon from "../assets/icons/Search.png";
import MediaError from "../assets/images/mediaError.jpg";
import MediaUploadIconWrapper from "../components/custom/MediaUploadIconWrapper";
import ReusableFilePreview from "../components/custom/ReusableFilePreview";
import { ProviderCommonObj, ProviderExtendedCommonObj } from "../constants/ProviderConstants";
import { AppContext } from "../context/AppContext";
import useHttp from "../hooks/useHttp";
import { localStorage, urlParams } from "./../constants/AppConstants";
import { ConvContext } from "./ConversationContext";
import { SavedTemplatesSelect } from "./outbound/SavedTemplatesSelect";
import { failureNotification, failureNotificationWithBtn } from "./utils/CommonNotifications";
import { mapToMediaList, openServiceNotAuthorizedModal } from "./utils/commonUtils";
import { BuyFunds, ConfigurePhone, FromNumberComponent, initPusher } from "./utils/CommonVessels";
const { Search } = Input;

function ConversationView(props) {
  const { chatView, toNumber, setHelloSend } = props;
  const [appProperties] = useContext(AppContext);
  const { channel, sendsms, selectedContactId } = useContext(ConvContext);
  const [numberList, setNumbersList] = useState([]);
  const [showDescription, setShowDescription] = useState(true);
  const [selectedContact, setSelectedContact] = useState(null);
  const [filterContact, setFilterContact] = useState("");
  const [services, setServices] = useState([]);
  const [showConversations, setShowConversations] = useState(false);
  const [isChannelPresent, setIsChannelPresent] = useState(services?.length > 0 ? true : false);
  const [serviceList, setServiceList] = useState([]);
  const [customerInfoObj, setCustomerInfoObj] = useState([]);
  const { fetchData } = useHttp();

  useEffect(() => {
    if (services?.length < 1 && appProperties.apps && !chatView) {
      fetchData("omni/integrations?includeResources=true", "GET", null, appProperties)
        .then(function (response) {
          try {
            if (response) {
              response = JSON.parse(response);
              var servicesResp = response.data.map((obj) => {
                return {
                  value: obj?.serviceInfo.serviceId,
                  title: obj?.serviceName,
                  children: obj?.resource?.data?.map((data) => {
                    return {
                      value: data.phoneNumber,
                      title: data.phoneNumber,
                    };
                  }),
                };
              });
              setServiceList(response?.data);
              setServices(servicesResp);
            }
          } catch (error) {
            console.error("Error processing response:", error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [appProperties?.apps, fetchData, services?.length]);

  const handleNumberSelect = (firstName, selectedNumber) => {
    setIsChannelPresent(true);
    setSelectedContact(selectedNumber);
    var getConversationObject = [];
    if (numberList.length > 0) {
      getConversationObject = numberList?.filter((obj) => obj?.orgCustomer === selectedNumber?.toNumber);
    }
    if (getConversationObject.length < 1 || selectedNumber.new) {
      var idObject = {
        firstName: firstName,
        orgCustomer: selectedNumber?.toNumber,
        new: "New",
      };
      setNumbersList((prevNumberList) => [{ ...idObject }, ...prevNumberList]);
      setCustomerInfoObj((prev) => ({ ...prev, firstName: firstName }));
    }
  };

  useEffect(() => {
    if (!chatView) {
      channel?.bind("CONTACTS_UPDATE", (data) => {
        console.log(">> CONTACTS_UPDATE >>>>>", data);
        if (data) {
          try {
            let response = JSON.parse(data?.data);
            if (response.contactId !== selectedContactId.current) {
              setNumbersList((prev) => {
                const existingIndex = prev?.findIndex((item) => item?.contactId === response?.contactId);
                if (existingIndex !== -1) {
                  const newList = [...prev];
                  newList[existingIndex] = response;
                  newList.unshift(newList.splice(existingIndex, 1)[0]);
                  return newList;
                } else {
                  return [response, ...(prev || [])];
                }
              });
            }
          } catch (e) {
            console.error("ERROR::::", e);
          }
        }
      });
      return () => {
        channel?.unbind("CONTACTS_UPDATE");
      };
    }
  }, [channel, chatView]);

  return (
    <>
      <audio className="d-none" id="notification" src={messageTone}></audio>
      <div className="conversationInbox ms-4"></div>
      <div className="conversationInbox ms-4 ps-2 mt-2">
        <Row>
          {!chatView && (
            <Col xl={7} sm={24}>
              <ListView
                setSelectedContact={setSelectedContact}
                services={services}
                setShowDescription={setShowDescription}
                numberList={numberList}
                selectedContact={selectedContact}
                handleNumberSelect={handleNumberSelect}
                setIsChannelPresent={setIsChannelPresent}
                filterContact={filterContact}
                setFilterContact={setFilterContact}
                setNumbersList={setNumbersList}
                appProperties={appProperties}
                setCustomerInfoObj={setCustomerInfoObj}
                selectedContactId={selectedContactId}
                showConversations={showConversations}
                setShowConversations={setShowConversations}
                showDescription={showDescription}
              />
            </Col>
          )}
          <Col xl={chatView ? 24 : 17} sm={24} span={24}>
            <DetailView
              contactId={selectedContactId}
              chatView={chatView}
              channel={channel}
              selectedContact={selectedContact}
              serviceList={serviceList}
              isChannelPresent={isChannelPresent}
              services={services}
              handleNumberSelect={handleNumberSelect}
              from={selectedContact?.fromNumber}
              toNumber={toNumber || selectedContact?.toNumber}
              setHelloSend={setHelloSend}
              associatedIntegID={selectedContact?.integId}
              setNumbersList={setNumbersList}
              numberList={numberList}
              omni={true}
              customerInfoObj={customerInfoObj}
              setCustomerInfoObj={setCustomerInfoObj}
              sendsms={sendsms}
              showConversations={chatView ? true : showConversations}
              setShowConversations={setShowConversations}
              showDescription={showDescription}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ConversationView;

export const DetailView = React.memo(
  ({
    isChannelPresent,
    services,
    handleNumberSelect,
    toNumber,
    setNumbersList,
    numberList,
    omni,
    from,
    selectedContact,
    serviceList,
    customerInfoObj,
    setCustomerInfoObj,
    channel,
    associatedIntegID,
    chatView,
    setHelloSend,
    sendsms,
    contactId,
    showConversations,
    setShowConversations,
    showDescription,
  }) => {
    const [appProperties, setAppProperties] = useContext(AppContext);
    const [searchContact, setSearchContact] = useState("");
    const [contactList, setContactList] = useState([]);
    const { fetchData } = useHttp();
    const scrollableDivRef = useRef(null);
    const messageBodyRef = useRef(null);
    const navigate = useNavigate();
    const [conversations, setConversations] = useState([]);
    const [unReadMessages, setUnReadMessages] = useState([]);
    const [showConversationsChat, setShowConversationsChat] = useState(false);
    const [newFromNumber, setNewFromNumber] = useState();
    const [integId, setIntegId] = useState();
    const [savedNumbersList, setSavedNumbersList] = useState();
    const [showChatView, setShowChatView] = useState(false);
    const [fromNumberFlag, setFromNumberFlag] = useState(false);
    const [searchDropdownLoading, setSearchDropdownLoading] = useState(false);
    const [showAddBtn, setShowAddBtn] = useState(false);
    const [notFoundContent, setNotFoundContent] = useState(null);
    const [toNumberChatView, setToNumberChatView] = useState();
    const [contactInfo, setContactInfo] = useState(null);
    var urlParams = new URLSearchParams(window.location.search);
    const [debouncedSearchContact] = useDebounce(searchContact, 400);
    const balanceCredits =
      appProperties?.licenseObj?.licenseDetails?.creditsPurchased - appProperties?.licenseObj?.licenseDetails?.creditsConsumed;

    async function fetchByList(integ, fromFlag) {
      await fetchData(
        !showChatView
          ? "channels/" +
              (integ || associatedIntegID) +
              "/conversations/all?orgMember=" +
              encodeURIComponent(fromFlag ? from : newFromNumber) +
              "&orgCustomer=" +
              encodeURIComponent(chatView ? toNumberChatView : toNumber)
          : "channels/" +
              (integ || associatedIntegID) +
              "/conversationsList/" +
              encodeURIComponent(chatView ? toNumberChatView : toNumber) +
              "?channelName=" +
              encodeURIComponent(fromFlag ? from : newFromNumber) +
              "&contactId=" +
              contactId?.current,
        "GET",
        null,
        appProperties
      )
        .then(function (response) {
          try {
            if (response) {
              response = JSON.parse(response);
              let convs = response?.data?.data?.messages;
              let unreads = [];
              let reads = [];

              convs.forEach((conv, index) => {
                if (index < convs?.length - (customerInfoObj?.unreadCount ?? unReadMessages?.length ?? 0)) {
                  reads.push(conv);
                } else {
                  if (conv?.direction === 1) {
                    unreads.push({
                      ...conv,
                      isUnreadedMessage: true,
                    });
                  } else {
                    unreads = [];
                    reads.push(conv);
                  }
                }
              });

              let contact = response?.data?.data?.participants?.find((obj) => obj?.role === "source_user");
              setContactInfo(contact);
              if (contact && chatView) {
                contactId.current = contact?.contactId;
              }
              setConversations(reads);
              setUnReadMessages(unreads);
              setShowConversationsChat(true);
              setShowConversations(true);
              if (!chatView) {
                messageBodyRef.current.focus();
              }
            }
          } catch (error) {
            console.error("Error processing response:", error);
            setConversations([]);
            setUnReadMessages([]);
            setShowConversationsChat(true);
            setShowConversations(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setShowConversationsChat(true);
          setShowConversations(true);
        });
    }
    useEffect(() => {
      const fetchApi = async () => {
        setShowConversationsChat(false);
        let savedNumbers;
        if (!savedNumbersList) {
          var phoneObj = appProperties?.savedNumbers?.filter((obj) => obj?.isMessagingService !== "true");
          var msgObj = appProperties?.savedNumbers?.filter((obj) => obj?.isMessagingService === "true");
          if (msgObj.length > 0) {
            phoneObj = [
              ...(phoneObj || []),
              {
                label: (
                  <Divider plain style={{ margin: 0, fontSize: "12px" }} orientation="left">
                    Message Service ID
                  </Divider>
                ),
                value: "divider",
                disabled: true,
              },
              ...msgObj,
            ];
          }
          setSavedNumbersList(phoneObj);
          savedNumbers = phoneObj;
          if (chatView) {
            const defaultPhoneNumber =
              phoneObj.find((obj) => obj?.defaultNumber === 1)?.phoneNumber ||
              (phoneObj[0]?.value === "divider" ? phoneObj[1]?.phoneNumber : phoneObj[0]?.phoneNumber);
            setNewFromNumber(defaultPhoneNumber);
          }
        }
        let list = savedNumbers || savedNumbersList;
        var integ;

        if (customerInfoObj?.displayName?.startsWith("Facebook") || customerInfoObj?.displayName?.startsWith("Instagram")) {
          integ = customerInfoObj?.integId;
          setIntegId(integ);
        } else {
          integ = list?.find((entry) => entry.phoneNumber === from)?.integId;
        }
        let fromFlag;
        if (!integ) {
          integ = list?.find((entry) => entry.phoneNumber === newFromNumber)?.integId;
          if (integ) {
            fromFlag = false;
            setFromNumberFlag(false);
          } else {
            fromFlag = true;
          }
        } else {
          setFromNumberFlag(true);
          fromFlag = true;
        }
        if (!from || !integ) {
          setConversations([]);
          setUnReadMessages([]);
        }
        setIntegId(integ);
        if ((toNumberChatView || toNumber) && (from || newFromNumber)) {
          setShowConversationsChat(false);
          setIntegId(integ);
          await fetchByList(integ, fromFlag, false);
        } else {
          setShowConversationsChat(true);
        }
      };
      if (appProperties && appProperties.apps) {
        fetchApi();
      }
      if (setHelloSend) {
        setHelloSend(savedNumbersList?.find((obj) => obj?.phoneNumber === newFromNumber)?.isHelloSendPhoneNumber === "true" ? true : false);
      }
      return () => {};
    }, [
      fetchData,
      chatView,
      associatedIntegID,
      showChatView,
      savedNumbersList,
      setHelloSend,
      appProperties.serviceInstalled,
      from,
      toNumber,
      toNumberChatView,
      newFromNumber,
    ]);

    useEffect(() => {
      if (scrollableDivRef.current) {
        const scrollableDiv = scrollableDivRef.current;
        scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
      }
    }, [conversations]);

    useEffect(() => {
      if (debouncedSearchContact?.length > 2 && appProperties.serviceInstalled) {
        setSearchDropdownLoading(<div className="actionIconsSprite search-loading"></div>);
        fetchData(
          "omessage/" + appProperties?.randomIntegId + "/search?searchText=" + encodeURIComponent(debouncedSearchContact) + "&module=ALL",
          "GET",
          null,
          appProperties
        )
          .then(function (response) {
            try {
              if (response) {
                response = JSON.parse(response);
                if (response?.data?.data) {
                  let data = response?.data?.data;
                  setContactList(data);
                  if (data.length > 0) {
                    setShowAddBtn(false);
                    setNotFoundContent(null);
                  } else {
                    setShowAddBtn(true);
                    setNotFoundContent(
                      <Empty
                        image={
                          <div className=" mt-2 d-flex flex-column align-items-center justify-content-center w-100">
                            <div style={{ color: "#8B8D97" }} className="staticIconsSprite search-empty-icon"></div>
                            No matches found.
                          </div>
                        }
                        description={null}
                      />
                    );
                  }
                }
                setSearchDropdownLoading(null);
              }
            } catch (error) {
              console.error("Error processing response:", error);
              setSearchDropdownLoading(null);
            }
          })
          .catch((error) => {
            console.log("Error processing response:", error);
            setSearchDropdownLoading(null);
          });
      } else {
        setNotFoundContent(null);
      }
    }, [debouncedSearchContact, appProperties.serviceInstalled, fetchData, appProperties]);

    const handleSelectContact = (e) => {
      let contact = contactList?.filter((obj) => obj?.lastName === e);
      let queryObj = numberList ?? numberList?.find((obj) => obj?.orgCustomer === contact[0]?.phone);
      handleNumberSelect(contact[0]?.lastName, {
        fromNumber: queryObj?.orgMember,
        toNumber: contact[0]?.phone,
        integId: queryObj?.integId,
        new: true,
      });
    };
    const updateUnreadMessages = useCallback(() => {
      if (unReadMessages?.length > 0) {
        setConversations((prev) => {
          const arr = [...(prev || []), ...(unReadMessages || [])];
          return arr;
        });
        setNumbersList((prev) => {
          const newNumbersList = prev.map((item) => {
            if (item?.orgMember === selectedContact?.fromNumber && item?.orgCustomer === selectedContact?.toNumber) {
              return {
                ...item,
                unreadCount: 0,
              };
            } else {
              return item;
            }
          });
          return newNumbersList;
        });
        setUnReadMessages([]);
        setCustomerInfoObj((prev) => ({ ...prev, unreadCount: 0 }));
      }
    }, [setCustomerInfoObj, unReadMessages]);

    const handleSendSmsResp = (response, newUUID, idObject) => {
      var newContact;
      if (response.data.status === true) {
        const updatedList = numberList?.map((item) => {
          if (
            item.contactId === response.data?.contact?.contactId ||
            (item.orgCustomer === idObject.id && item.orgMember === idObject.sender_id) ||
            (item.orgCustomer === idObject.id && item.new)
          ) {
            if ((item.orgCustomer === idObject.id && item.new) || conversations.length <= 1) {
              contactId.current = response.data?.contact?.contactId;
              newContact = true;
            }
            return response.data?.contact;
          } else {
            return item;
          }
        });
        if (response.data?.contact?.contactId === contactId?.current || newContact || chatView) {
          setConversations((prev) => {
            var existingIndex = prev.findIndex((item) => item.tempMessageId === newUUID);
            if (!existingIndex) {
              prev.findIndex((item) => item.id === response?.data?.messageId);
            }
            if (existingIndex !== -1) {
              const newList = [...prev];
              let messageData = {
                message: newList[existingIndex]?.message,
                id: response.data?.messageId,
                reply_by: response.data?.reply_by,
                sender_url: response.data?.sender_url,
                createdTime: newList[existingIndex]?.createdTime,
                attachments: newList[existingIndex]?.attachments,
                direction: 2,
                messageStatus: "sent",
              };
              newList[existingIndex] = messageData;
              return newList;
            } else {
              return [...prev, response?.data];
            }
          });
        }
        setNumbersList(updatedList);
      } else {
        if (response?.data) {
          if (response?.data?.license?.code) {
            response.data.data = response?.data?.license?.description;
            response.data.errorCode = response?.data?.license?.code;
          }
          setConversations((prev) => {
            var existingIndex = prev.findIndex((item) => item.tempMessageId === newUUID);
            if (!existingIndex) {
              prev.findIndex((item) => item.id === response?.data?.messageId);
            }
            if (existingIndex !== -1) {
              const newList = [...prev];
              let messageData = {
                ...newList?.[existingIndex],
                direction: 2,
                errorMessage: response?.data?.data,
                errorCode: response?.data?.errorCode,
                messageStatus: "failed",
              };
              newList[existingIndex] = messageData;
              return newList;
            }
          });
          const updatedList = numberList?.map((item) => {
            if (
              item?.contactId === response.data?.contact?.contactId ||
              (item?.orgCustomer === idObject.id && item?.orgMember === idObject.sender_id) ||
              (item?.orgCustomer === idObject.id && item?.new)
            ) {
              contactId.current = response.data?.contact?.contactId;
              return response.data?.contact || item;
            } else {
              return item;
            }
          });
          setNumbersList(updatedList);
        }
      }
      if (response.data?.omniLicense) {
        setAppProperties((prev) => ({
          ...prev,
          licenseObj: {
            ...prev.licenseObj,
            licenseDetails: response.data?.omniLicense,
          },
        }));
      }
    };

    return (
      <div
        className={`bg-white d-flex flex-column rounded-4 ${chatView ? "" : "m-2 ms-3 me-3"}`}
        style={{
          height: urlParams.get("sideBar") === "false" ? "95.2vh" : showDescription ? "75vh" : "88vh",
        }}
      >
        {showConversations ? (
          <>
            {isChannelPresent || !omni || chatView ? (
              <>
                {chatView ? (
                  <ChatViewHeader
                    appProperties={appProperties}
                    newFromNumber={newFromNumber}
                    setNewFromNumber={setNewFromNumber}
                    savedNumbersList={savedNumbersList}
                    setSavedNumbersList={setSavedNumbersList}
                    setToNumberChatView={setToNumberChatView}
                  />
                ) : (
                  <ConversationHeader
                    savedNumbersList={savedNumbersList}
                    customerInfoObj={customerInfoObj}
                    toNumber={toNumber}
                    showChatView={showChatView}
                    setShowChatView={setShowChatView}
                    newFromNumber={newFromNumber}
                    from={from}
                  />
                )}
                {showConversationsChat ? (
                  <>
                    {conversations?.length > 0 || unReadMessages?.length > 0 ? (
                      <ConversationMessages
                        sendsms={sendsms}
                        updateUnreadMessages={updateUnreadMessages}
                        conversations={conversations}
                        setConversations={setConversations}
                        unReadMessages={unReadMessages}
                        customerInfoObj={contactInfo || customerInfoObj}
                        scrollableDivRef={scrollableDivRef}
                        integId={integId}
                        appProperties={appProperties}
                        handleSendSmsResp={handleSendSmsResp}
                        toNumberChatView={toNumberChatView}
                        toNumber={toNumber}
                        balanceCredits={balanceCredits}
                        chatView={chatView}
                      />
                    ) : (
                      <NoConversations urlParams={urlParams} navigate={navigate} chatView={chatView} appProperties={appProperties} />
                    )}
                  </>
                ) : (
                  <Skeleton className="p-3 mt-auto" active size="large" paragraph={{ rows: chatView ? 8 : 14 }} />
                )}
              </>
            ) : (
              <>
                <div className="container d-flex align-items-center m-3 mb-0">
                  To:
                  <Select
                    suffixIcon={
                      <div className="d-flex">
                        <span>{searchDropdownLoading}</span>
                      </div>
                    }
                    tabIndex={3}
                    notFoundContent={notFoundContent}
                    showSearch
                    onSelect={handleSelectContact}
                    onSearch={(e) => {
                      setSearchContact(e);
                    }}
                    onBlur={() => {
                      setContactList([]);
                    }}
                    filterOption={false}
                    bordered={false}
                    style={{ minWidth: 250 }}
                    placeholder={`Enter a name or phone number to search from ${appProperties?.leftServiceDisplayName}`}
                  >
                    {contactList && (
                      <>
                        {Array.from(new Set(contactList.map((obj) => obj?.module))).map((module) => (
                          <Select.OptGroup
                            key={module}
                            label={
                              <Tag
                                color="#F5F4FF"
                                style={{
                                  color: "#605bff",
                                  borderRadius: 12,
                                  textTransform: "capitalize",
                                }}
                              >
                                {module}
                              </Tag>
                            }
                          >
                            {contactList
                              .filter((obj) => obj?.module === module)
                              .map((obj, index) => (
                                <Select.Option key={`${module}-${index}`} value={obj?.lastName}>
                                  {obj?.lastName}
                                </Select.Option>
                              ))}
                          </Select.OptGroup>
                        ))}
                      </>
                    )}
                  </Select>
                  {showAddBtn && debouncedSearchContact && /^\+?\d+$/.test(debouncedSearchContact) && (
                    <Button
                      type="primary"
                      icon={<div className="actionIconsSprite plusIconWhite" />}
                      className="d-flex justify-content-between"
                      style={{ backgroundColor: "#605BFF" }}
                      onClick={() => {
                        if (debouncedSearchContact && debouncedSearchContact !== "") {
                          handleNumberSelect(null, {
                            fromNumber: null,
                            toNumber: debouncedSearchContact,
                            new: true,
                          });
                          if (messageBodyRef.current) {
                            messageBodyRef.current.focus();
                          }
                        }
                      }}
                    >
                      Add
                    </Button>
                  )}
                </div>
                <Divider />
                <div className="d-flex flex-grow-* flex-column align-items-center justify-content-center h-100">
                  <div className="staticIconsSprite chatIcon-sprite"></div>
                  {!appProperties?.serviceInstalled ? (
                    <div className="d-flex flex-column align-items-center noConvo-info">
                      <span
                        style={{
                          fontSize: chatView ? 14 : 16,
                          color: "#818094",
                        }}
                      >
                        Stay connected with your customers.{" "}
                      </span>
                      <span
                        style={{
                          fontSize: chatView ? 12 : 14,
                          color: "#818094",
                        }}
                      >
                        Connect your channel and begin typing a contact name or number above to initiate a new message.{" "}
                      </span>
                    </div>
                  ) : (
                    <div className="d-flex flex-column align-items-center noConvo-info">
                      <span
                        style={{
                          fontSize: chatView ? 14 : 16,
                          color: "#818094",
                        }}
                      >
                        Stay connected with your customers.{" "}
                      </span>
                      {!chatView && (
                        <span
                          style={{
                            fontSize: chatView ? 12 : 14,
                            color: "#818094",
                          }}
                        >
                          Begin typing a contact name or number above to initiate a new message.
                        </span>
                      )}
                    </div>
                  )}
                  {!appProperties?.serviceInstalled ? (
                    <Button
                      className="rounded m-2 addChannel-btn"
                      onClick={() => {
                        navigate("/channels/textsms?" + urlParams);
                      }}
                      type=""
                    >
                      Add Channels
                    </Button>
                  ) : null}
                </div>
              </>
            )}
            {(services?.length > 0 && showConversations && toNumber) || chatView || !omni ? (
              <MessageArea
                handleSendSmsResp={handleSendSmsResp}
                appProperties={appProperties}
                unReadMessages={unReadMessages}
                customerInfoObj={customerInfoObj}
                updateUnreadMessages={updateUnreadMessages}
                serviceList={serviceList}
                toNumberChatView={toNumberChatView}
                integId={integId}
                messageBodyRef={messageBodyRef}
                contactList={contactList}
                conversations={conversations}
                numberList={numberList}
                fromNumberFlag={fromNumberFlag}
                from={from}
                setConversations={setConversations}
                toNumber={toNumber}
                setNumbersList={setNumbersList}
                newFromNumber={newFromNumber}
                setNewFromNumber={setNewFromNumber}
                chatView={chatView}
                savedNumbersList={savedNumbersList}
                setSavedNumbersList={setSavedNumbersList}
                sendsms={sendsms}
                channel={channel}
                contactId={contactId}
                balanceCredits={balanceCredits}
              />
            ) : null}
          </>
        ) : (
          <Skeleton className="p-4" active size="small" paragraph={{ rows: 12 }} />
        )}
      </div>
    );
  }
);
const MessageArea = ({
  integId,
  messageBodyRef,
  appProperties,
  chatView,
  from,
  serviceList,
  customerInfoObj,
  conversations,
  setNumbersList,
  numberList,
  fromNumberFlag,
  setConversations,
  contactList,
  savedNumbersList,
  setSavedNumbersList,
  toNumber,
  sendsms,
  newFromNumber,
  updateUnreadMessages,
  toNumberChatView,
  setNewFromNumber,
  unReadMessages,
  channel,
  contactId,
  handleSendSmsResp,
  balanceCredits,
}) => {
  const { fetchData } = useHttp();
  const [message, setMessage] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [fileList, setFileList] = useState([]);
  const [sendBtnLoading, setSendBtnLoading] = useState(false);
  const [showMMS, setShowMMS] = useState(true);
  const [messageAreaLoading, setMessageAreaLoading] = useState(false);
  var urlParams = new URLSearchParams(window.location.search);
  var resource = urlParams.get("resource");
  const antIcon = <LoadingOutlined className="loading-button" spin />;
  function handleMessage(messageText) {
    setMessage(messageText);
    handlePusherCall(messageText);
  }

  function handleMedia(mediaFile) {
    setFileList(mediaFile ? mapToMediaList(mediaFile, false) : []);
  }

  const [pusherChannel, setPusherChannel] = useState(null);

  const handlePusherCall = (e) => {
    if (chatView && !pusherChannel) {
      initPusher(fetchData, appProperties).then((res) => {
        setPusherChannel(res);
      });
    }
  };

  useEffect(() => {
    const globalChannel = chatView ? pusherChannel : { channel: channel };
    if (globalChannel) {
      globalChannel?.channel?.bind("CONVERSATION_UPDATE", (data) => {
        if (data) {
          try {
            let response = JSON.parse(data?.data);
            console.log(">> CONVERSATION_UPDATE >>>>>", response);
            if (
              response?.contactId === contactId.current ||
              ([from, newFromNumber, toNumberChatView, toNumberChatView?.replace(/[\s()-]/g, "")].includes(response?.sender_id) &&
                [from, newFromNumber, toNumberChatView, toNumberChatView?.replace(/[\s()-]/g, "")].includes(response?.receiver_id))
            ) {
              if (response?.messageStatus === "failed") {
                response.class = "input_error";
              }
              const payload = {
                contactId: contactId.current,
                orgMember: fromNumberFlag ? from : newFromNumber,
                orgCustomer: chatView ? toNumberChatView : toNumber,
              };
              if (response.direction === 1) {
                fetchData("clearUnread", "PUT", payload, appProperties);
                // playNotificationSound();
              }
              if (unReadMessages?.length > 0) {
                updateUnreadMessages();
              }
              setConversations((prev) => {
                const existingIndex = prev.findIndex((item) => item.id === response?.messageId);
                if (existingIndex !== -1) {
                  const newList = [...prev];
                  newList[existingIndex] = {
                    ...newList[existingIndex],
                    ...response,
                  };
                  return newList;
                } else {
                  return [...(prev || []), response];
                }
              });
            }
            setNumbersList((prev) => {
              const existingIndex = prev?.findIndex((item) => item?.contactId === response?.contactId);
              if (existingIndex !== -1) {
                const newList = [...prev];
                newList[existingIndex] = {
                  ...newList[existingIndex],
                  messageTagStatus: response?.messageStatus,
                };
                return newList;
              } else {
                return [...(prev || [])];
              }
            });
          } catch (e) {
            console.error("ERROR::::", e);
          }
        }
      });
      return () => {
        globalChannel?.channel?.unbind("CONVERSATION_UPDATE");
        if (globalChannel.dispose) {
          globalChannel?.dispose();
        }
      };
    }
  }, [pusherChannel, channel, toNumberChatView, from, newFromNumber, fromNumberFlag, unReadMessages]);

  const handleSendsms = async () => {
    updateUnreadMessages();
    let newUUID = crypto.randomUUID();
    if (!(fromNumberFlag ? from : newFromNumber)) {
      failureNotification("From Number is required !");
    } else if (!toNumber && !toNumberChatView) {
      failureNotification("To Number is required !");
    } else if (message === "" && fileList?.length === 0) {
      failureNotification("Message Body is required !");
    } else {
      setSendBtnLoading(true);
      let associatedObjectId = null;
      let associatedObjectType = null;
      if (chatView) {
        associatedObjectId = await appProperties?.controller?.getAssociatedObjectId();
        associatedObjectType = await appProperties?.controller?.getAssociatedObjectType();
      } else {
        associatedObjectId = contactList.filter((obj) => obj?.phone === toNumber)?.map((val) => val.id)?.[0];
        associatedObjectType = contactList.filter((obj) => obj?.phone === toNumber)?.map((val) => val.module)?.[0];
      }
      const mediaFileList = fileList
        ? fileList.map((file) => {
            return {
              name: file.name,
              url: file.url,
              contentType: file.type,
              size: file.size,
            };
          })
        : [];
      var body = {
        from: fromNumberFlag ? from : newFromNumber,
        pageId: fromNumberFlag ? from : newFromNumber,
        to: chatView ? toNumberChatView : toNumber,
        messageBody: message,
        mediaFileList: mediaFileList,
        userId: urlParams.get("userId"),
        associatedObjectId: associatedObjectId,
        associatedObjectType: associatedObjectType,
        templateId: templateId,
      };
      let rightPhoneObj = appProperties.installedApps?.find((obj) => obj?.integProps?.integId === integId);

      if (rightPhoneObj?.right?.auth?.authorized) {
        setMessage("");
        setFileList([]);
        var idObject = {
          sender_id: fromNumberFlag ? from : newFromNumber,
          id: toNumber,
          class: "input-error",
          tempMessageId: newUUID,
          messageStatus:
            rightPhoneObj?.right?.service_name !== "hello_send" || (rightPhoneObj?.right?.service_name === "hello_send" && balanceCredits > 0.5)
              ? "queued"
              : "failed",
          errorMessage:
            rightPhoneObj?.right?.service_name === "hello_send" && balanceCredits < 0.5 ? (
              <span>
                Message sending failed due to low credits. Please
                <BuyFunds />.
              </span>
            ) : null,
          direction: 2,
          message: message,
          attachments: fileList?.length > 0 ? fileList : null,
          createdTime: new Date().toISOString(),
        };
        const updatedList = numberList?.map((item) => {
          if ((item.orgCustomer === idObject.id && item.orgMember === idObject.sender_id) || (item.orgCustomer === idObject.id && item.new)) {
            return {
              ...item,
              orgMember: fromNumberFlag ? from : newFromNumber,
              sourceFrom: rightPhoneObj?.right?.service_display_name,
              messageTag: message,
              messageTagStatus:
                rightPhoneObj?.right?.service_name !== "hello_send" ||
                rightPhoneObj?.right?.service_name === "hello_send" ||
                balanceCredits > 0.5
                  ? "queued"
                  : "failed",

              messageTagType: fileList[fileList?.length - 1]?.type,
              messageTime: dayjs().format("YYYY-MM-DD HH:mm"),
            };
          } else {
            return item;
          }
        });
        setNumbersList(updatedList);
        setConversations([...conversations, idObject]);
        setSendBtnLoading(false);
        if (
          rightPhoneObj?.right?.service_name !== "hello_send" ||
          (rightPhoneObj?.right?.service_name === "hello_send" && balanceCredits > 0.5)
        ) {
          sendsms(integId, body)
            .then(function (response) {
              try {
                if (response) {
                  response = JSON.parse(response);
                  handleSendSmsResp(response, newUUID, idObject);
                  setSendBtnLoading(false);
                }
              } catch (error) {
                console.error("Error processing response:", error);
              }
            })
            .catch((error) => {
              setSendBtnLoading(false);
              setConversations((prev) => {
                var existingIndex = prev.findIndex((item) => item.tempMessageId === newUUID);
                if (existingIndex !== -1) {
                  const newList = [...prev];
                  let messageData = {
                    message: newList[existingIndex]?.message,
                    createdTime: newList[existingIndex]?.createdTime,
                    attachments: newList[existingIndex]?.attachments,
                    direction: 2,
                    class: "input-error",
                    messageStatus: "failed",
                  };
                  newList[existingIndex] = messageData;
                  return newList;
                }
              });
            });
        }
      } else {
        if (rightPhoneObj?.right) {
          openServiceNotAuthorizedModal(rightPhoneObj?.right);
        }
        setSendBtnLoading(false);
      }
    }
  };
  useEffect(() => {
    let obj = serviceList?.find((obj) => obj?.serviceInfo?.displayName === customerInfoObj?.displayName);
    let rightServiceName = obj?.serviceInfo?.name;
    if (newFromNumber && savedNumbersList?.length > 0) {
      let numberObj = savedNumbersList?.find((obj) => obj?.phoneNumber === newFromNumber);
      rightServiceName = appProperties.installedApps.find((obj) => obj?.integProps?.integId === numberObj?.integId)?.right?.service_name;
    }
    if (rightServiceName) {
      let assignerObj1 = Object.assign(ProviderCommonObj, ProviderExtendedCommonObj[rightServiceName]);
      appProperties.providerCommonObj = assignerObj1;
      if (appProperties?.providerCommonObj && appProperties.providerCommonObj["isMMSSupported"]) {
        setShowMMS(true);
      } else {
        setShowMMS(false);
      }
    } else {
      setShowMMS(false);
    }
  }, [customerInfoObj, newFromNumber, appProperties, savedNumbersList, serviceList]);

  const handleFromNumberChange = (e) => {
    setNewFromNumber(e);
  };
  useEffect(() => {
    messageBodyRef?.current?.focus();
  }, [templateId]);
  return (
    <div
      style={{
        background: "white",
        padding: chatView ? "8px 6px 8px 0px" : "12px 10px",
      }}
      className="flex-shrink-1 rounded-4"
    >
      {showMMS && fileList?.length > 0 && (
        <Row>
          <div className="d-flex align-items-end w-100" style={{ minHeight: "48px" }}>
            <ReusableFilePreview
              fileList={fileList}
              setFileList={setFileList}
              shape={"circle"}
              direction={"horizontal"}
              customClasses={"ps-3"}
            />
          </div>
        </Row>
      )}
      <Row>
        <div className="w-100 rounded focus-border " style={{ background: "rgba(250, 250, 251, 1)" }}>
          <Spin spinning={messageAreaLoading}>
            <TextArea
              bordered={false}
              allowClear
              autoFocus
              style={{
                resize: "none",
                transition: "none",
                minHeight: chatView ? 70 : 100,
              }}
              placeholder="Enter message"
              value={message}
              onChange={(e) => {
                handleMessage(e.target.value);
                if (unReadMessages.length > 0) {
                  updateUnreadMessages();
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSendsms();
                }
              }}
              ref={messageBodyRef}
              className="conv-textArea"
            />
          </Spin>
          <div className="d-flex gap-2 p-2 ">
            <div className="d-flex align-items-center">
              <MediaUploadIconWrapper fileList={fileList} setFileList={setFileList} showMMS={showMMS} integId={integId} />
              <div className="ms-3">
                {chatView && (
                  <SavedTemplatesSelect
                    setMessageAreaLoading={setMessageAreaLoading}
                    setTemplateId={setTemplateId}
                    setLoading={null}
                    handleMessage={handleMessage}
                    handleMedia={handleMedia}
                    loadTemplates={true}
                    moduleName={resource}
                    showPreview={true}
                  />
                )}
              </div>
            </div>
            <div className="d-flex ms-auto align-items-center">
              {((conversations?.length < 1 && unReadMessages?.length < 1) || !integId) && !chatView && (
                <FromNumberComponent
                  customClasses={"fromNumber-conv"}
                  fromNumber={newFromNumber}
                  handleFromNumberChange={handleFromNumberChange}
                  savedNumbersList={savedNumbersList}
                  setSavedNumbersList={setSavedNumbersList}
                  disabled={false}
                />
              )}
              <div onClick={() => handleSendsms()} className="me-3 d-flex align-items-center justify-content-center sendSMSIconWrapper">
                {sendBtnLoading ? <Spin indicator={antIcon} /> : <div className="actionIconsSprite sendSMSIcon h-100 w-100"></div>}
              </div>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};

const ChatViewHeader = (props) => {
  const { appProperties, newFromNumber, setNewFromNumber, setSavedNumbersList, savedNumbersList, setToNumberChatView } = props;
  const [ttoNumber, setToNumber] = useState(appProperties?.phone);
  const handleFromNumberChange = (e) => {
    setNewFromNumber(e);
  };
  const [debouncedToNumber] = useDebounce(ttoNumber, 500);
  useEffect(() => {
    setToNumberChatView(debouncedToNumber);
  }, [debouncedToNumber]);

  useEffect(() => {
    setToNumber(appProperties.phone);
  }, [appProperties.phone]);
  return (
    <div>
      <div className="d-flex align-items-center container">
        <label>From</label>
        <div className="ms-2 w-100">
          <FromNumberComponent
            fromNumber={newFromNumber}
            handleFromNumberChange={handleFromNumberChange}
            savedNumbersList={savedNumbersList}
            setSavedNumbersList={setSavedNumbersList}
            disabled={false}
            defaultSelectFirstOption={true}
          />
        </div>
      </div>
      <Divider className="m-1" />
      <div className="d-flex align-items-center container">
        <label>To</label>
        <div className="d-flex align-items-center justify-content-between w-100">
          <Input
            bordered={false}
            className="ms-4 ps-3"
            value={ttoNumber}
            onChange={(e) => {
              setToNumber(e.target.value);
            }}
          />
          <ConfigurePhone />
        </div>
      </div>
      <Divider className="m-1" />
    </div>
  );
};
const ConversationHeader = ({ customerInfoObj, toNumber, setShowChatView, showChatView, savedNumbersList, newFromNumber, from }) => {
  const [showSwitch, setShowSwitch] = useState(true);
  const historySupportedArr = ["Twilio", "RingCentral", "Hello Send", "WhatsApp", "Facebook"];
  useEffect(() => {
    if (from && !historySupportedArr.includes(customerInfoObj?.displayName)) {
      setShowChatView(false);
    }
    let integ = savedNumbersList?.find((entry) => entry.phoneNumber === from)?.integId;
    let findNumber = savedNumbersList?.find((obj) => (obj?.phoneNumber === integ ? from : newFromNumber));
    if (findNumber?.isMessagingService === "true" || findNumber?.isAlphaSender === "true") {
      setShowSwitch(false);
    }
  }, [savedNumbersList, newFromNumber, from]);

  return (
    <>
      <div className="d-flex align-items-center rounded-4 conversationHeader">
        <Row className="w-100">
          <div className="ms-3 rounded d-flex align-items-center justify-content-center list-row-avatar">
            <div
              className={customerInfoObj ? customerInfoObj?.displayName?.split(" ")[0] + "-badgeIcon badgeSprites" : ""}
              style={{
                background: customerInfoObj?.displayName === undefined ? "#F7F7FC" : "",
              }}
            ></div>
          </div>
          <Col className="align-self-center flex-grow-1 d-flex justify-content-between align-items-center m-1 ms-3">
            <span className="d-flex flex-column">
              <span style={{ fontSize: 16 }}>{customerInfoObj?.firstName}</span>
              <span
                style={{
                  cursor: "default",
                  fontSize: customerInfoObj?.firstName ? 12 : 16,
                }}
              >
                {toNumber}
              </span>
            </span>
            {historySupportedArr.includes(customerInfoObj?.displayName) && showSwitch && (
              <div className="d-flex align-items-center">
                <span style={{ cursor: "default" }}>Show All</span>
                <Switch
                  className="addPhoneSwitch ms-2 me-5"
                  value={showChatView}
                  onChange={(e) => {
                    setShowChatView(e);
                  }}
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
const ConversationMessages = React.memo(
  ({
    customerInfoObj,
    conversations,
    setConversations,
    scrollableDivRef,
    updateUnreadMessages,
    unReadMessages,
    integId,
    sendsms,
    appProperties,
    handleSendSmsResp,
    toNumber,
    toNumberChatView,
    balanceCredits,
    chatView,
  }) => {
    const handleRetrySendsms = (obj) => {
      let rightPhoneObj = appProperties.installedApps?.find((obj) => obj?.integProps?.integId === integId);
      if (rightPhoneObj?.right?.auth?.authorized) {
        let newUUID = crypto.randomUUID();
        var idObject = {
          ...obj,
          createdTime: new Date().toISOString(),
          messageStatus:
            rightPhoneObj?.right?.service_name !== "hello_send" || (rightPhoneObj?.right?.service_name === "hello_send" && balanceCredits > 0.5)
              ? "queued"
              : "failed",
          errorMessage:
            rightPhoneObj?.right?.service_name === "hello_send" && balanceCredits < 0.5 ? (
              <span>
                Message sending failed due to low credits. Please
                <BuyFunds />.
              </span>
            ) : null,
          tempMessageId: newUUID,
        };
        setConversations([...conversations, idObject]);

        const mediaFileList = obj.attachments
          ? obj.attachments.map((file) => {
              return {
                name: file.name,
                url: file.url,
                contentType: file.type,
                size: file.size,
              };
            })
          : [];
        const body = {
          from: obj.sender_id,
          to: chatView ? toNumberChatView : toNumber,
          message: obj.message,
          pageId: obj.sender_id,
          tempMessageId: newUUID,
          messageBody: obj.message,
          mediaFileList: mediaFileList,
        };
        if (
          rightPhoneObj?.right?.service_name !== "hello_send" ||
          (rightPhoneObj?.right?.service_name === "hello_send" && balanceCredits > 0.5)
        ) {
          sendsms(integId, body)
            .then(function (response) {
              if (response) {
                response = JSON.parse(response);
                handleSendSmsResp(response, newUUID, idObject);
              }
            })
            .catch((err) => {
              setConversations((prev) => {
                var existingIndex = prev.findIndex((item) => item.tempMessageId === newUUID);
                if (existingIndex !== -1) {
                  const newList = [...prev];
                  let messageData = {
                    ...idObject,
                    class: "input-error",
                    messageStatus: "failed",
                  };
                  newList[existingIndex] = messageData;
                  return newList;
                }
              });
            });
        }
      } else {
        if (rightPhoneObj?.right) {
          failureNotificationWithBtn(
            <span className="text-nowrap">
              {`${rightPhoneObj?.right?.service_display_name} service is not
              authorized !`}
            </span>,
            "Authorize",
            `/channels/textsms/${rightPhoneObj?.right?.service_name}?` + urlParams
          );
        }
      }
    };

    function mapMessages(data, isUnreadMessage) {
      return (
        <>
          {data.map((obj, index) => (
            <div key={index}>
              {obj?.isUnreadedMessage && isUnreadMessage && (
                <>
                  <div className="d-flex align-items-center justify-content-center">
                    {formatDate(obj?.createdTime) !== formatDate(conversations[conversations?.length - 1]?.createdTime) &&
                    formatDate(obj?.createdTime) !== formatDate(data[index - 1]?.createdTime) ? (
                      <div
                        className="p-1 rounded ps-2 pe-2 mb-1"
                        style={{
                          backgroundColor: "#F4F5FA",
                          fontSize: 12,
                        }}
                      >
                        {formatDate(obj?.createdTime)}
                      </div>
                    ) : null}
                  </div>
                  {index === 0 && (
                    <div className="d-flex align-items-center justify-content-center">
                      <div
                        className="p-1 mt-1 rounded-5 ps-3 pe-3"
                        style={{
                          backgroundColor: "#F4F5FA",
                          fontSize: 15,
                        }}
                      >
                        {unReadMessages.length} Unread Messages
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className="d-flex align-items-center justify-content-center">
                {formatDate(obj?.createdTime) !== formatDate(data[index - 1]?.createdTime) && !isUnreadMessage ? (
                  <div
                    className="p-1 rounded ps-2 pe-2 mb-1"
                    style={{
                      backgroundColor: "#F4F5FA",
                      fontSize: 12,
                    }}
                  >
                    {formatDate(obj?.createdTime)}
                  </div>
                ) : null}
              </div>

              <div
                className={"d-flex flex-column p-1 m-auto"}
                style={{
                  alignItems: obj?.direction === 2 ? "end" : "start",
                }}
              >
                <div
                  className="d-flex justify-content-end align-items-end"
                  style={{
                    flexFlow: obj?.direction === 2 ? "row" : "row-reverse",
                    marginInlineStart: obj?.direction === 2 ? "30%" : 0,
                    marginInlineEnd: obj?.direction === 2 ? 0 : "30%",
                  }}
                >
                  <div className="d-flex flex-column align-items-end position-relative">
                    {obj?.attachments?.length > 0 ? (
                      <div>
                        {obj?.attachments.map((attachment, index) => (
                          <>
                            {(attachment.type?.startsWith("application/") || attachment.type?.startsWith("text/")) && (
                              <div className="h-100 w-100 mb-2">
                                <a
                                  key={index}
                                  href={attachment.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="pe-2 pdfAttch shadow-sm"
                                >
                                  <div className="d-flex align-items-center justify-content-start ">
                                    <span
                                      className={`${attachment.type === "application/pdf" ? "pdf-icon" : "doc-icon"} staticIconsSprite`}
                                    ></span>
                                    {attachment.name}
                                  </div>
                                </a>
                              </div>
                            )}
                            {attachment.type?.startsWith("audio/") && (
                              <div>
                                <audio
                                  key={index}
                                  src={attachment.url}
                                  controls
                                  className="mt-1"
                                  style={{
                                    borderRadius: 12,
                                  }}
                                />
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    ) : null}
                    {obj?.attachments?.length > 0 &&
                    obj?.attachments?.some((attachment) => attachment?.type?.startsWith("image/") || attachment?.type?.startsWith("video/")) ? (
                      <>
                        <div
                          className={`position-relative image-container ${
                            ["failed", "undelivered"].includes(obj?.messageStatus || obj?.status) && "image-failed"
                          }`}
                        >
                          <div className={obj.attachments.length > 1 ? "conv-attachments" : ""}>
                            {obj?.attachments.map((attachment, index) => (
                              <>
                                {attachment.type?.startsWith("image/") && (
                                  <div className="image-background">
                                    <Image
                                      key={index}
                                      src={attachment.url}
                                      preview={{
                                        mask: <div />,
                                        toolbarRender: () => null,
                                        maskClassName: "previewMask",
                                      }}
                                      width={obj.attachments.length === 1 && !chatView ? "22rem" : "11rem"}
                                      className="chatImage mt-1"
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = MediaError;
                                      }}
                                      style={{
                                        borderRadius: 12,
                                        margin: -1,
                                        padding: "0px 2px 4px 0px",
                                        minHeight: obj.attachments.length === 1 ? "16rem" : "11rem",
                                        height: obj.attachments.length === 1 ? "16rem" : "11rem",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </div>
                                )}
                                {attachment.type?.startsWith("video/") && (
                                  <div>
                                    <video
                                      key={index}
                                      src={attachment.url}
                                      controls
                                      width={obj.attachments.length === 1 && !chatView ? "22rem" : "11rem"}
                                      className="mt-1"
                                      style={{
                                        borderRadius: 12,
                                        margin: -1,
                                      }}
                                    />
                                  </div>
                                )}
                              </>
                            ))}
                          </div>
                          {obj?.message &&
                            obj?.attachments?.some(
                              (attachment) => attachment?.type?.startsWith("image/") || attachment?.type?.startsWith("video/")
                            ) && (
                              <pre
                                className={obj?.message && "p-1"}
                                style={{
                                  fontSize: 14,
                                  whiteSpace: "pre-wrap",
                                  wordBreak: "break-word",
                                  position: "relative",
                                  maxWidth: !chatView ? "22rem" : "11rem",
                                }}
                              >
                                {convertURLsToLinks(obj, true)}
                              </pre>
                            )}
                        </div>
                        {["failed", "undelivered"].includes(obj?.messageStatus || obj?.status) && (
                          <div
                            className="message-retry"
                            onClick={() => {
                              handleRetrySendsms(obj);
                            }}
                          >
                            <div className="actionIconsSprite message-retry-icon "></div>{" "}
                          </div>
                        )}
                      </>
                    ) : null}
                    {obj?.message &&
                      !obj?.attachments?.some(
                        (attachment) => attachment?.type?.startsWith("image/") || attachment?.type?.startsWith("video/")
                      ) && (
                        <div
                          className={`${
                            ["failed", "undelivered"].includes(obj?.messageStatus || obj?.status)
                              ? "failedMessage"
                              : obj?.direction === 2
                              ? "deliveredMessage"
                              : "receivedMessage"
                          }  ${obj?.class}`}
                        >
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            <pre
                              className={obj?.message && "p-2"}
                              style={{
                                fontSize: 14,
                                position: "relative",
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-word",
                              }}
                            >
                              {convertURLsToLinks(obj, false)}
                            </pre>
                            {["failed", "undelivered"].includes(obj?.messageStatus || obj?.status) && (
                              <div
                                className="message-retry"
                                onClick={() => {
                                  handleRetrySendsms(obj);
                                }}
                              >
                                <div className="actionIconsSprite message-retry-icon "></div>{" "}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                  </div>

                  <div
                    className="ms-2 me-2"
                    style={{
                      borderRadius: 8,
                      height: 30,
                      width: 30,
                      marginBottom: 1,
                      minWidth: 30,
                      background: !obj?.reply_by ? "" : obj?.reply_by === data[index + 1]?.reply_by ? "white" : "#EBF5ED",
                    }}
                  >
                    {obj?.direction === 2 ? (
                      <div
                        style={{
                          height: 30,
                          borderRadius: 8,
                          width: 30,
                          color: "#3A974C",
                          display: !obj?.reply_by ? "none" : obj?.reply_by === data[index + 1]?.reply_by ? "none" : "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {obj?.sender_url ? (
                          <Tooltip title={obj?.reply_by}>
                            <img height={30} width={30} alt="sender_url" src={obj?.sender_url} style={{ borderRadius: 4, objectFit: "cover" }} />
                          </Tooltip>
                        ) : (
                          <Tooltip title={obj?.reply_by}>{obj?.reply_by?.slice(0, 1)}</Tooltip>
                        )}
                      </div>
                    ) : (
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          height: 30,
                          borderRadius: 8,
                          width: 30,
                          color: "#FB784E",
                          fontSize: 18,
                          textTransform: "capitalize",
                          fontWeight: 700,
                          backgroundColor: customerInfoObj?.firstName?.slice(0, 1) && obj?.direction !== data[index + 1]?.direction && "#FFF2EE",
                        }}
                      >
                        {obj?.direction !== data[index + 1]?.direction && (
                          <span>
                            {customerInfoObj?.avatar || customerInfoObj?.avatar_url ? (
                              <Tooltip title={customerInfoObj?.firstName}>
                                <img
                                  height={30}
                                  width={30}
                                  alt="receiver_url"
                                  style={{
                                    borderRadius: 4,
                                    objectFit: "cover",
                                  }}
                                  src={customerInfoObj?.avatar || customerInfoObj?.avatar_url}
                                />
                              </Tooltip>
                            ) : (
                              <span> {customerInfoObj?.firstName?.slice(0, 1)}</span>
                            )}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <span
                  style={{
                    color: "#8B8D97",
                    fontSize: 9,
                    marginRight: obj?.direction === 2 ? 48 : "",
                    marginLeft: obj?.direction === 1 ? 48 : "",
                  }}
                  className="d-flex align-items-center"
                >
                  {formatTime(obj?.createdTime)}
                  {obj?.direction === 2 && (
                    <span className={`ps-1`}>
                      <Tooltip
                        title=<span style={{ textTransform: "capitalize" }}>
                          {obj?.errorCode ? <div>Error Code : {obj?.errorCode}</div> : <div> {obj?.messageStatus || obj?.status}</div>}
                          {obj?.errorMessage && <div>{obj?.errorMessage}</div>}
                        </span>
                      >
                        {(obj?.messageStatus || obj?.status) === "queued" ? (
                          <div className="actionIconsSprite queue-icon"></div>
                        ) : (obj?.messageStatus || obj?.status) === "sent" ? (
                          <div className="actionIconsSprite success-icon"></div>
                        ) : ["failed", "undelivered"].includes(obj?.messageStatus || obj?.status) ? (
                          <div className="actionIconsSprite failure-icon"></div>
                        ) : (obj?.messageStatus || obj?.status) === "delivered" ? (
                          <div className="actionIconsSprite delivered-icon "></div>
                        ) : null}
                      </Tooltip>
                    </span>
                  )}
                </span>
              </div>
            </div>
          ))}
        </>
      );
    }

    return (
      <div className="overflow-auto p-2 mt-auto" style={{ scrollBehavior: "smooth", position: "relative" }} ref={scrollableDivRef}>
        {mapMessages(conversations)}
        {unReadMessages?.length > 0 && <div onClick={() => updateUnreadMessages()}>{mapMessages(unReadMessages, true)}</div>}
      </div>
    );
  }
);

const NoConversations = ({ navigate, urlParams, chatView, appProperties }) => (
  <div className="d-flex flex-column align-items-center justify-content-center m-auto">
    <div className="staticIconsSprite chatIcon-sprite"></div>
    {!appProperties?.serviceInstalled ? (
      <div className="d-flex flex-column align-items-center noConvo-info">
        <span style={{ fontSize: chatView ? 14 : 16, color: "#818094" }}>Stay connected with your customers. </span>
        <span style={{ fontSize: chatView ? 12 : 14, color: "#818094" }}>
          Connect your channel and begin typing a contact name or number above to initiate a new message.{" "}
        </span>
      </div>
    ) : (
      <div className="d-flex flex-column align-items-center noConvo-info">
        <span style={{ fontSize: chatView ? 14 : 16, color: "#818094" }}>Stay connected with your customers. </span>
        {!chatView && (
          <span
            style={{
              fontSize: chatView ? 12 : 14,
              color: "#818094",
            }}
          >
            Begin typing a contact name or number above to initiate a new message.
          </span>
        )}
      </div>
    )}
    {!appProperties?.serviceInstalled ? (
      <Button
        className="rounded m-2 addChannel-btn"
        onClick={() => {
          navigate("/channels/textsms?" + urlParams);
        }}
        type=""
      >
        Add Channels
      </Button>
    ) : null}
  </div>
);

function playNotificationSound() {
  if (localStorage.getItem("sound") === "true" || localStorage.getItem("sound") === true) {
    const sound = document.querySelector("#notification");
    if (sound) {
      sound.play().catch((error) => {
        console.log(error);
      });
    }
  }
}

function ListView({
  numberList,
  setNumbersList,
  selectedContact,
  handleNumberSelect,
  setIsChannelPresent,
  setSelectedContact,
  setFilterContact,
  appProperties,
  services,
  setCustomerInfoObj,
  selectedContactId,
  showConversations,
  setShowConversations,
  setShowDescription,
  showDescription,
}) {
  const listRef = useRef(null);
  const { fetchData } = useHttp();
  const [selectedService, setSelectedService] = useState(null);
  const [searchInputLoader, setSearchInputLoader] = useState(null);
  const [filterSearchInput, setFilterSearchInput] = useState("");
  const [searchNoContactContent, setSearchNoContactContent] = useState(false);
  const [debouncedFilterContact] = useDebounce(filterSearchInput, 1000);
  const paginationRef = useRef();

  useEffect(() => {
    const fetchApi = () => {
      setSearchInputLoader(<div className="actionIconsSprite search-loading"></div>);
      fetchData(
        "omessage/contacts?limit=100" +
          (debouncedFilterContact !== "" ? "&searchText=" + encodeURIComponent(debouncedFilterContact) : "") +
          (selectedService !== null && selectedService !== "null" && selectedService?.slice(0, 1) !== "+"
            ? "&serviceId=" + selectedService
            : selectedService?.slice(0, 1) === "+"
            ? "&orgMember=" + encodeURIComponent(selectedService)
            : ""),
        "GET",
        null,
        appProperties
      )
        .then(function (response) {
          try {
            if (response) {
              response = JSON.parse(response);
              setShowConversations(true);
              let resp = response?.data?.contacts;
              paginationRef.current = response?.data?.paginationModel?.lastKey;
              setNumbersList(resp);
              setFilterContact(debouncedFilterContact);
              setSearchInputLoader(null);
              if (debouncedFilterContact.length > 0) {
                setSearchNoContactContent(true);
              }
              setShowDescription((prev) => {
                if (prev === true) {
                  return false;
                }
                return prev;
              });
            } else {
              setSearchInputLoader(null);
            }
          } catch (error) {
            setSearchInputLoader(null);
            console.error("Error processing response:", error);
          }
        })
        .catch((err) => {
          setSearchInputLoader(null);
        });
    };
    if (appProperties) {
      fetchApi();
    }
  }, [debouncedFilterContact, selectedService, setNumbersList, fetchData, setFilterContact, setShowConversations]);

  const debounceFetchData = useDebouncedCallback(() => {
    fetchData(
      "omessage/contacts?lastKey=" +
        encodeURIComponent(paginationRef.current) +
        "&limit=100" +
        (debouncedFilterContact !== "" ? "&searchText=" + encodeURIComponent(debouncedFilterContact) : "") +
        (selectedService !== null && selectedService !== "null" && selectedService?.slice(0, 1) !== "+"
          ? "&serviceId=" + selectedService
          : selectedService?.slice(0, 1) === "+"
          ? "&orgMember=" + encodeURIComponent(selectedService)
          : ""),
      "GET",
      null,
      appProperties
    )
      .then(function (response) {
        try {
          if (response) {
            response = JSON.parse(response);
            let resp = response?.data?.contacts;
            paginationRef.current = response.data?.paginationModel?.lastKey;
            setNumbersList([...numberList, ...resp]);
          }
        } catch (error) {
          console.error("Error processing response:", error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, 500);

  const handleScroll = async () => {
    const element = listRef.current;
    if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
      if (paginationRef.current) {
        debounceFetchData();
      } else {
        console.log("Pagination Modal Body is NUll");
      }
    }
  };

  const handleUpdateInfo = (resp) => {
    const { sourceFrom, firstName, unreadCount, integId, orgMember, orgCustomer, contactId, avatar } = resp;
    setNumbersList((prevRecords) => {
      const index = prevRecords.findIndex((obj) => obj?.orgMember === orgMember && obj?.orgCustomer === orgCustomer);
      if (index !== -1) {
        const updatedRecords = [...prevRecords];
        updatedRecords[index] = {
          ...updatedRecords[index],
          unreadCount: null,
        };
        return updatedRecords;
      }
      return prevRecords;
    });
    selectedContactId.current = contactId;
    setCustomerInfoObj((prev) => ({
      ...prev,
      displayName: sourceFrom,
      avatar: avatar,
      firstName: firstName,
      unreadCount: unreadCount,
      integId: integId,
    }));
  };
  return (
    <div className="bg-white d-flex flex-column rounded-4 m-2 ms-0" style={{ height: showDescription ? "75vh" : "88vh" }}>
      {showConversations ? (
        <>
          <Row className="m-3 ms-4 me-3 mb-1 d-flex justify-content-between align-items-center">
            <Col
              className="d-flex align-items-center"
              style={{
                fontSize: 18,
                fontWeight: 500,
              }}
            >
              <span className="px-1 pb-1" style={{ cursor: "default" }}>
                Conversations
              </span>
              {numberList?.length > 0 && <span className="numberlist-count ms-1">{numberList?.length}</span>}
            </Col>
            <Col>
              <Button
                tabIndex={0}
                className="px-2"
                style={{ backgroundColor: "#605BFF" }}
                type="primary"
                onClick={() => {
                  if (services?.length > 0) {
                    setIsChannelPresent(false);
                    setSelectedContact(null);
                    setCustomerInfoObj(null);
                    selectedContactId.current = null;
                  }
                }}
              >
                <div className="d-flex justify-content-between">
                  <div className="actionIconsSprite plusIconWhite pb-1 mb-1" />
                  <span className="px-1">New</span>
                </div>
              </Button>
            </Col>
          </Row>
          <div className="m-3 mb-2 d-flex align-items-center justify-content-between">
            <Search
              placeholder="Search"
              suffix={
                <>
                  <div>
                    {filterSearchInput?.length > 0 ? (
                      <div
                        className="actionIconsSprite clear-search"
                        onClick={() => {
                          setFilterSearchInput("");
                          setSearchInputLoader(<div className="actionIconsSprite search-loading"></div>);
                        }}
                      ></div>
                    ) : null}
                  </div>
                  <div>{searchInputLoader}</div>
                </>
              }
              tabIndex={1}
              value={filterSearchInput}
              onChange={(e) => {
                setSearchInputLoader(<div className="actionIconsSprite search-loading"></div>);
                setFilterSearchInput(e.target.value);
                setCustomerInfoObj(null);
                setIsChannelPresent(false);
                setSelectedContact(null);
                selectedContactId.current = null;
              }}
              className="hs-bg-offwhite focus-border searchFilter-contact"
              bordered={false}
              prefix={<img alt="search" src={SearchIcon} height={15} />}
              style={{ marginRight: "0.4em", borderRadius: 4 }}
            />
            <TreeSelect
              tabIndex={2}
              bordered={false}
              suffixIcon={<div className="actionIconsSprite fromNumberDropDownIcon" />}
              defaultValue={[
                {
                  value: "null",
                  title: "All",
                },
              ]}
              placement="bottomRight"
              dropdownStyle={{ width: 180 }}
              popupMatchSelectWidth={false}
              onChange={(e) => {
                setSelectedService(e);
                setCustomerInfoObj(null);
                setIsChannelPresent(false);
                setSelectedContact(null);
                selectedContactId.current = null;
              }}
              treeData={[
                {
                  value: "null",
                  title: "All",
                },
                ...services,
              ]}
            />
          </div>
          {numberList?.length > 0 ? (
            <div ref={listRef} className="overflow-auto flex-grow-*" style={{ scrollBehavior: "smooth" }} onScroll={handleScroll}>
              <List
                header={null}
                footer={null}
                dataSource={numberList}
                className="chatList m-2"
                renderItem={(resp, index) => (
                  <List.Item
                    tabIndex={index + 4}
                    value={resp.timeInMillis}
                    onClick={() => {
                      const selectedNumbers = {
                        fromNumber: resp?.orgMember,
                        toNumber: resp?.orgCustomer,
                        integId: resp?.integId,
                      };
                      handleNumberSelect("", selectedNumbers);
                      handleUpdateInfo(resp);
                    }}
                    className="conv-list-item"
                    style={{
                      borderRadius: 6,
                      backgroundColor:
                        selectedContactId.current === resp.contactId ||
                        (!selectedContactId.current && selectedContact?.toNumber === resp.orgCustomer && resp.new)
                          ? "rgba(245, 245, 255, 1)"
                          : null,
                      padding: "12px 0px",
                      marginTop: 4,
                      borderBlockEnd: 0,
                    }}
                  >
                    <List.Item.Meta
                      avatar={
                        <div className="ms-3 hs-bg-light-orange rounded-circle d-flex align-items-center justify-content-center list-row-avatar">
                          {resp?.avatar ? (
                            <Avatar rootClassName="w-100 h-100" src={resp?.avatar} />
                          ) : resp?.firstName ? (
                            <span className="hs-color-dark-orange hs-fw-600 hs-fs-18">{resp?.firstName?.slice(0, 1)?.toUpperCase()}</span>
                          ) : (
                            <div className="h-100 w-100 avatarIcon rounded-circle staticIconsSprite" />
                          )}
                        </div>
                      }
                      title={
                        <div className="d-flex justify-content-between">
                          <span style={{ fontSize: 15 }}>{resp.firstName || resp.orgCustomer}</span>
                          {resp?.unreadCount > 0 &&
                            !(selectedContact?.toNumber === resp.orgCustomer && selectedContact?.fromNumber === resp?.orgMember) && (
                              <Tag
                                className="rounded-5 border-0"
                                style={{
                                  backgroundColor: "#2ED47A",
                                  color: "#fff",
                                }}
                              >
                                {resp?.unreadCount}
                              </Tag>
                            )}
                          {resp.new && (
                            <Tag
                              className="rounded-5 border-0"
                              style={{
                                backgroundColor: "#FEF5EA",
                                color: "#1C1D22",
                              }}
                            >
                              {resp.new}
                            </Tag>
                          )}
                        </div>
                      }
                      description={
                        <div className="d-flex justify-content-between me-2" style={{ color: "#68677F" }}>
                          <span className="d-flex align-items-center" style={{ fontSize: 13 }}>
                            <span className="me-1">
                              {resp?.messageTagStatus === "queued" ? (
                                <div className="actionIconsSprite contact-queue-icon"></div>
                              ) : resp?.messageTagStatus === "sent" ? (
                                <div className="actionIconsSprite sent-icon"></div>
                              ) : ["failed", "undelivered"].includes(resp?.messageTagStatus) ? (
                                <div className="actionIconsSprite contact-failure-icon"></div>
                              ) : resp?.messageTagStatus === "delivered" ? (
                                <div className="actionIconsSprite contact-delivered-icon"></div>
                              ) : null}
                            </span>
                            <span
                              className={
                                resp?.messageTagType &&
                                !resp.messageTagType.includes("dummy") &&
                                !resp.messageTagType.includes("text") &&
                                `me-1 actionIconsSprite contact-${
                                  resp.messageTagType.includes("gif")
                                    ? "gif"
                                    : resp.messageTagType.includes("video")
                                    ? "video"
                                    : resp.messageTagType.includes("image")
                                    ? "image"
                                    : "file"
                                }-icon`
                              }
                            ></span>
                            {resp.messageTag ? (
                              <>{resp?.messageTag?.length > 19 ? `${resp?.messageTag?.slice(0, 19)}...` : resp?.messageTag}</>
                            ) : resp?.messageTagType?.includes("gif") ? (
                              "GIF"
                            ) : resp?.messageTagType?.includes("video") ? (
                              "Video"
                            ) : resp?.messageTagType?.includes("image") ? (
                              "Image"
                            ) : (
                              resp?.messageTagType && !resp?.messageTagType?.includes("dummy") && "File"
                            )}
                          </span>
                          <span style={{ fontSize: 13, whiteSpace: "nowrap" }}>
                            {formatDate(resp.messageTime) === "Today"
                              ? formatTime(resp.messageTime)
                              : resp.new
                              ? dayjs().format("hh:mm A")
                              : formatDate(resp.messageTime)}
                          </span>
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            </div>
          ) : (
            <>
              {!searchNoContactContent ? (
                <div className="d-flex m-auto flex-column justify-content-center align-items-center">
                  <div className="staticIconsSprite inboxNumberListEmpty-icon"></div>
                  <span className="p-2" style={{ color: "#606060" }}>
                    Your inbox is empty
                  </span>
                  <span style={{ color: "#8B8D97" }}>New message will show up here</span>
                </div>
              ) : (
                <div className="d-flex m-auto flex-column justify-content-center align-items-center">
                  <div className="staticIconsSprite inboxNumberListEmpty-icon"></div>
                  <span className="p-2" style={{ color: "#606060" }}>
                    No matches found.
                  </span>
                  <span style={{ color: "#8B8D97" }}>
                    <span
                      className=""
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setFilterSearchInput("");
                      }}
                    >
                      Clear
                    </span>
                  </span>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <Space className="p-5 pb-1">
            <Skeleton.Button active size={"small"} shape={""} />
            <Skeleton.Avatar active size={"small"} shape={"circle"} />
            <Skeleton.Input active size={"small"} />
          </Space>
          <Space className="p-5 pb-1 pt-3 d-flex justify-content-between">
            <Skeleton.Button active size={"small"} shape={""} />
            <Skeleton.Input active size={"small"} />
          </Space>
          <Skeleton className="p-5 pt-2" active size="small" paragraph={{ rows: 12 }} />
        </>
      )}
    </div>
  );
}

const formatDate = (date) => {
  const today = dayjs().format("YYYY-MM-DD");
  const yesterday = dayjs().subtract(1, "days").format("YYYY-MM-DD");
  if (date) {
    date = dayjs(date).format("YYYY-MM-DD");
    if (date === today) {
      return "Today";
    } else if (date === yesterday) {
      return "Yesterday";
    } else {
      return dayjs(date).format("D MMM, YYYY");
    }
  }
};

const formatTime = (time) => {
  if (time) {
    let date = dayjs(time);
    return date.format("h:mm A");
  }
};

const convertURLsToLinks = (obj, isMMS) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = obj?.message?.split(urlRegex);
  return parts?.map((part, index) => {
    if (part?.match(urlRegex)) {
      return (
        <a
          style={{
            color: obj?.direction === 2 && !isMMS && !["failed", "undelivered"].includes(obj?.messageStatus || obj?.status) ? "#fff" : "black",
          }}
          key={index}
          href={part}
          target="_blank"
        >
          {part}
        </a>
      );
    } else {
      return part;
    }
  });
};
