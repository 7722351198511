import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom";
import Settings from "./Setting/Settings";
import Home from "./Home";
import Campaign from "./campaign/components/campaign/Campaign";
import CampaignOverview from "./campaign/components/overview/CampaignOverview";
import Services from "./services/Services";
import TextSMS from "./services/TextSMS";
import TextSMSAuthPage from "./services/TextSMSAuthPage";
import ActiveNumbers from "./OnboardingComponents/ActiveNumbers";
import ConversationView from "./ConversationPage";
import ConversationWrapper from "./ConversationContext";
import SendSMS from "./outbound/SendSMS";
import Templates from "./Templates";
import Module from "./Module";
import AppLayout from "./AppLayout";
import Users from "./Users";
import AutomatedMessages from "./AutomatedMessages";
import ContentGpt from "./Content";
import CampaignAnalytics from "./CampaignAnalytics";
import TenDlc from "./OnboardingComponents/10DLC/TenDlc";

const createRouter = (breadCrumbContextValue) => {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={
          <AppLayout
            setLoading={breadCrumbContextValue?.setLoading}
            breadCrumbItems={breadCrumbContextValue?.breadCrumbItems}
            setBreadCrumbItems={breadCrumbContextValue?.setBreadCrumbItems}
          />
        }
      >
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/settings" element={<Settings />} />
        <Route path="/campaign" element={<Campaign />} />
        <Route path="/campaigns/list" element={<CampaignOverview />} />
        <Route path="/channels" element={<Services />} />
        <Route path="/channels/textsms" element={<TextSMS />} />
        <Route path="/channels/facebook" element={<TextSMSAuthPage />} />
        <Route path="/channels/twilio_whatsapp" element={<TextSMSAuthPage />} />
        <Route path="/channels/textsms/helloSend" element={<ActiveNumbers />} />
        <Route
          path="/channels/textsms/:dynamicSMSservice"
          element={<TextSMSAuthPage />}
        />
        <Route path="/content" element={<ContentGpt />} />
        <Route path="/chat" element={<ConversationView />} />
        <Route path="*" element={<p>There's nothing here: 404!</p>} />{" "}
        <Route path="/inbox" element={<ConversationWrapper />} />
        <Route
          path="/sendsms"
          element={
            <SendSMS
              bulk={false}
              setLoading={breadCrumbContextValue?.setLoading}
            />
          }
        />
        <Route
          path="/messages"
          element={
            <SendSMS
              bulk={true}
              setLoading={breadCrumbContextValue?.setLoading}
            />
          }
        />
        <Route path="/templates" element={<Templates />} />
        <Route path="/module" element={<Module />} />
        <Route path="/users" element={<Users />} />
        {/* <Route path="/automation" element={<WorkFlow />} /> */}
        <Route path="/automation" element={<AutomatedMessages />} />
        <Route path="/content" element={<ContentGpt />} />
        <Route
          path="/campaigns/list/:campaignId/reports"
          element={
            <CampaignAnalytics
              setBreadCrumbItems={breadCrumbContextValue?.setBreadCrumbItems}
              currentItem={"error"}
            />
          }
        />
        <Route
          path="/campaigns/list/:campaignId/overview"
          element={
            <CampaignAnalytics
              setBreadCrumbItems={breadCrumbContextValue?.setBreadCrumbItems}
              currentItem={"overview"}
            />
          }
        />
        <Route
          path="/campaigns"
          element={<Navigate to={`/campaigns/list${window.location.search}`} />}
        />
        <Route path="/channels/textsms/helloSend/10DLC" element={<TenDlc />} />
        <Route path="*" element={<h1>404!</h1>} />
      </Route>
    )
  );
};
export default createRouter;
