import axios from "axios";
import { useCallback, useRef, useState } from "react";
import { failureNotification } from "../../src/pages/utils/CommonNotifications";

import { API_VERSION, APP_URL, ASSIST_MAIL, ModalState, osyncId } from "../constants/AppConstants";
// API call for all methods GET, POST, PUT, DELETE

export default function useHttp() {
  const [isLoading, setIsLoading] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [serverError, setServerError] = useState(null);
  const lastFetchedTime = useRef();

  const fetchData = useCallback(async (url, method, body, appProperties, headers) => {
    if (ModalState === false) {
      let headerJson = {};
      var urlParams = new URLSearchParams(window.location.search);
      if (API_VERSION === "v1") {
        if ((appProperties?.hash && appProperties.hash !== "") || appProperties?.first) {
          const hash = appProperties.hash;

          headerJson = {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Osync-Authorization": hash,
            "Content-Type": "application/json; charset=UTF-8",
          };
        } else if (urlParams.get("hash") !== null && urlParams.get("hash") !== undefined && urlParams.get("hash") !== "") {
          const hash = urlParams.get("hash");
          headerJson = {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Osync-Authorization": hash,
            "Content-Type": "application/json; charset=UTF-8",
          };
        }
      } else {
        const companyId = urlParams.get("companyId");
        const userId = urlParams.get("userId");
        headerJson = {
          "hs-orgId": companyId + "_" + userId,
        };
      }

      let hsOsyncId = "";

      if (urlParams.has("osyncId")) {
        hsOsyncId = urlParams.get("osyncId");
      }
      if (hsOsyncId && hsOsyncId !== "") {
        let subAccountHeaderJson = {
          "hs-osyncId": hsOsyncId,
        };
        headerJson = { ...headerJson, ...subAccountHeaderJson };

        console.log("subAccountId ;;;; 111 final headerJson >>>>>>>>>>>>>>>>>", headerJson);
      }
      if (headers) {
        headerJson = { ...headerJson, ...headers };
      }

      //Just for ngrok purpose -- don't delete
      // headerJson["ngrok-skip-browser-warning" ] =  "ngrok";
      setIsLoading(true);
      try {
        const resp = await axios({
          method: method,
          headers: headerJson,
          url: APP_URL + "/api/" + API_VERSION + "/" + url,
          withCredentials: true,
          data: body,
        });

        const data = JSON.stringify(resp);
        setApiData(data);
        return data;
      } catch (error) {
        console.log(error);
        console.log("ERROR :::::: " + typeof error, error.response);
        console.log("TYPE OF RESPONSE:::::: ", typeof error.response?.status);

        if (API_VERSION === "v2" && error.response?.status === 401) {
          appProperties.setOtpModalState(true);
        } else if (API_VERSION === "v2" && error.response?.status === 403 && error?.response?.data?.error === "NO_ACCESS_TO_SUB_ACCOUNT") {
          appProperties.setPermissionDeniedToSubAccount(true);
        } else {
          failureNotification(
            "ERROR",
            <div>
              Some unknown error occurred. Please contact
              <a target="_blank" rel="noreferrer" href={`mailto:${ASSIST_MAIL}`} style={{ paddingLeft: 5, color: "#605bff" }}>
                {ASSIST_MAIL}
              </a>
            </div>
          );
        }

        setServerError(error);
        setIsLoading(false);
        return null;
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  const pollData = useCallback(
    async (from, to, serviceId, appProperties) => {
      const d = new Date();
      let timeMS = d.getTime();
      let fetchUrl = `channels/inbox/polling`;
      if (lastFetchedTime.current) {
        d.setTime(d.getTime() + -10 * 1000);
        timeMS = d.getTime();
        lastFetchedTime.current = d.toISOString();
      } else {
        lastFetchedTime.current = d.toISOString();
      }
      fetchUrl += `?lastFetchedTime=${lastFetchedTime.current}&timeInMS=${timeMS}`;
      fetchUrl += from ? `&orgMember=${encodeURIComponent(from)}` : "";
      fetchUrl += to ? `&orgCustomer=${encodeURIComponent(to)}` : "";
      fetchUrl += serviceId ? `&serviceId=${encodeURIComponent(serviceId)}` : "";

      let response = await fetchData(fetchUrl, "GET", null, appProperties);
      response = JSON.parse(response);
      return response?.data;
    },
    [fetchData]
  );

  const fetchDataByPagination = useCallback(
    async (url, method, body, appProperties, header, limit, paginationParams) => {
      let targetUrl = url;
      if (limit) {
        targetUrl += `&limit=${limit}`;
      }
      if (paginationParams?.lastKey && paginationParams?.lastRange) {
        targetUrl += `&lastKey=${paginationParams?.lastKey}&lastRange=${paginationParams?.lastRange}`;
      }
      const response = await fetchData(targetUrl, method, body, appProperties, header);
      const responseJson = JSON.parse(response);
      return responseJson?.data;
    },
    [fetchData]
  );

  return {
    isLoading,
    apiData,
    serverError,
    fetchData,
    fetchDataByPagination,
    pollData,
  };
}
