import "./../../../../assets/css/campaign.css";
import { useContext } from "react";
import { AppContext } from "../../../../context/AppContext";
import CampaignHeader from "./CampaignHeader";
import CampaignContent from "./CampaignContent";

export default function Campaign() {
  const [appProperties, setAppProperties] = useContext(AppContext);

  return (
    <div className="h-100 hs-w-90 p-3">
      <CampaignHeader appProperties={appProperties} />
      <CampaignContent appProperties={appProperties} setAppProperties={setAppProperties} />
    </div>
  );
}
