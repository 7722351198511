import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Dropdown, Layout, Row, Space } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { urlParams } from "./../../constants/AppConstants";
import { AppContext } from "./../../context/AppContext";

import SubAccountsListing from "./../../pages/subAccount/SubAccountsListing.jsx";

const { Header } = Layout;

const titles = {
  channels: "Channels",
  phone: "Add phone",
  templates: "Templates",
  inbox: "Inbox",
  module: "Custom Module",
  sendsms: "Send SMS",
  messages: "Bulk SMS",
  campaigns: "Overview",
  campaign: "Campaign",
  chat: "Chat",
  users: "Users",
  workflow: "Automation",
  campaignOverView: "Detailed Overview",
};

function Headerbar(props) {
  const topBarNeeded = urlParams.get("topBar");
  const [title, setTitle] = useState(props.headerTitle);
  const [appProperties] = useContext(AppContext);
  const [userLogo, setUserLogo] = useState();
  const [isTopBarNeeded] = useState(topBarNeeded === undefined || topBarNeeded !== "false");
  const [allPageContainer, setAllPageContainer] = useState("");
  const [isSideBar, setIsSideBar] = useState(true);
  const [items, setItems] = useState([]);

  let sideBar = urlParams.get("sideBar");
  useEffect(() => {
    if (appProperties?.controller?.pricingLink) {
      appProperties.controller.pricingLink(appProperties).then(function (resp) {
        if (appProperties?.licenseObj?.licenseDetails?.licenseStatus === 4) {
          items.push({
            label: (
              <a style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer" href={resp}>
                Manage Subscription
              </a>
            ),
            key: "Manage_Subscription",
            icon: <UserOutlined />,
          });
        } else {
          items.push({
            label: "Manage Subscription",
            key: "Manage_Subscription",
            icon: <UserOutlined />,
            "data-cb-type": "portal",
          });
        }
        setItems(items);
      });
    }
  }, [appProperties?.controller]);

  useEffect(() => {
    if (sideBar === "false") {
      setAllPageContainer("container mx-auto ");
      setIsSideBar(false);
    }
  }, []);
  useEffect(() => {
    setTitle(titles[props.headerTitle] || props.headerTitle);
    let userLogoText = appProperties.userLogo;
    if (userLogoText === "") {
      userLogoText = appProperties.userName.charAt(0).toUpperCase();
    }
    setUserLogo(userLogoText);
  }, [props.headerTitle, appProperties.userLogo, appProperties.userName]);

  if (!isTopBarNeeded) {
    return <></>;
  }
  const handleManageSubscription = (e) => {
    window.Chargebee?.registerAgain();
    console.log("click", e);
  };

  const menuProps = {
    items,
    onMouseEnter: handleManageSubscription,
    style: {
      marginTop: "25px",
    },
  };
  return (
    <Header
      className={allPageContainer}
      style={{
        padding: 0,
        position: sideBar === "false" ? "relative" : "fixed",
        width: sideBar === "false" ? "relative" : "93.6svw",
        // background: props.style,
        height: 60,
        zIndex: 9,
        marginInlineStart: "130px",
        marginLeft: sideBar === "false" ? "auto" : "12.5rem",
        lineHeight: "64px",
        backgroundColor: "#fff",
        borderLeft: "none",
      }}
    >
      <Row style={{ overflow: "hidden" }}>
        <Col span={17}>
          <h1
            className="ms-4 m-3"
            style={{
              textTransform: "capitalize",
              color: "#111827",
              fontWeight: 700,
              fontSize: 24,
            }}
          >
            {title}
          </h1>
        </Col>

        <Col span={3}>
          <SubAccountsListing selectComponentWidth={"90%"} selectComponentHeight={40} />
        </Col>

        <Col span={2} style={{ alignContent: "center" }}>
          <Space>
            {isSideBar ? (
              <>
                <Dropdown menu={menuProps} overlayClassName="profilePopup">
                  <Button className="ps-0 border-none boxshadowRemove">
                    <Space className="profileAvatarBg me-3 px-2 py-1 gap-0 hs-border-30">
                      <Avatar
                        style={{
                          backgroundColor: "#d5fce3",
                          color: "black",
                          verticalAlign: "middle",
                          border: "1px solid #d5fce3",
                        }}
                        size="large"
                        className="manageSubscription"
                        src={userLogo}
                        border="light"
                      >
                        {userLogo}
                      </Avatar>
                      <div className="actionIconsSprite fromNumberDropDownIcon" />
                    </Space>
                  </Button>
                </Dropdown>
              </>
            ) : null}
          </Space>
        </Col>
      </Row>
    </Header>
  );
}

export default Headerbar;
