import React, { useState, useEffect, useContext } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Drawer, Table, Col, Row, Select } from "antd";
import "./../assets/css/customModule.css";
import { AppContext } from "../context/AppContext";
import useSavedModule from "../hooks/useModule";
import { failureNotificationWithLink, successNotifyWithDescription } from "./utils/CommonNotifications";
import { ASSIST_MAIL } from "../constants/AppConstants";

function Module() {
  const [appProperties] = useContext(AppContext);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCustomModule, setSelectedCustomModule] = useState("");
  const { fetchSavedModule } = useSavedModule();
  const [savedCustomModule, setSavedCustomModule] = useState([]);
  const [selectedModuleName, setSelectedModuleName] = useState("");
  const [selectedModuleApiName, setSelectedModuleApiName] = useState("");
  const [callSavedModuleTable, setCallSavedModule] = useState(true);

  const addConfigurationCustomModule = () => {
    setIsDrawerOpen(true);
    setSelectedCustomModule("");
  };
  const columns = [
    {
      title: "Module Id",
      dataIndex: "smsModuleId",
      key: "smsModuleId",
    },
    {
      title: "Module",
      dataIndex: "moduleName",
      key: "moduleName",
    },
    {
      title: "Field Label",
      dataIndex: "moduleFieldDisplayLabel",
      key: "moduleFieldDisplayLabel",
    },
    {
      title: "Phone field",
      key: "phoneFieldDisplayLabel",
      dataIndex: "phoneFieldDisplayLabel",
    },
    {
      title: "Created Time",
      key: "createdTime",
      dataIndex: "createdTime",
    },
  ];

  useEffect(() => {
    if (appProperties?.integId && callSavedModuleTable === true) {
      fetchSavedModule(appProperties).then((response) => {
        setSavedCustomModule(response?.data ?? []);
        setCallSavedModule(false);
        if (selectedCustomModule === "") {
          console.log(response, "response");
          setSelectedCustomModule(response?.data[0]);
        }
      });
    }
  }, [appProperties.integId, callSavedModuleTable]);
  return (
    <>
      <Button type="primary" id="addCustomModuleBtn" icon={<PlusCircleOutlined />} onClick={addConfigurationCustomModule} className="mb-0 me-2">
        Configuration custom module
      </Button>
      {isDrawerOpen ? (
        <AddCustomModule
          setIsDrawerOpen={setIsDrawerOpen}
          isDrawerOpen={isDrawerOpen}
          appProperties={appProperties}
          selectedModuleName={selectedModuleName}
          setSelectedModuleName={setSelectedModuleName}
          setSelectedModuleApiName={setSelectedModuleApiName}
          selectedModuleApiName={selectedModuleApiName}
          setCallSavedModule={setCallSavedModule}
        />
      ) : null}
      <Table className="savedTempTable" columns={columns} dataSource={savedCustomModule} pagination={false} />
    </>
  );
}

export default Module;

const AddCustomModule = React.memo((props) => {
  const {
    isDrawerOpen,
    setIsDrawerOpen,
    appProperties,
    selectedModuleName,
    setSelectedModuleName,
    setSelectedModuleApiName,
    selectedModuleApiName,
    setCallSavedModule,
  } = props;

  const [drawerOpen, setDrawerOpen] = useState(isDrawerOpen);
  const { fetchModules, fetchModuleApiName, addCustomModuleBtn } = useSavedModule();
  const [savedModules, setSavedModules] = useState([]);
  const [savedModuleApiName, setSavedModuleApiName] = useState([]);
  const [selectedDisplayedValue, setSelectedDisplayedValue] = useState("");
  const [selectedPhoneField, setSelectedPhoneField] = useState("");
  const [selectedDisplayApiName, setSelectedDisplayApiName] = useState("");
  const [selectedPhoneFieldApiName, setSelectedPhoneFieldApiName] = useState("");

  const handleClose = () => {
    setDrawerOpen(false);
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    let integId = appProperties?.randomIntegId;
    if (integId) {
      fetchModules(appProperties).then((response) => {
        console.log("response for module", response?.data);
        setSavedModules(response?.data ?? []);
      });
    }
  }, []);

  const handleChangeCustomModule = (value) => {
    const moduleNameArray = savedModules[value];
    const moduleName = moduleNameArray.moduleName;
    const moduleApiName = moduleNameArray.moduleApiName;
    setSelectedModuleName(moduleName);
    setSelectedModuleApiName(moduleApiName);
    fetchModuleApiName(appProperties, moduleApiName).then((response) => {
      setSavedModuleApiName(response?.data ?? []);
      if (selectedDisplayedValue === "") {
        setSelectedDisplayedValue(response.data[0].moduleName);
      }
      if (selectedPhoneField === "") {
        setSelectedPhoneField(response.data[0].moduleName);
      }
    });
  };
  const handleChangeDisplayed = (value) => {
    const lableToBeDisplay = savedModuleApiName[value];
    console.log(lableToBeDisplay, "lableToBeDisplay");
    const lableToBeDisplayModuleName = lableToBeDisplay.moduleName;
    const lableToBeDisplayModuleApiName = lableToBeDisplay.moduleApiName;
    setSelectedDisplayedValue(lableToBeDisplayModuleName);
    setSelectedDisplayApiName(lableToBeDisplayModuleApiName);
  };
  const handleChangePhoneField = (value) => {
    const phoneField = savedModuleApiName[value];
    console.log(phoneField, "phoneField");
    const phoneFieldModuleName = phoneField.moduleName;
    const phoneFieldModuleApiName = phoneField.moduleApiName;
    setSelectedPhoneField(phoneFieldModuleName);
    setSelectedPhoneFieldApiName(phoneFieldModuleApiName);
  };

  const addCustomModule = () => {
    var moduleApiName = selectedModuleApiName;
    const tempData = {
      moduleName: selectedModuleName,
      moduleApiName: selectedModuleApiName,
      moduleFieldDisplayLabel: selectedDisplayedValue,
      moduleFieldDisplayLabelApiName: selectedDisplayApiName,
      phoneFieldDisplayLabel: selectedPhoneField,
      phoneFieldApiName: selectedPhoneFieldApiName,
      integId: appProperties.integId,
    };
    if (moduleApiName !== "") {
      console.log("payload fo@@@r templates3");
      addCustomModuleBtn(appProperties, tempData).then((response) => {
        console.log("response for add templates", response);
        if (response?.status) {
          console.log("response for add templates");
          setCallSavedModule(true);
          setIsDrawerOpen(false);

          successNotifyWithDescription("Add Module", "Module added. You can now send campaigns.");
        } else if (response?.license?.code === "FEATURE_LIMIT_EXCEEDED") {
          var temLimit = response?.license?.description;
          failureNotificationWithLink(temLimit, "mail to", `mailto:${ASSIST_MAIL}`);
        }
      });
    }
  };

  return (
    <>
      <Drawer
        className="templateDrawer"
        title="Add Custom Module"
        placement="right"
        open={drawerOpen}
        onClose={handleClose}
        width={"30rem"}
        footer={
          <Col span={24} className=" templatesAddButton">
            <Button id="templateAdd" type="primary" size="large" className="mt-4 me-2" style={{ width: "170px" }} onClick={addCustomModule}>
              Add
            </Button>
          </Col>
        }
      >
        <div className="templateModal">
          <Row>
            <Col xs={24} md={22} xl={24} id="EventWebhook">
              Module
              <Select
                className="selectModule w-100 mt-1"
                size="large"
                value={selectedModuleName || "Select Module"}
                onChange={handleChangeCustomModule}
              >
                {savedModules.map((value, index) => {
                  return <Select.Option key={index}>{value.moduleName}</Select.Option>;
                })}
              </Select>
            </Col>

            <Col xs={24} md={22} xl={24} className="pt-4" id="EventWebhook">
              Choose the label to be displayed
              <Select className="selectModule w-100 mt-1" size="large" value={[selectedDisplayedValue]} onChange={handleChangeDisplayed}>
                {savedModuleApiName.map((value, index) => {
                  return <Select.Option value={index}>{value.moduleName}</Select.Option>;
                })}
              </Select>
            </Col>
            <Col xs={24} md={22} xl={24} className="pt-4" id="FromNumberWebhook">
              Choose the phone field
              <Select className="selectModule w-100 mt-1" size="large" value={[selectedPhoneField]} onChange={handleChangePhoneField}>
                {savedModuleApiName.map((value, index) => {
                  return <Select.Option value={index}>{value.moduleName}</Select.Option>;
                })}
              </Select>
            </Col>
          </Row>
        </div>
      </Drawer>
    </>
  );
});
