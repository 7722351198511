import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, Empty, Form, Input, Modal, Row, Select, Skeleton, Space, Statistic, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import Pusher from "pusher-js";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../assets/css/commonVessels.css";
import Loading from "../../assets/gif/Loading.gif";
import editIcon from "../../assets/icons/editIcon.svg";
import { Chargebee_CHARGE_NAME, urlParams } from "../../constants/AppConstants";
import { ProviderCommonObj, ProviderExtendedCommonObj } from "../../constants/ProviderConstants";
import useHttp from "../../hooks/useHttp";
import useNumbers from "../../hooks/useNumbers";
import useTemplates from "../../hooks/useTemplates";
import { AppContext } from "./../../context/AppContext";
import { RegisterChargebee } from "./ChargebeeRegistration";
import { failureNotification } from "./CommonNotifications";
import { getHSFormattedDateString } from "./dateUtils";
const { Text } = Typography;

export const CommonLoading = () => {
  return (
    <div className="container-loading">
      <img
        src={Loading}
        alt="loading"
        style={{
          height: 150,
          width: 200,
          mixBlendMode: "darken",
          pointerEvents: "none",
        }}
      />
    </div>
  );
};

export const LicenseInvalidModal = () => {
  const [appProperties] = useContext(AppContext);
  const [pricingLink, setPricingLink] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (appProperties.controller.pricingLink) {
      appProperties.controller.pricingLink(appProperties).then(function (resp) {
        setPricingLink(resp);
      });
    }
  }, [appProperties]);
  return (
    <>
      <div>
        <Modal width={400} className="activateLicenseModal" open={isModalVisible} footer={null} closable={false} centered>
          <Row>
            <Col span={20}>
              <div className="d-flex align-items-left justify-content-left mb-3">
                <div className="staticIconsSprite licenseIcon"></div>
              </div>
            </Col>
          </Row>
          <div style={{ fontSize: "16px", fontWeight: 600 }} className="mb-1 me-3">
            License inactive. Activate to stay connected with client replies.
          </div>
          <div className="pb-3">Activate license for just $39 </div>
          <Row>
            <Col span={10} className="activateExtendBtn">
              <Button
                onClick={() => setOpen(true)}
                id="extendTrail"
                className="fs-6 extendTrail w-100"
                size="large"
                style={{ backgroundColor: "#F5F5F5", border: "none" }}
              >
                <span style={{ fontSize: "14px" }}>Extend Trial</span>
              </Button>
            </Col>
            <Col span={10} className="activateLicenseBtn ms-4">
              <Button href={pricingLink} target="_blank" className="fs-6 activateLicensedBtn w-100" size="large">
                <span style={{ fontSize: "14px" }}>Get Access</span>
              </Button>
            </Col>
          </Row>
        </Modal>
        {open && (
          <Modal title=" " centered open={open} onOk={() => setOpen(false)} onCancel={() => setOpen(false)} width={1000} footer={null}>
            <div>
              <iframe
                className="iframe-popup"
                src={`https://oapps.zohobookings.com/portal-embed#/share-and-extend?Name=${appProperties?.userName}&Email=${appProperties?.currentUserEmail}`}
                frameBorder="0"
                allowFullScreen
                title="Extend Trial"
              />
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export const InvalidModal = (props) => {
  let title = "";
  let modalContent = "";
  let modalButton = "";
  let link = "";

  const { authorizeModal } = props;
  const [showAuthorizeModal, setShowAuthorizeModal] = useState(authorizeModal ?? false);
  const location = useLocation();
  const [appProperties] = useContext(AppContext);
  let authObj = appProperties.authorizeObj;
  let leftAuth = authObj?.left?.auth?.authorized;
  let rightAuth = authObj?.right?.auth?.authorized;
  if (!leftAuth || !rightAuth) {
    title = appProperties?.authTitle;
    modalContent = appProperties?.authContent;
    modalButton = appProperties?.authButton;
    link = `/channels/textsms/${authObj?.right?.service_name}${location.search}`;
  }
  const navigate = useNavigate();

  function DoAuthorization() {
    if (!leftAuth) {
      const leftAuthWindow = window.open(appProperties.authLink, "Popup", "width=700,height=700,left=350,top=100");
      const receiveMessage = (event) => {
        if (event !== undefined && event.data !== undefined) {
          console.log("addEventListener receiveMessage event>>>>>>>>>>>>>>>>", event);
          console.log("addEventListener receiveMessage event.data>>>>>>>>>>>>>>>>", event.data);
          let data = event.data;
          if (typeof event.data === "string") {
            data = JSON.parse?.(event.data);
          }
          if (data !== undefined && data?.data?.integId !== undefined) {
            console.log("::::::::::::::::    SUCESSSS      :::::::::::::::s");
            navigate("/channels" + location.search);
          } else if (data !== undefined && data?.data?.osyncId !== undefined) {
            setShowAuthorizeModal(false);
          }
        }
      };
      try {
        window.addEventListener("message", receiveMessage);
        return () => {
          console.log("addEventListener  receiveMessage event registration>>>>>>>>>>>>>>>>");
          leftAuthWindow.close();
          window.removeEventListener("message", receiveMessage);
        };
      } catch (error) {
        console.log("addEventListener  receiveMessage error>>>>>>>>>>>>>>>>", error);
      }
    } else {
      window.open(link);
    }
  }
  return (
    <>
      <Modal width={700} className="Modal" open={showAuthorizeModal} footer={null} closable={false} centered>
        <Row>
          <Col xs={5} md={4} lg={4} xl={4}>
            <ClockCircleOutlined className="clockIconInModal" />
          </Col>
          <Col xs={18} md={12} lg={12} xl={12} className="activateAuthorizeModal">
            <p className="modalTitle text-dark">{title}</p>
            <p className="modalDescription text-secondary">{modalContent}</p>
          </Col>
          <Col xs={3} md={2} lg={2} xl={2}></Col>
          <Col xs={18} md={12} lg={12} xl={12} className="ModalBtn">
            <Button onClick={DoAuthorization} target="_blank" className="fs-6 activateBtn" type="primary" size="middle">
              <span style={{ "font-size": "1rem;" }}>{modalButton}</span>
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export const ErrorModal = (props) => {
  return (
    <>
      <Modal width={700} className="errorMessageModal" open={props.showError} footer={null} closable={false} centered>
        <Row>
          <Col xs={3} md={2} lg={2} xl={2}>
            <ExclamationCircleOutlined className="exclamatoryIcon" />
          </Col>
          <Col xs={18} md={20} lg={20} xl={20} className="errorMessageModal">
            <p className="errorModalTitle">{props.errorMessage}</p>
            <p className="errorModalDescription">{props.errorDescription}</p>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export function NoticeModal({ show, handleModalCancel, handleModalConfirm, cancel }) {
  return (
    <Modal
      centered
      width={400}
      title="Important Notice"
      open={show}
      className="noticeModal"
      onCancel={cancel}
      footer={[
        <Button key="back" danger ghost className="noticeModalButton" onClick={handleModalCancel}>
          cancel
        </Button>,
        <Button key="submit" type="primary" className="noticeModalButton" onClick={handleModalConfirm}>
          confirm
        </Button>,
      ]}
    >
      Altering the SID might impact actions configured earlier. Nevertheless ,I remain interested in moving forward with the override.
    </Modal>
  );
}

export function EditMessageServiceId({ msgSidList, messageServiceId, friendlyName, phoneNumber, integId }) {
  const [hover, setHover] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [getMsgValue, setMsgValue] = useState(messageServiceId);
  const [messageServiceValue, setMessageServiceValue] = useState(null);
  const [showNotice, setShowNotice] = useState(false);
  const [finalMessageServiceId, setFinalMessageServiceId] = useState(null);
  const [msgSidLists, setMsgSidLists] = useState([]);
  const [appProperties] = useContext(AppContext);

  const { fetchData } = useHttp();

  useEffect(() => {
    if (msgSidList?.length < 1) {
      fetchData(`omessage/${integId}/numbers`, "GET", null, appProperties)
        .then((response) => {
          const responseData = JSON.parse(response);
          if (responseData?.data?.messagingServices) {
            let getMsgServicesLists = responseData.data.messagingServices;
            const msgSidsList = getMsgServicesLists.map((service) => ({
              friendlyName: service.friendlyName,
              sid: service.sid,
            }));
            setMsgSidLists(msgSidsList);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setMsgSidLists(msgSidList);
    }
  }, [appProperties, fetchData, integId, msgSidList]);

  function handleServiceChange(value) {
    setMessageServiceValue(value);
  }
  function handleMsgService() {
    if (messageServiceValue === getMsgValue || messageServiceValue === finalMessageServiceId) {
      setHover(false);
      setShowSelect(false);
    } else if (messageServiceValue !== "") {
      setShowNotice(true);
    }
  }

  function editModalConfirm() {
    setShowNotice(false);

    const appData = {
      phoneNumber: phoneNumber,
      messageServiceId: getMsgValue,
      friendlyName: friendlyName,
    };
    fetchData("omessage/" + integId + "/updatePhone?associatedSid=" + messageServiceValue, "PUT", appData, appProperties).then(
      function (response) {
        response = JSON.parse(response);
        if (response.data.data !== undefined) {
          setFinalMessageServiceId(response.data.data.messageServiceId);
          setMsgValue(response.data.data.messageServiceId);
          setShowSelect(false);
          setHover(false);
        }
        if (response.data.errorMessage !== undefined) {
          setFinalMessageServiceId(messageServiceId);
          failureNotification("ERROR", response.data.errorMessage);
          setShowSelect(false);
          setHover(false);
        }
      }
    );
  }
  return (
    <>
      {hover === true ? (
        <>
          {showSelect === false ? (
            <div
              onMouseOut={() => {
                setHover(false);
              }}
              onClick={() => {
                setShowSelect(true);
              }}
            >
              <img
                src={editIcon}
                alt="editIcon"
                style={{
                  height: "20px",
                  width: "20px",
                  cursor: "pointer",
                }}
              />
            </div>
          ) : (
            <>
              <div>
                <Space>
                  <Select
                    className="hs-bg-offwhite focus-border w-100"
                    suffixIcon={<div className="actionIconsSprite fromNumberDropDownIcon" />}
                    bordered={null}
                    style={{ width: "10rem" }}
                    placeholder="choose Messaging Service"
                    onSelect={handleServiceChange}
                    // onMouseOut={()=>{setHover(false)}}
                  >
                    {msgSidLists?.map((value, index) => (
                      <Select.Option key={index} value={value.sid}>
                        {value.friendlyName + "(" + value.sid + ")"}
                      </Select.Option>
                    ))}
                  </Select>
                  <NoticeModal
                    show={showNotice}
                    cancel={() => {
                      setShowNotice(false);
                    }}
                    handleModalCancel={() => {
                      setShowNotice(false);
                    }}
                    handleModalConfirm={editModalConfirm}
                  />
                  <CheckCircleOutlined className="checkMsgIcon" onClick={handleMsgService} />
                  <CloseCircleOutlined
                    className="closeMsgIcon"
                    onClick={() => {
                      setShowSelect(false);
                    }}
                  />
                </Space>
              </div>
            </>
          )}
        </>
      ) : (
        <div
          onMouseOver={() => {
            setHover(true);
          }}
          onMouseOut={() => {
            setHover(false);
          }}
        >
          {finalMessageServiceId || messageServiceId}
        </div>
      )}
    </>
  );
}

export function RefreshLicenseDetails() {
  const [appProperties, setAppProperties] = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const { fetchData } = useHttp();
  const getLicenseDetails = () => {
    setLoading(true);
    fetchData(`omni/license?leftServiceId=${appProperties.leftServiceId}&osyncId=${appProperties.osyncId}`, "GET", null, appProperties)
      .then((response) => {
        response = JSON.parse(response);
        var license = response.data;
        appProperties.licenseObj.licenseDetails = license;
        setAppProperties((prev) => ({ ...prev, licenseObj: license }));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <>
      <Button icon={<ReloadOutlined />} loading={loading} onClick={getLicenseDetails}></Button>
    </>
  );
}

export const CommonLoadingV2 = () => {
  return (
    <div className="loading">
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export const NoChannelPresent = (newTab) => {
  const navigate = useNavigate();
  const location = useLocation();
  urlParams.delete("sideBar");
  urlParams.delete("topBar");
  return (
    <div className="d-flex flex-column m-auto w-25 align-items-center">
      <div style={{ marginTop: "30%" }} className="staticIconsSprite chatIcon-sprite"></div>
      <span
        className="m-2"
        style={{
          fontSize: newTab ? 12 : 16,
          color: "#818094",
          whiteSpace: "nowrap",
        }}
      >
        No channels Connected
      </span>
      <Button
        className="rounded m-2 addChannel-btn"
        type=""
        onClick={() => {
          if (newTab) {
            window.open("/channels?" + urlParams);
          } else {
            navigate("/channels" + location.search);
          }
        }}
        style={{ height: 48, width: 220 }}
      >
        Add Channels
      </Button>
    </div>
  );
};
export const LowCreditsWarning = () => {
  const [appProperties] = useContext(AppContext);
  const [BalanceCredits, setBalanceCredits] = useState(null);
  useEffect(() => {
    if (appProperties.licenseObj != null) {
      let licenseInfo = appProperties.licenseObj.licenseDetails;
      if (licenseInfo !== null && licenseInfo !== undefined) {
        setBalanceCredits(licenseInfo?.creditsPurchased - licenseInfo?.creditsConsumed);
      }
    }
  }, [appProperties.licenseObj]);
  return (
    <>
      {BalanceCredits < 10 && appProperties?.licenseObj?.licenseDetails?.creditsPurchased > 0 ? (
        <div
          className="p-1 m-3 rounded d-flex align-items-center"
          style={{
            border: "1px solid #F44336",
            backgroundColor: "#FFEBEE",
          }}
        >
          <ExclamationCircleOutlined
            className="pe-2"
            style={{
              color: "#F44336",
              fontSize: 20,
            }}
          />
          Low balance in your wallet, top up for uninterrupted service
        </div>
      ) : null}
    </>
  );
};
export const FromNumberComponent = React.memo(
  ({
    fromNumber,
    handleFromNumberChange,
    savedNumbersList,
    setSavedNumbersList,
    defaultSelectFirstOption,
    tabIndex,
    customClasses,
    disabled,
  }) => {
    const { fetchData } = useHttp();
    const [appProperties] = useContext(AppContext);
    const { getAllSavedNumbers } = useNumbers(appProperties);
    console.log("appProps ::: ", appProperties);

    const mapPhoneAndMessagingServiceObjToSavedNumber = (phoneList, msgObjList) => {
      if (msgObjList.length > 0) {
        phoneList = [
          ...(phoneList || []),
          {
            label: (
              <Divider plain style={{ margin: 0, fontSize: "12px" }} orientation="left">
                Message Service ID
              </Divider>
            ),
            value: "divider",
            disabled: true,
          },
          ...msgObjList,
        ];
      }
      if (phoneList !== undefined && phoneList.length > 0) {
        console.log("savedNumbersList phoneList.length annnachi 11>>>>>>>>>>>>>>>>>", phoneList.length);
        console.log("savedNumbersList defaultSelectFirstOption annnachi 11>>>>>>>>>>>>>>>>>", defaultSelectFirstOption);
        if (defaultSelectFirstOption) {
          const defaultPhoneNumber = phoneList.find((obj) => obj?.defaultNumber === 1)?.phoneNumber || phoneList[0]?.phoneNumber;
          console.log("savedNumbersList defaultPhoneNumber annnachi 11>>>>>>>>>>>>>>>>>", defaultPhoneNumber);
          handleFromNumberChange(defaultPhoneNumber);
        }
        phoneList = mapDataToOption(phoneList, appProperties, setSavedNumbersList);
      } else {
        phoneList = [];
      }

      console.log("savedNumbersList >>>>>>>>>>>>>>>>>", savedNumbersList);
      setSavedNumbersList(phoneList);
    };

    useEffect(() => {
      const fetchApi = async () => {
        var phoneList = [];
        var msgObjList = [];
        if (appProperties.apps) {
          const savedNumbers = appProperties.savedNumbers;
          if (savedNumbers && savedNumbers?.length > 0) {
            savedNumbers?.map((item) => {
              if (item?.isMessagingService === "true") {
                msgObjList.push(item);
              } else {
                phoneList.push(item);
              }
            });
          } else {
            try {
              const response = await getAllSavedNumbers();
              phoneList = response.data;
              msgObjList = response.messagingServices;
            } catch (error) {
              failureNotification("Error processing response:", error);
            }
          }

          console.log("phoneLIst.length annnachi >>>>>>>>>>>>", phoneList);
          if (phoneList.length === 0) {
            handleFromNumberChange(null);
          }
          mapPhoneAndMessagingServiceObjToSavedNumber(phoneList, msgObjList);
        }
      };
      fetchApi();
    }, [appProperties.apps, fetchData, savedNumbersList?.length, setSavedNumbersList, defaultSelectFirstOption]);

    useEffect(() => {
      if (appProperties) {
        let numberObj = savedNumbersList?.find((obj) => obj?.phoneNumber === fromNumber);
        let rightServiceName = appProperties?.installedApps?.find((obj) => obj?.integProps?.integId === numberObj?.integId)?.right?.service_name;
        let assignerObj1 = Object.assign(ProviderCommonObj, ProviderExtendedCommonObj[rightServiceName]);
        appProperties.providerCommonObj = assignerObj1;
        appProperties.rightServiceName = rightServiceName;
      }
    }, [fromNumber, appProperties]);

    return (
      <Select
        disabled={disabled}
        className={`rounded h-45 ${customClasses || ""}`}
        id="fromPhoneNumber"
        value={fromNumber || null}
        options={[
          ...(mapDataToOption(savedNumbersList, appProperties, setSavedNumbersList) || []),
          {
            value: "add::::phone",
            label: (
              <div type="" className="d-flex align-items-center addPhone-sendsms">
                <PlusOutlined style={{ verticalAlign: "middle" }} />
                <span className="p-1 ps-2" style={{ color: "#374151" }}>
                  Add Phone
                </span>
              </div>
            ),
          },
        ]}
        placeholder="Select From number"
        autoFocus
        style={{ width: "100%" }}
        bordered={null}
        tabIndex={tabIndex}
        suffixIcon={<div className="actionIconsSprite fromNumberDropDownIcon" />}
        onSelect={(v) => {
          urlParams.delete("sideBar");
          urlParams.delete("topBar");
          if (v === "add::::phone") {
            window.open("/channels/textsms?" + urlParams, "_blank");
          } else {
            handleFromNumberChange(v);
          }
        }}
      />
    );
  }
);

export const SetAsDefaultPhone = (props) => {
  const { phoneObj, item, setSavedNumbers } = props;
  const [appProperties] = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const { fetchData } = useHttp();
  const handleSetDefaultNumber = (e, method) => {
    e.stopPropagation();
    if (method === "add") {
      setLoading(true);
      fetchData(`${item?.integId}/number/default?phoneNumber=${encodeURIComponent(item?.phoneNumber)}`, "PUT", null, appProperties).then(
        function (response) {
          if (response) {
            response = JSON.parse(response);
            setLoading(false);
            var updatedPhoneObj = phoneObj.map((phoneItem) => ({
              ...phoneItem,
              defaultNumber: phoneItem.phoneNumber === response?.data?.phone?.phoneNumber ? 1 : 0,
            }));
            updatedPhoneObj = mapDataToOption(updatedPhoneObj, appProperties, setSavedNumbers);
            setSavedNumbers(updatedPhoneObj);
          }
        }
      );
    } else {
      setLoading(true);
      fetchData(
        `${item?.integId}/number/default?phoneNumber=${encodeURIComponent(item?.phoneNumber)}&action=remove`,
        "PUT",
        null,
        appProperties
      ).then(function (response) {
        if (response) {
          response = JSON.parse(response);
          var updatedPhoneObj = phoneObj.map((phoneItem) => ({
            ...phoneItem,
            defaultNumber: phoneItem.phoneNumber === response?.data?.phone?.phoneNumber ? 0 : 0,
          }));
          updatedPhoneObj = mapDataToOption(updatedPhoneObj, appProperties, setSavedNumbers);
          setLoading(false);
          setSavedNumbers(updatedPhoneObj);
        }
      });
    }
  };
  return (
    <>
      <Button
        loading={loading}
        bordered={null}
        type="link"
        onClick={(e) => {
          handleSetDefaultNumber(e, item?.defaultNumber === 1 ? "remove" : "add");
        }}
        className={item?.defaultNumber === 1 ? "defaultPhoneStarred labelMode" : "defaultPhone labelMode"}
      >
        {item?.defaultNumber === 1 ? (
          <Tooltip title="Set as Default">
            <StarFilled style={{ color: "#FFD66B", fontSize: 20 }} />
          </Tooltip>
        ) : (
          <Tooltip title="Set as Default">
            <StarOutlined style={{ fontSize: 20 }} />
          </Tooltip>
        )}
      </Button>
    </>
  );
};
export const mapDataToOption = (updatedPhoneObj, appProperties, setSavedNumbers) => {
  if (updatedPhoneObj?.length > 0) {
    updatedPhoneObj = updatedPhoneObj.map((item) => {
      return {
        ...item,
        value: item.phoneNumber,
        label: item?.phoneNumber ? (
          <Tooltip title={item?.phoneNumber}>
            <div className="addPhone-sendsms">
              <div className="d-flex align-items-center addPhone-sendsms">
                <div
                  className={
                    "numberlist-sprites numberlist-" +
                    appProperties?.installedApps?.find((obj) => obj?.integProps?.integId === item.integId)?.right?.service_name
                  }
                ></div>
                <span>
                  {(() => {
                    let labelName = (item.friendlyName || "") + `(${item.phoneNumber})`;
                    if (labelName?.length > 30) {
                      return labelName.slice(0, 30) + "...";
                    } else {
                      return labelName;
                    }
                  })()}
                </span>

                <SetAsDefaultPhone setSavedNumbers={setSavedNumbers} phoneObj={updatedPhoneObj} item={item} />
              </div>
            </div>
          </Tooltip>
        ) : (
          item?.label
        ),
      };
    });
  }
  return updatedPhoneObj;
};

export const initPusher = (fetchData, appProperties) => {
  return new Promise((resolve, reject) => {
    try {
      let pusherRef = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        encrypted: true,
        forceTLS: true,
        channelAuthorization: {
          customHandler: async (params, authorize) => {
            try {
              const response = await fetchData(
                `pusher/auth?socketId=${params.socketId}&channelName=${params.channelName}`,
                "post",
                null,
                appProperties
              );
              const responseJson = JSON.parse(response);
              authorize(null, responseJson.data);
            } catch (error) {
              reject(new Error("Error authorizing Pusher channel: " + error.message));
            }
          },
        },
      });

      let channelRef = pusherRef.subscribe(`private-${appProperties?.osyncId}`);

      function disposePusher() {
        channelRef.unbind_all();
        pusherRef?.unsubscribe(appProperties?.osyncId);
        pusherRef?.disconnect();
      }

      if (channelRef) {
        resolve({
          channel: channelRef,
          pusher: pusherRef,
          dispose: disposePusher,
        });
      } else {
        reject(new Error("Error initializing Pusher: Channel reference is undefined"));
      }
    } catch (error) {
      reject(new Error("Error initializing Pusher: " + error.message));
    }
  });
};
export const formatDate = (date) => {
  return dayjs(date).format("DD MMM, YYYY h:mm a");
};

export const CountUpStatistic = ({ title, finalCount, duration }) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    let start = 0;
    const increment = finalCount / ((duration * 1000) / 70);

    const interval = setInterval(() => {
      start += increment;
      if (start >= finalCount) {
        start = finalCount;
        clearInterval(interval);
      }
      setCount(Math.floor(start));
    }, 50);

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [finalCount, duration]);

  return <Statistic className="statistics-data" title={title} value={count} />;
};

export const BuyFunds = () => {
  const [appProperties, setAppProperties] = useContext(AppContext);
  function registerAgain() {
    RegisterChargebee("Omni", appProperties, setAppProperties);
  }
  return (
    <Button data-cb-type="checkout" data-cb-item-0={Chargebee_CHARGE_NAME} data-cb-item-0-quantity="1" type="link" onMouseEnter={registerAgain}>
      Add Funds
    </Button>
  );
};

export const ConfirmAlertModal = React.memo((props) => {
  const { onCancel, open, onConfirm, centered, alertIcon, titleContent, modalContent, buttonConfirm } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const handleModalConfirm = async () => {
    if (onConfirm) {
      setConfirmLoading(true);
      await onConfirm();
      setConfirmLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onCancel={onCancel}
        centered={centered}
        width={400}
        className="campaignOverviewEdit"
        footer={
          <div className="d-flex align-items-center justify-content-between mt-3 m-2">
            <Button className="w-100 me-2 h-45" style={{ color: "#605BFF" }} onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className="w-100 ms-2 h-45"
              type="primary"
              style={{ background: "#605BFF" }}
              onClick={handleModalConfirm}
              loading={confirmLoading}
            >
              {buttonConfirm}
            </Button>
          </div>
        }
      >
        <Row>
          <Col span={22}>
            <div className="d-flex align-items-center p-1  ps-0">{alertIcon}</div>
          </Col>
          <Col span={2} onClick={onCancel}>
            <div className="m-2 p-1 pt-2 pe-2">
              <div className="actionIconsSprite closeIconBig me-4"></div>
            </div>
          </Col>
        </Row>
        <div style={{ fontSize: "16px", fontWeight: 600 }} className="mb-1 mt-2">
          {titleContent}
        </div>
        <div className="pb-3">{modalContent}</div>
      </Modal>
    </>
  );
});

export const ActivityDetailsModal = React.memo((props) => {
  const { data, handleClose, open } = props ?? {};
  let { createdBy: createdUser, createdOn, modifiedBy } = data ?? {};
  createdUser = {
    ...createdUser,
    date: createdOn,
    create: true,
  };

  return (
    <Modal
      centered
      title={<div className="hs-fs-18">Activity</div>}
      cancelText={null}
      footer={<></>}
      className="activityDetailsModal"
      maskClosable={true}
      closeIcon={<div onClick={handleClose} className="actionIconsSprite closeIconSmall" />}
      mask={true}
      onCancel={handleClose}
      open={open}
    >
      <div className="container pb-2">
        <p>Who were the users involved in creating and editing this campaign</p>
        <div className="justify-content-center activityDetailList">
          {createdUser && <ActivityDetailItem user={createdUser} />}
          {modifiedBy &&
            modifiedBy?.map((modifiedByUser) => {
              return (
                <>
                  <hr className="hs-hr" />
                  <ActivityDetailItem user={modifiedByUser} />
                </>
              );
            })}
        </div>
      </div>
    </Modal>
  );
});

const ActivityDetailItem = (props) => {
  const { user } = props;
  const formattedDate = getHSFormattedDateString(user?.date);
  return (
    <div className="row hs-w-90 mt-3 d-flex align-items-center ms-1">
      <div className="col-3">
        {user?.iconUrl ? (
          <img alt="sender_url" src={user?.iconUrl} className="avatar-big-round" />
        ) : user?.name ? (
          <div className="avatar-big-round fw-bold hs-fs-16 bg-light d-flex justify-content-center align-items-center">
            {user?.name?.slice(0, 1)}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="col-4 float-left">
        <div className="hs-fs-15">{user?.create ? "Created By" : "Edited by"}</div>
        <Text className="hs-fs-17 hs-fw-550">{user?.name ?? ""}</Text>
      </div>
      <div className="col-5">
        <Text className="hs-fs-13">{formattedDate ?? ""}</Text>
      </div>
    </div>
  );
};

const ExtraComponent = ({ content }) => {
  return (
    <>
      {content && (
        <div className="d-flex justify-content-end">
          <span className="hs-fs-11 mt-1">{content}</span>
        </div>
      )}
    </>
  );
};

export const FormInput = (props) => {
  const { instantfocus, formItemName, formItemLabel, formItemRules, formExtraContent } = props;
  const inputRef = useRef(null);

  useEffect(() => {
    if (instantfocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [instantfocus]);

  return (
    <div className="d-flex flex-column gap-2">
      <span>{formItemLabel}</span>
      <Form.Item rules={formItemRules ?? []} name={formItemName} className="mb-0" extra={<ExtraComponent content={formExtraContent} />}>
        <Input
          ref={inputRef}
          autoComplete="off"
          tabIndex={1}
          className="hs-bg-offwhite hs-focus-border h-50 hs-fs-14 hover rounded"
          bordered={null}
          {...props}
        />
      </Form.Item>
    </div>
  );
};

export const RefreshButton = React.memo((props) => {
  const { onClickFunction, loading } = props;
  return <div className={`actionIconsSprite refresh-icon ${loading ? "hs-spin-360-infinite" : ""}`} onClick={onClickFunction} />;
});

export const handleBuyNumberClick = async (appProperties, setAppProperties, fetchData, setBuyNumberLoading, setShowBuyNumber, navigate) => {
  const currentParams = new URLSearchParams(window.location.search);
  const newParams = currentParams.toString();

  let savedNumbers = appProperties?.savedNumbers;
  let helloSendNumbers = savedNumbers?.filter((obj) => obj?.isHelloSendPhoneNumber === "true");
  if (helloSendNumbers?.length > 0) {
    navigate(`/channels/textsms/helloSend?${newParams}`);
  } else {
    if (appProperties.apps) {
      let queryObj = appProperties?.installedApps?.find((app) => app?.right?.service_name === "hello_send");
      if (queryObj) {
        queryObj = {
          integId: queryObj?.integProps?.integId,
          serviceId: queryObj?.right?.service_id,
          isTrialAccount: queryObj?.right?.isTrialAccount,
          rightServiceName: queryObj?.right?.service_name,
          authorized: queryObj?.right?.auth?.authorized,
        };
      }
      let queryObj2 = appProperties?.apps?.SMS?.find((app) => app.name === "hello_send");
      if (queryObj2) {
        queryObj2 = {
          integId: queryObj2?.integId,
          serviceId: queryObj2?.serviceId,
          isTrialAccount: queryObj2?.isTrialAccount,
          rightServiceName: queryObj2?.name,
          authorized: queryObj2?.authorized,
        };
      }
      let rightServiceObj = queryObj ?? queryObj2;
      let integIdNew;
      if (!rightServiceObj?.integId) {
        if (rightServiceObj?.serviceId && appProperties?.leftServiceId) {
          setBuyNumberLoading(true);
          const integrationResp = await fetchData(
            `integration/new?userId=${appProperties?.userId}`,
            "POST",
            {
              left_service_id: appProperties.leftServiceId,
              osync_id: appProperties.osyncId,
              right_service_id: rightServiceObj.serviceId,
            },
            appProperties
          );
          const parsedIntegrationResp = JSON.parse(integrationResp);
          integIdNew = parsedIntegrationResp?.data?.integId;
        }
        let integId = rightServiceObj.integId || integIdNew;
        if (integId && appProperties?.leftServiceId) {
          await fetchData("hellosend/account/" + integId + "/create?leftServiceId=" + appProperties.leftServiceId, "POST", null, appProperties)
            .then(function (response) {
              try {
                if (response) {
                  response = JSON.parse(response);
                  response = response?.data?.integration;
                  setAppProperties((prev) => ({
                    ...prev,
                    serviceInstalled: true,
                    randomIntegId: prev?.randomIntegId ? prev?.randomIntegId : response?.integId,
                    installedApps: [
                      ...(prev?.installedApps || []),
                      {
                        integProps: { ...response },
                        right: {
                          service_display_name: "Hello Send",
                          service_name: "hello_send",
                          auth: {
                            authorized: true,
                            url: true,
                          },
                        },
                      },
                    ],
                  }));
                  setBuyNumberLoading(false);
                  setShowBuyNumber(true);
                }
              } catch (error) {
                console.error("Error processing response:", error);
              }
            })
            .catch((err) => {
              setBuyNumberLoading(false);
            });
        }
      } else {
        setShowBuyNumber(true);
      }
    }
  }
};

export const ConfigurePhone = () => {
  const initialValue = {
    primaryPhoneApiName: "",
    primaryPhoneLookUp: "",
    secondaryPhoneApiName: "",
    secondaryPhoneLookUp: "",
  };
  const options = useMemo(
    () => [
      {
        label: "Primary phone",
        phoneApiName: "primaryPhoneApiName",
        phoneModule: "primaryPhoneLookUp",
      },
      {
        label: "Secondary phone",
        phoneApiName: "secondaryPhoneApiName",
        phoneModule: "secondaryPhoneLookUp",
      },
    ],
    []
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [addBtnLoading, setAddBtnLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [fieldsList, setFieldsList] = useState(null);
  const [fieldsOption, setFieldsOption] = useState([]);
  const [selectedFields, setSelectedFields] = useState(initialValue);
  const module = urlParams.get("resource");
  const [associatedModules, setAssociatedModules] = useState();
  const { fetchAllFields } = useTemplates();
  const { fetchData } = useHttp();
  const [appProperties, setAppProperties] = useContext(AppContext);
  const handleModalClose = () => {
    setModalOpen(false);
  };
  useEffect(() => {
    const fetchAssociatedModules = async () => {
      if (appProperties?.controller && !associatedModules) {
        try {
          const response = await appProperties.controller.associatedModules();
          setAssociatedModules(response);
        } catch (error) {
          console.error("Error fetching associated modules:", error);
        }
      }
    };

    fetchAssociatedModules();
  }, [appProperties, associatedModules]);

  useEffect(() => {
    const fetchFields = async () => {
      if (appProperties?.leftServiceId && module && !fieldsList && modalOpen) {
        try {
          const response = await fetchAllFields(appProperties, module);
          let configuredFields = await fetchData(
            `service/${appProperties?.leftServiceId}/modules/phone-fields?resource=${module}`,
            "GET",
            null,
            appProperties
          );
          configuredFields = JSON.parse(configuredFields);
          let phoneFieldEntity = configuredFields?.data?.config;
          if (phoneFieldEntity) {
            setSelectedFields({
              primaryPhoneApiName: phoneFieldEntity?.primaryPhoneApiName,
              primaryPhoneLookUp: phoneFieldEntity?.primaryPhoneLookUp,
              secondaryPhoneApiName: phoneFieldEntity?.secondaryPhoneApiName,
              secondaryPhoneLookUp: phoneFieldEntity?.secondaryPhoneLookUp,
            });
          }
          let allFields = [];
          console.log("fields>>>>>>>    ", response.data);
          response?.data?.forEach((obj) => {
            let fields = JSON.parse(obj.data);
            let byPhone = fields?.filter((field) => field.fieldType === "phone");
            allFields.push(...byPhone);
          });
          setFieldsList(allFields);
          setContentLoaded(true);
        } catch (error) {
          console.error("Error fetching fields:", error);
        }
      }
    };
    fetchFields();
  }, [appProperties, module, fieldsList, fetchAllFields, modalOpen]);

  useEffect(() => {
    if (fieldsList && module) {
      let supportedFields = fieldsList.filter((obj) => associatedModules[module]?.includes(obj.moduleName));
      let optionArr = {};

      options.forEach((option, index) => {
        const otherSelectedFields = Object.keys(selectedFields)
          .filter((key) => key !== option.phoneApiName)
          .map((key) => selectedFields[key]);

        optionArr[option.phoneApiName] = supportedFields.filter((field) => !otherSelectedFields.includes(field.fieldApi));
      });

      setFieldsOption(optionArr);
    }
  }, [fieldsList, module, associatedModules, selectedFields, options]);

  const addPhoneFields = () => {
    if (appProperties?.randomIntegId && module && appProperties.leftServiceId) {
      setAddBtnLoading(true);
      fetchData(
        `${appProperties?.randomIntegId}/modules/phone-fields?leftServiceId=${
          appProperties.leftServiceId
        }&resource=${module}&selectedIds=${urlParams.get("selectedIds")}`,
        "POST",
        { ...(selectedFields || []), moduleName: module },
        appProperties
      ).then(function (response) {
        try {
          response = JSON.parse(response);
          if (response?.data?.success) {
            let integDetails = response?.data?.integrationDetails;
            setAppProperties((prev) => ({
              ...prev,
              phone: integDetails?.phone || "",
            }));
            handleModalClose();
          } else {
            failureNotification("Error", "Something went wrong !");
          }
          setAddBtnLoading(false);
        } catch (error) {
          setAddBtnLoading(false);
          console.console.log("error>> ", error);
        }
      });
    } else {
      setShowError(true);
    }
  };

  const body = (
    <>
      {contentLoaded ? (
        <Space className="w-100" direction="vertical">
          {options.map((obj, index) => (
            <div key={index}>
              <div className="m-1">{obj.label}</div>
              <Select
                suffixIcon={<div className="actionIconsSprite fromNumberDropDownIcon" />}
                notFoundContent={<NotFoundContent content="No phone field available." />}
                value={selectedFields?.[obj?.phoneApiName] ?? null}
                options={
                  [
                    {
                      label: <span className="opacity-50">- Select -</span>,
                      value: "",
                    },
                    ...((fieldsOption &&
                      fieldsOption[obj?.phoneApiName]?.map((obj) => ({
                        label: (
                          <span>
                            {module === obj?.moduleName ? (
                              obj?.fieldName
                            ) : (
                              <span className="text-capitalize d-flex align-items-center">
                                {obj?.moduleName?.length > 8 ? obj?.moduleName?.slice(0, 3) : obj?.moduleName}
                                <span className="actionIconsSprite right-arrow-icon" /> {obj?.fieldName}
                              </span>
                            )}
                          </span>
                        ),
                        value: obj?.fieldApi,
                      }))) ||
                      []),
                  ] || []
                }
                onSelect={(e) => {
                  let item = fieldsOption?.[obj?.phoneApiName]?.find((obj) => obj?.fieldApi === e);
                  setSelectedFields((prev) => ({
                    ...(prev || []),
                    [obj?.phoneApiName]: item?.fieldApi || "",
                    [obj?.phoneModule]: module === item?.moduleName ? "" : item?.moduleName || "",
                  }));
                }}
                className={`hs-bg-offwhite w-100 rounded focus-border h-44 mt-1 ${showError && index === 0 && `select-field-error`}`}
                dropdownStyle={{
                  boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.12),0px 20px 20px 0px rgba(0, 0, 0, 0.08)",
                }}
                bordered={false}
                placeholder={`- Select -`}
                style={{ minWidth: 180, maxWidth: 300 }}
                onFocus={() => {
                  if (index === 0) {
                    setShowError(false);
                  }
                }}
              />
            </div>
          ))}
          <div className="d-flex justify-content-end">
            <Space>
              <Button className="mt-2 row-reverse d-inline-block hs-btn-cancel-small" onClick={handleModalClose}>
                cancel
              </Button>
              <Button loading={addBtnLoading} className="hs-btn-small mt-2 row-reverse d-inline-block" onClick={addPhoneFields}>
                Configure
              </Button>
            </Space>
          </div>
        </Space>
      ) : (
        <Space
          direction="vertical"
          size="middle"
          style={{
            display: "flex",
            minWidth: 250,
          }}
        >
          {[...Array(3)].map((_, index) => (
            <React.Fragment key={index}>
              <div>
                <Skeleton
                  className="w-100"
                  active
                  paragraph={{
                    rows: 0,
                  }}
                />
                <Skeleton.Input className="w-100" active size="medium" />
              </div>
            </React.Fragment>
          ))}
          <div className="d-flex justify-content-end">
            <Skeleton.Button active />
          </div>
        </Space>
      )}
    </>
  );

  return (
    <>
      <Button
        onClick={() => {
          setModalOpen(true);
        }}
        type="link"
      >
        Configure Phone
      </Button>

      <CommonModal
        modalCloseIcon={true}
        contentSpaceSize="large"
        open={modalOpen}
        doOnClose={handleModalClose}
        header={
          <h6 className="d-flex">
            Configure 'To Phone' for
            <span className="text-capitalize ms-1"> {module} module</span>
          </h6>
        }
        body={body}
        okButtonProps={false}
        cancelButtonProps={false}
        width={"auto"}
        styles={{
          mask: { backgroundColor: "rgb(163, 163, 163, 0.2)" },
          content: {
            boxShadow: "rgba(169, 169, 169, 0.366) 0px 13px 61px 0px",
          },
        }}
        centered
      />
    </>
  );
};

export const NotFoundContent = ({ content }) => {
  return (
    <>
      <Empty image={null} description={<span className="empty-content">{content}</span>}></Empty>
      {/* <div className="w-100 m-auto notDataFound d-flex align-items-center justify-content-center">
        {content}
      </div> */}
    </>
  );
};

export const CommonModal = (props) => {
  const { okButtonProps, cancelButtonProps, doOnClose, header, modalCloseIcon, body, contentSpaceSize } = props;
  const headerClasses = {
    large: "fs-4 fw-medium",
    small: "fs-6 fw-light",
  };

  return (
    <Modal {...props} onCancel={doOnClose && doOnClose} centered closeIcon={null} mask={false} maskClosable={false} footer={null}>
      <div className="d-flex justify-content-between align-items-start ms-1 mb-1">
        <span className={headerClasses[contentSpaceSize]}>{header}</span>
        {modalCloseIcon && <div onClick={doOnClose && doOnClose} className={"actionIconsSprite mediumSprite closeIconBig ms-2"} />}
      </div>
      <Space size={contentSpaceSize ?? "large"} className="w-100 h-100 modalContent" direction="vertical">
        {body}
        {cancelButtonProps || okButtonProps ? (
          <div className="d-flex justify-content-end pb-3">
            <div className="d-flex align-items-center gap-4">
              {cancelButtonProps && (
                <Button
                  danger={cancelButtonProps?.danger ?? true}
                  {...cancelButtonProps}
                  className={`hs-border-10 big-button hs-fs-16 px-3 ${cancelButtonProps?.className}`}
                >
                  <span className="px-3">{cancelButtonProps?.buttonText}</span>
                </Button>
              )}
              {okButtonProps && (
                <Button
                  {...okButtonProps}
                  className={`hs-bg-violet hs-border-10 big-button text-white hs-fs-16 px-3 ${okButtonProps?.className}`}
                >
                  <span className="px-3">{okButtonProps?.buttonText}</span>
                </Button>
              )}
            </div>
          </div>
        ) : null}
      </Space>
    </Modal>
  );
};

export const DeleteComponent = ({ record, setTableData, appProperties, confirmDeleteComponent, handleDelete, modalOpen, setModalOpen }) => {
  const [loading, setLoading] = useState(false);
  const [outlineColor, setOutlineColor] = useState(false);
  const [deleteText, setDeleteText] = useState("");
  const { fetchData } = useHttp();
  const inputRef = useRef(null);

  const closeModal = () => {
    setModalOpen(false);
    setOutlineColor(false);
    setDeleteText("");
  };

  const handleDeleteClass = (record) => {
    setModalOpen(false);
    handleDelete(record);
  };
  return (
    <>
      <Modal
        className="confirmDeleteModal"
        open={modalOpen}
        onCancel={closeModal}
        width={400}
        centered
        footer={
          <div className="d-flex align-items-center justify-content-between mt-2">
            <Button
              className="w-100  h-45 hs-fs-16 commonGhostButton text-dark"
              onClick={() => {
                setModalOpen(false);
              }}
              style={{ fontWeight: 600 }}
            >
              Cancel
            </Button>
            <Button className="w-100 h-45 confirmDeleteBtn hs-fs-16" onClick={() => handleDeleteClass(record)}>
              Delete
            </Button>
            {loading && <CommonLoadingV2 />}
          </div>
        }
      >
        <Row>
          <Col span={22}>
            <div className="d-flex align-items-center">
              <div className="actionIconsSprite deleteIcon me-4"></div>
            </div>
          </Col>
          <Col span={2} onClick={closeModal}>
            <div className="m-2 p-1 pt-2 pe-2">
              <div className="actionIconsSprite closeIconBig me-4"></div>
            </div>
          </Col>
        </Row>
        <div style={{ fontSize: "16px", fontWeight: 600 }} className="mb-1">
          Sure you want to Delete ?
        </div>
        <div className="pb-3">Are you sure you want to delete this {confirmDeleteComponent} ?</div>
      </Modal>
    </>
  );
};
