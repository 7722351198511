import React, { useState, useContext, useMemo } from "react";
import { Select, Spin, Typography, Divider, Button, Badge } from "antd";

const DropDownComponent = (props) => {
  const {
    selectTitle,
    customProps,
    tabIndex,
    selectedId,
    buttonAction,
    selectMenuItems,
    buttonNeeded,
    buttonIconNeeded,
    buttonText,
    handleOnSelect,
    notFoundContent,
    headerSpace,
    headerSpaceNeeded,
    selectComponentWidth,
    selectComponentHeight,
  } = props;
  const { Text } = Typography;
  const [dropDownLoading, setDropDownLoading] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(["3"]);

  const handleOnChange = (v) => {
    handleOnSelect(v);
  };
  return (
    <>
      <Select
        {...customProps}
        selectedKeys={selectedKeys}
        tabIndex={tabIndex}
        bordered={false}
        style={{
          width: selectComponentWidth ? selectComponentWidth : 120,
          height: selectComponentHeight ? selectComponentHeight : 29,
          backgroundColor: "#F4F4FF",
          borderRadius: 8,
          cursor: "pointer",
        }}
        dropdownStyle={{ width: 200 }}
        placeholder={
          <span style={{ color: "#333" }} className="ms-1 hs-fs-13">
            {selectTitle}
          </span>
        }
        options={selectMenuItems}
        optionFilterProp="children"
        notFoundContent={notFoundContent}
        filterOption={(input, option) =>
          (option?.label ?? "").toString().includes(input)
        }
        suffixIcon={
          <div className="actionIconsSprite customComponentDropDownIcon" />
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toString()
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toString().toLowerCase())
        }
        dropdownRender={(menu) => (
          <div className="d-flex flex-column">
            {headerSpaceNeeded ? <>{headerSpace}</> : null}
            <Spin spinning={dropDownLoading}>
              <div
                className="overflow-auto"
                style={{ maxHeight: "20dvh", minHeight: "20dvh" }}
              >
                {menu}
              </div>
            </Spin>
            <div className="row mt-auto justify-content-end">
              <div>
                {buttonNeeded ? (
                  <div
                    onClick={() => buttonAction()}
                    className="btn d-flex align-items-center justify-content-center"
                    style={{ background: "#605bff", color: "#fff" }}
                  >
                    {buttonIconNeeded ? (
                      <div className="actionIconsSprite addTemplateIcon" />
                    ) : null}
                    <span className="hs-fs-12 text-light">{buttonText}</span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
        onSelect={(v) => handleOnChange(v)}
        onChange={handleOnChange}
      />
    </>
  );
};

export default DropDownComponent;
