import { Layout, Menu, Row, Col, Modal } from "antd";
import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../assets/css/omessage.css";
import { urlParams } from "./../../constants/AppConstants";
import { AppContext } from "./../../context/AppContext";

const { Sider } = Layout;

function Sidebar(props) {
  const location = useLocation();
  const [appProperties] = useContext(AppContext);
  const sideBarNeeded = urlParams.get("sideBar");
  const [isSideBarNeeded] = useState(sideBarNeeded === undefined || sideBarNeeded !== "false");
  const [openKeys, setOpenKeys] = useState([]); // Add state to track open keys

  if (!isSideBarNeeded) {
    return <></>;
  }
  const showSetting = appProperties?.controller?.sideBarExtend?.settingSideBar;

  const { SubMenu } = Menu;

  const topItems = [
    {
      key: "campaigns",
      label: "Analytics",
      icon: <span className="actionIconsSpriteForSideBar overviewIcon display-inline-block"></span>,
      path: "/campaigns/list",
    },
    {
      key: "inbox",
      label: "Inbox",
      icon: <span className="actionIconsSpriteForSideBar inboxIcon display-inline-block"></span>,
      path: "/inbox",
    },
    {
      key: "campaign",
      label: "Campaign",
      icon: <span className="actionIconsSpriteForSideBar campaignIcon display-inline-block"></span>,
      path: "/campaign",
    },
    {
      key: "templates",
      label: "Templates",
      icon: <span className="actionIconsSpriteForSideBar templatesIcon display-inline-block"></span>,
      path: "/templates",
    },
    {
      key: "automation",
      label: "Automation",
      icon: <span className="actionIconsSpriteForSideBar automationIcon display-inline-block"></span>,
      path: "/automation",
    },
    {
      key: "more",
      label: "More",
      icon: <span className="actionIconsSpriteForSideBar moreIcon display-inline-block"></span>,
      children: [
        {
          key: "users",
          parentKey: "more",
          label: "Users",
          icon: <span className="actionIconsSpriteForSideBar usersIcon display-inline-block"></span>,
          path: "/users",
        },
      ],
    },
  ];

  const bottomItems = [
    {
      key: "channels",
      label: "Channels",
      icon: <span className="actionIconsSpriteForSideBar channelsIcon display-inline-block"></span>,
      path: "/channels",
      settingsSidebarAboveSpace: "100px",
    },

    showSetting && {
      key: "settings",
      label: "Settings",
      icon: <span className="actionIconsSpriteForSideBar settingIcon display-inline-block"></span>,
      path: "/settings",
    },
    {
      key: "bookAgent",
      label: "Book Agent",
      icon: <span className="actionIconsSpriteForSideBar bookAgentIcon display-inline-block"></span>,
      popup: true,
      iframeUrl: "https://oapps.zohobookings.com/portal-embed#/meeting",
    },
  ].filter(Boolean);

  const getSelectedAndOpenKeys = () => {
    let selectedKey = "";
    let openKey = "";
    var menuItems = [...topItems, ...bottomItems];
    menuItems.forEach((item) => {
      if (item.children) {
        item.children.forEach((child) => {
          if (location.pathname === child?.path) {
            selectedKey = child?.key;
            // openKey = item.key;
          }
        });
      } else if (location.pathname === item.path) {
        selectedKey = item.key;
      }
    });

    return { selectedKey, openKey };
  };

  const { selectedKey, openKey } = getSelectedAndOpenKeys();

  const generateMenuItems = (menuItems) => {
    return menuItems.map((item) => {
      if (item.children) {
        return (
          <SubMenu
            key={item.key}
            title={
              <Row align="middle">
                <Col span={8}>{item.icon}</Col>
                <Col span={16}>{item.label}</Col>
              </Row>
            }
            onTitleClick={() => handleSubMenuClick(item.key)} // Handle submenu click
          >
            {item.children.map((child) => (
              <Menu.Item key={child?.key}>
                <Link to={child?.path + location.search} style={{ textDecoration: "none" }}>
                  <Row align="middle">
                    <Col span={8}>
                      {/* <HomeOutlined style={{ fontSize: '24px' }} /> */}
                      {child?.icon}
                    </Col>
                    <Col span={16}>{child?.label}</Col>
                  </Row>
                </Link>
              </Menu.Item>
            ))}
          </SubMenu>
        );
      }

      return (
        <Menu.Item key={item.key}>
          {item?.popup ? (
            <SidebarModal item={item} appProperties={appProperties} />
          ) : (
            <Link to={item.path + location.search} style={{ textDecoration: "none" }} className="mb-3">
              <Row align="middle">
                <Col span={6}>{item.icon}</Col>
                <Col span={18}>{item.label}</Col>
              </Row>
            </Link>
          )}
        </Menu.Item>
      );
    });
  };

  const handleSubMenuClick = (key) => {
    setOpenKeys((prevKeys) => (prevKeys.includes(key) ? prevKeys.filter((k) => k !== key) : [key]));
  };

  return (
    <>
      <Sider
        // trigger={null}
        // collapsible
        // collapsed={true}
        // collapsedWidth={"200px"}
        theme="light"
        className="omessageSider"
        style={{
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: 1,
          background: "white",
        }}
      >
        <div className="demo-logo-vertical" />
        <div key="logo" style={{ height: "100px" }} title="" className="hellosendHover">
          <a target="_blank" href="https://www.oapps.xyz" rel="noreferrer" style={{ textDecoration: "none" }}>
            <div className="d-flex justify-content-left ps-4">
              <Col className="mt-4">
                <div className="sprite-icon icon-logo" title=""></div>
              </Col>
              <Col span={24} className="ms-2 mt-3" style={{ lineHeight: "initial" }}>
                <div className="text-column">
                  <div className="text-dark">Hellosend</div>
                  <div className="text-secondary mt-1" style={{ fontSize: "12px" }}>
                    OAppS.xyz
                  </div>
                </div>
              </Col>
            </div>
          </a>
        </div>
        <div className="d-flex flex-column justify-content-between" style={{ height: "84vh" }}>
          <Menu
            className="h-100 sidebar-menu"
            mode="vertical"
            selectedKeys={[selectedKey]}
            openKeys={openKeys}
            onOpenChange={handleSubMenuClick}
          >
            {generateMenuItems(topItems)}
          </Menu>
          <Menu className="sidebar-menu" mode="vertical" selectedKeys={[selectedKey]} defaultOpenKeys={[openKey]}>
            {generateMenuItems(bottomItems)}
          </Menu>
        </div>
      </Sider>
    </>
  );
}

export default Sidebar;

const SidebarModal = ({ item, appProperties }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const iframeUrl = item?.iframeUrl + `?Name=${appProperties?.userName}&Email=${appProperties?.currentUserEmail}`;
  return (
    <>
      <Modal
        title=" "
        centered
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        width={1000}
        footer={null}
      >
        <div>{iframeUrl && <iframe className="iframe-popup" src={iframeUrl} frameBorder="0" allowFullScreen title="Schedule"></iframe>}</div>
      </Modal>

      <div
        style={{ textDecoration: "none" }}
        className="mb-3"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <Row align="middle">
          <Col span={6}>{item?.icon}</Col>
          <Col span={18}>{item?.label}</Col>
        </Row>
      </div>
    </>
  );
};
