import AccountPhone from "../pages/OnboardingComponents/AccountPhone.jsx";
import AuthorizePage from "../pages/OnboardingComponents/AuthorizePage";
import AddPhone from "../pages/OnboardingComponents/AddPhone.jsx";
import GuidetoSendSms from "../pages/OnboardingComponents/GuideToSendSms.jsx";

export let ProviderCommonObj = {
  isMMSSupported: false,
  isAlphaSenderSupported: false,
  category: "SMS",
  isMessagingServiceSenderSupported: false,
  isEnableInboundSms: false,
  app: "",
  title: "",
  AuthorizationType: "",
  loginContent: [],
  steps: [
    {
      title: "account",
      description: "Account",
      content: <AccountPhone />,
    },
    {
      title: "installation",
      description: "Installation",
      content: <AuthorizePage />,
    },
    {
      title: "addPhone",
      description: "Add Phone",
      content: <AddPhone />,
    },
    {
      title: "guide",
      description: "Guide to send SMS",
      content: <GuidetoSendSms />,
    },
  ],
  menu: [
    {
      label: "Add Phone",
      key: "Phone number",
    },
  ],
  supportedAttachmentTypes: {
    image: "image/jpg, image/jpeg, image/png, image/bmp, image/gif, image/tiff, text/vcard, video/mp4, video/mpeg, audio/mpeg",
  },
  maximumAttachmentSizeInBytes: 5242880,
  maximumAttachmentLimit: 5,
};

export let ProviderExtendedCommonObj = {
  hello_send: {
    howToAuthLink: null,
    app: "hello_send",
    isEnableInboundSms: false,
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg, image/gif",
    },
    maximumAttachmentSizeInBytes: 5242880,
    maximumAttachmentLimit: 5,
    isMessagingServiceSenderSupported: false,
  },
  twilio: {
    AuthorizationType: "auth",
    app: "twilio",
    category: "sms",
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_Twilio_",
    title: "Authorize Twilio and configure all your SMS numbers.",
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg, image/gif",
      file: "application/pdf, application/vcard, application/vnd.apple.pkpass, text/vcard, text/x-vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory",
    },
    maximumAttachmentSizeInBytes: 5242880,
    maximumAttachmentLimit: 5,
    isMMSSupported: true,
    isAlphaSenderSupported: true,
    isEnableInboundSms: false,
    isMessagingServiceSenderSupported: true,
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
      {
        label: "Messaging Service",
        key: "MessagingService",
      },
    ],
  },
  twilio_whatsapp: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/whatsapp-for-pipedrive",
    AuthorizationType: "auth",
    app: "twilio_whatsapp",
    category: "sms",
    title: "Authorize Whatsapp-Twilio and configure all your SMS numbers.",
    supportedAttachmentTypes: {
      image: "image/png,image/jpg,image/gif,image/jpeg,image/bmp,image/tiff",
      file: "application/pdf,application/vcard,application/vnd.apple.pkpass,text/vcard,text/x-vcard,text/csv,text/rtf,text/richtext,text/calendar,text/directory",
      audio:
        "audio/basic,audio/L24,audio/mp4,audio/mpeg,audio/ogg,audio/vnd.rn-realaudio,audio/vnd.wave,audio/3gpp,audio/3gpp2,audio/ac3,audio/webm,audio/amr-nb,audio/amr",
      video:
        "video/mpeg,video/mp4,video/quicktime,video/webm,video/3gpp,video/3gpp2,video/3gpp-tt,video/H261,video/H263,video/H263-1998,video/H263-2000,video/H264",
    },

    maximumAttachmentSizeInBytes: 5242880,
    maximumAttachmentLimit: 5,
    isMMSSupported: true,
    isAlphaSenderSupported: true,
    isEnableInboundSms: false,
    isMessagingServiceSenderSupported: true,
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
  },
  clicksend: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_ClickSend",
    AuthorizationType: "auth",
    app: "clicksend",
    isEnableInboundSms: false,
    category: "sms",
    title: "Authorize Clicksend and configure all your SMS numbers.",
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
  },
  plivo: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_Plivo",
    isMMSSupported: true,
    AuthorizationType: "auth",
    app: "plivo",
    isEnableInboundSms: false,
    category: "sms",
    title: "Authorize Plivo and configure all your SMS numbers.",
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
  },
  vonage: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_Vonage",
    AuthorizationType: "auth",
    app: "vonage",
    isEnableInboundSms: false,
    category: "sms",
    title: "Authorize Vonage and configure all your SMS numbers.",
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
  },
  burstsms: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_BurstSMS",
    AuthorizationType: "auth",
    isEnableInboundSms: false,
    category: "sms",
    title: "Authorize Burstsms and configure all your SMS numbers.",
    app: "burstsms",
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
  },
  telnyx: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_Telnyx",
    category: "sms",
    app: "telnyx",
    isMMSSupported: true,
    title: "Authorize Telnyx and configure all your SMS numbers.",
    AuthorizationType: "auth",
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
  },
  facebook: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_Facebook",
    category: "messenger",
    AuthorizationType: "outh",
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg, image/gif",
    },
    maximumAttachmentLimit: 5,
    maximumAttachmentSizeInBytes: 5242880,
    isMMSSupported: true,
    isEnableInboundSms: false,
    app: "facebook",
    title: "Connect facebook and configure all pages",
    menu: [
      {
        label: "Select Pages",
        key: "Select Pages",
      },
    ],
  },
  ringcentral: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_RingCentral",
    AuthorizationType: "oauth",
    category: "sms",
    isEnableInboundSms: true,
    app: "ringcentral",
    title: "Authorize Ringcentral and configure all your SMS numbers.",
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg, image/gif",
    },
    maximumAttachmentLimit: 5,
    maximumAttachmentSizeInBytes: 5242880,
    isMMSSupported: true,
  },
};
